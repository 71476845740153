import React, {Component} from 'react';
import {Button, Container, Divider, Icon, Message, Pagination, Segment, Table} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import moment from 'moment';
import APIService from '../API.js';


class ListBulkPurchases extends Component {

  state = {
    bulkPurchases: [],

    /* PAGINATION */
    page: 1,
    pageSize: 25,
    lastPage: 1,
    showFirstAndLastNav: false,

    isFetchingBulkPurchases: false,
  }

  componentDidMount() {
    this.getBulkPurchases()
  }

  getBulkPurchases = (options) => {
    let filters = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      ...options
    }
    this.setState(filters)
    this.setState({isFetchingBulkPurchases: true})
    fetch( APIService.serverUrl + "/bulk-gift-card/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify(filters),
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            bulkPurchases: json.results,
            isFetchingBulkPurchases: false,
            page: json.page ? json.page : 1,
            pageSize: json.pageSize ? json.pageSize: 10,
            lastPage: json.lastPage ? json.lastPage : 1,
          });
        }
      })
  }

  navToAddBulkPurchase = () => {
    this.props.history.push('/giftcard/bulk/add')
  }

  navToViewBulkPurchase = (id) => {
    this.props.history.push('/giftcard/bulk/view/' + id)
  }

  render = () => {

    return (
      <Container>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginTop: '30px'}}>
          <span>Click a row to edit a Bulk Purchase.</span>
          <Button
            icon="add"
            color='teal'
            content="Add Bulk Purchase"
            onClick={() => this.navToAddBulkPurchase()}
          />
        </div>

        <Divider style={{marginTop: '7px', marginBottom: "30px"}}/>

        {this.state.bulkPurchases.length === 0 ?
          <Segment basic loading={this.state.isFetchingBulkPurchases} style={{marginTop: "60px"}}>
            <Message color='teal'  style={{textAlign:'center'}}>
              <Message.Content>
                <Message.Header>
                  There are no Bulk Purchases at this time.
                </Message.Header>
                <br />
              </Message.Content>
            </Message>
          </Segment>
          :
          <Table
            basic="very"
            compact='very'
            size='small'
            verticalAlign='middle'
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Date Created</Table.HeaderCell>
                <Table.HeaderCell textAlign="center"># of Recipients</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.bulkPurchases.map(purchase => {
                return (
                  <Table.Row
                    key={purchase._id.toString()}
                    id={purchase._id.toString()}
                    style={{cursor:'pointer'}}
                    onClick={e => {this.navToViewBulkPurchase(purchase._id)}}
                  >
                    <Table.Cell>{purchase.title}</Table.Cell>

                    <Table.Cell>{new moment(purchase.createdAt).format('MMM D, YYYY - h:mm a')}</Table.Cell>

                    <Table.Cell textAlign="center">{purchase.recipients.length}</Table.Cell>

                    <Table.Cell textAlign="center"><Icon name="circle" color={purchase.status === "success" ? 'green' : 'red'}/></Table.Cell>

                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        }



        {/*PAGINATION*/}
        {this.state.lastPage <= 1 ? null :
          <div style={{ textAlign:'center', }} >
            <Pagination
              secondary
              pointing
              size='tiny'
              color='teal'
              defaultActivePage={this.state.page}
              totalPages={this.state.lastPage}
              boundaryRange={1}
              siblingRange={1}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={this.state.showFirstAndLastNav ?
                { content: <Icon name='angle double left' />, icon: true } : null }
              lastItem={this.state.showFirstAndLastNav ?
                { content: <Icon name='angle double right' />, icon: true } : null }
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              onPageChange={(e,{activePage}) => {
                this.getPurchasedCards({ page: activePage });
              }}
            />
          </div>
        }
      </Container>
    )
  }
}

export default withRouter(ListBulkPurchases);
