import React, {Component} from 'react';
import { Grid, Icon, Input, Button, Container, Divider, Header, Image, Message, Pagination, Table, Segment} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import moment from 'moment';
import APIService from '../API.js';
import PurchasedCardsTable from "./PurchasedCardsTable";
import ReactJson from 'react-json-view';

class ViewBulkPurchase extends Component {

  static filterOptions = {
    page: "page",
    pageSize: "pageSize",
    sortField: "sortField",
    sortOrder: "sortOrder"
  }

  static filterKeys = Object.keys(ViewBulkPurchase.filterOptions)

  state = {
    // Card Details
    bulkPurchase: {
      events: []
    },
    giftCards: [],

    /*LIST COLUMN DISPLAY*/
    selectedColumns: ["recipientName", "recipientEmail", "amount", "status"],

    /* PAGINATION */
    page: 1,
    pageSize: 25,
    lastPage: 1,
    showFirstAndLastNav: false,
    sortField: "",
    sortOrder: "",

    activeIndex: null,
    email: "",
    areActionButtonsShown: true,
    retryAction: "",

    isFetchingBulkPurchase: false,
    isSendingReceipt: false,
    isFetchingGiftCards: false
  }

  componentDidMount() {
    this.getBulkPurchase();
    this.getGiftCards();
  }

  apiURI = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  purchaseId = () => this.props.match.params.purchaseId;

  getBulkPurchase = () => {
    this.setState({ isFetchingBulkPurchase: true });
    fetch( APIService.serverUrl + "/bulk-gift-card/" + this.purchaseId(), {
      credentials: 'include',
      method: "GET",
      headers: {
        clientId: APIService.clientId
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response);
          alert('Fetching Bulk purchase failed, see browser logs for details');
        }
      })
      .then(json => {
        if (json) {
          this.setState({bulkPurchase: json})
        }
        this.setState({isFetchingBulkPurchase: false})
      })
  }

  getGiftCards = (options = {}) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({ isFetchingGiftCards: true, });
    fetch( APIService.serverUrl + "/gift-card/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        pageSize: options.pageSize,
        page: options.page,
        bulkGiftCard: this.purchaseId()
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response);
          alert('Fetching gift cards failed, see browser log for details');
        }
      })
      .then(json => {
        if (json) {
          this.setState({
            page: json.page,
            pageSize: json.pageSize,
            lastPage: json.lastPage,
            count: json.count,
            giftCards: json.results
          })
        }
        this.setState({isFetchingGiftCards: false})
      })
  }

  reSendReceipt = () => {
    this.setState({ isSendingReceipt: true });
    fetch( APIService.serverUrl + "/bulk-gift-card/" + this.purchaseId() + "/resend-receipt-email", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        email: this.state.email
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response);
          alert('Sending Bulk receipt failed, see browser logs for details');
        }
      })
      .then(json => {
        if (json) {
          this.setState({
            email: "",
            areActionButtonsShown: true,
            bulkPurchase: json
          })
        }
        this.setState({isSendingReceipt: false})
      })
  }

  canReceiptEmailSend = () => {
    return !!this.state.bulkPurchase.events.find(v => v.eventType === "MonetraPreauthCompleteEvent");
  }

  renderRetryAction = () => {
    switch(this.state.retryAction) {
      case "sendReceipt":
        return (
          <div className="retryContainer">
            <span className="retryLabel">Send Receipt Email</span>
            <Input
              className="retryInput"
              value={this.state.email}
              onChange={(e) => {
                this.setState({email: e.target.value})
              }}
            />
            <Button
              className="retryButton"
              content="Send"
              color="teal"
              disabled={this.state.isSendingReceipt}
              loading={this.state.isSendingReceipt}
              onClick={this.reSendReceipt}
            />
          </div>
        );
      default:
        return <span>Something went wrong...</span>
    }
  }

  displayEventType = (event) => {
    switch(event.eventType) {
      case "InitialBulkGiftCardRequestEvent":
        return "Initial Request"
      case "MonetraAvsOnlyEvent":
        return "Monetra AVS Only"
      case "MonetraPreauthEvent":
        return "Monetra Pre-Auth"
      case "CreateBulkGiftCardsEvent":
        return "Bulk Create E-Gift Cards"
      case "MonetraPreauthCompleteEvent":
        return "Monetra Pre-Auth Complete"
      case "EmailEvent":
        switch (event.emailType) {
          case "BulkGiftCardReceipt":
            return "Send Bulk Purchase Receipt Email"
          default:
            return "Send Email"
        }
        return "Send Receipt Email"
      case "CapturedErrorEvent":
        return "Failed Event"
      default:
        return event.eventType
    }
  }

  handleEventClick = (id) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === id ? -1 : id

    this.setState({ activeIndex: newIndex })
  }

  render = () => {
    return (
      <Container>

        {/*Details*/}
        <Segment basic loading={this.state.isFetchingBulkPurchase} padded={this.state.isFetchingBulkPurchase}>
        {!this.state.isFetchingBulkPurchase &&
          <React.Fragment>
            <Divider horizontal style={{marginTop: "30px", marginBottom: "20px"}}>
              <Header>
                Bulk Delivery Details
              </Header>
            </Divider>

            {/*Details Container*/}
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Image
                    rounded
                    centered
                    size='tiny'
                    alt="Card Image"
                    style={{width: '100%'}}
                    src={this.apiURI + "/gift-card-design/" + this.state.bulkPurchase.giftCardDesign + "/mainCardImage"}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Segment style={{backgroundColor: "#F8F8F8", border: "1px solid rgba(34, 36, 160, 0.15)"}}>
                    <Table basic='very' compact='very'>
                      <Table.Row>
                        <Table.HeaderCell align="right" width={2}>Title:</Table.HeaderCell>
                        <Table.Cell style={{border: 'none'}}>{this.state.bulkPurchase.title}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell align="right">Subject:</Table.HeaderCell>
                        <Table.Cell style={{border: 'none'}}>{this.state.bulkPurchase.subject}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell align="right">Delivery:</Table.HeaderCell>
                        <Table.Cell style={{border: 'none'}}>{new moment(this.state.bulkPurchase.scheduledDelivery).format("MMM D, YYYY [at] h a")}</Table.Cell>
                      </Table.Row>
                    </Table>
                  </Segment>
                  <div>
                    <label><strong>Message:</strong></label>
                    <p>{this.state.bulkPurchase.message}</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{margin: '16px'}}>
                <Grid.Column>
                  <Segment id="purchasedDetails">
                    <div className="purchasedEventLog">
                      <span style={{fontStyle: "italic", textDecoration: "underline"}}>Transaction Event Log</span>
                      <Table
                        basic="very"
                        compact='very'
                        size='small'
                        verticalAlign='middle'
                      >
                        <Table.Body>
                          {this.state.bulkPurchase.events.map((event) => {
                            return (
                              <React.Fragment>
                                <Table.Row
                                  className="eventLog"
                                  active={this.state.activeIndex === event._id}
                                  index={event._id}
                                  onClick={() => this.handleEventClick(event._id)}
                                  style={{padding: "0px"}}
                                >
                                  <Table.Cell className="eventLogIcon">
                                    <Icon
                                      name="info circle"
                                      color={event.eventType === "CapturedErrorEvent" ? "yellow" : "green"}
                                    />
                                  </Table.Cell>

                                  <Table.Cell className="eventLogDate">
                                    {new moment(event.createdAt).format("YYYY/MM/DD - h:mm:ss.SS A")}
                                  </Table.Cell>

                                  <Table.Cell
                                    className={"eventLogStatus " +
                                      (event.eventType === "CapturedErrorEvent" ? "errorSpacing" : null)
                                    }
                                  >
                                    {event.eventType === "CapturedErrorEvent" ? "Issue" : "Success"}
                                  </Table.Cell>

                                  <Table.Cell className="eventLogType">
                                    {this.displayEventType(event)}
                                  </Table.Cell>
                                </Table.Row>

                                {this.state.activeIndex !== event._id ? null :
                                  <Table.Row
                                    style={{
                                      paddingLeft: "20px",
                                      maxHeight: "600px",
                                      maxWidth: "100%",
                                      overflow: 'scroll'
                                    }}
                                  >
                                    <Table.Cell colspan="4">
                                      <ReactJson src={event}/>
                                    </Table.Cell>
                                  </Table.Row>
                                }
                              </React.Fragment>
                            )
                          })}
                        </Table.Body>
                      </Table>
                    </div>

                    <div className="purchasedDetailsFooter">
                      <h5 style={{margin: "0 15px 0 10px", textTransform: "uppercase"}}>Actions:</h5>

                      {this.state.areActionButtonsShown ?
                        <div>
                          <Button
                            color="teal"
                            icon="mail"
                            content="Resend Receipt"
                            onClick={() => {
                              this.setState({
                                retryAction: "sendReceipt",
                                areActionButtonsShown: false,
                                email: "",
                              })
                            }}
                          />
                        </div>
                        :
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                          {this.renderRetryAction()}
                          <Button
                            content="Cancel"
                            color="red"
                            style={{height: "36px"}}
                            onClick={() => {
                              this.setState({
                                email: "",
                                retryAction: "",
                                areActionButtonsShown: true
                              })
                            }}
                          />
                        </div>
                      }
                    </div>

                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </React.Fragment>
        }
        </Segment>

        {/*Email List*/}
        {!this.state.isFetchingGiftCards ?
          <div style={this.state.giftCards.length !== 0 ? {marginBottom: "100px"} : {marginBottom: "0px"}}>
            <Divider horizontal style={{marginTop: "30px", marginBottom: "20px"}}>
              <Header>
                E-Gift Cards
              </Header>
            </Divider>

            {/*TABLE LIST*/}
            {this.state.giftCards.length === 0 ?
              <Message color='teal'  style={{textAlign:'center'}}>
                <Message.Content>
                  <Message.Header>
                    There are no Gift Cards associated with this Bulk Purchase.
                  </Message.Header>
                  <br />
                </Message.Content>
              </Message>
              :
              <PurchasedCardsTable
                cards={this.state.giftCards}
                canModifyColumns={false}
                defaultColumns={this.state.selectedColumns}
                setSelectedOptions={this.setSelectedOptions}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                getCardList={this.getGiftCards}
                page={this.state.page}
                pageSize={this.state.pageSize}
                lastPage={this.state.lastPage}
                showFirstAndLastNav={this.state.showFirstAndLastNav}
              />
            }
          </div>
          :
          <Segment basic loading/>
        }

      </Container>
    )
  }
}

export default withRouter(ViewBulkPurchase)
