import React, {Component} from "react";
import {withRouter} from 'react-router';
import {
  Button, Checkbox, Container, Table, Popup,
  Header, Icon, Input, Segment, Image, Pagination,
  Divider, Confirm, Grid, Transition,
} from "semantic-ui-react";
import "react-image-crop/dist/ReactCrop.css";
import { ChromePicker } from 'react-color';
import Base64Cropper from './Base64Cropper.js';
import APIService from '../API.js';


class DesignGiftCard extends Component {

  isAddEdit = this.props.match.params.giftcardId === undefined ? 'Add' : 'Edit';
  patchId = () => this.props.match.params.giftcardId;
  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  defaultState = {
    unmodifiedCard: {
      _id: '',
      isActive: false,
      name: '',
      createdAt: '',
      updatedAt: '',
      mainCardImage: '',
      // googlePayLogo: '',
      // googlePayHeroImage: '',
      appleWalletStripImage: '',
      appleWalletLogoImage: '',
      appleWalletIcon: '',
      cardTextColor: { hex: '#000000', rgb: 'rgb(0,0,0)', },
      cardLabelColor: { hex: '#FFFFFF', rgb: 'rgb(255,255,255)', },
      cardBackgroundColor: { hex: '#AC1925', rgb: 'rgb(172,25,37)', },
      // googleTextColor: { hex: '#000000', rgb: 'rgb(0,0,0)', },
      // googleLabelColor: { hex: '#000000', rgb: 'rgb(0,0,0)', },
      // googleBackgroundColor: { hex: '#FFFFFF', rgb: 'rgb(255,255,255)', },
    },
    _id: '',
    isActive: false,
    name: '',
    createdAt: '',
    updatedAt: '',
    mainCardImage: '',
    // googlePayLogo: '',
    // googlePayHeroImage: '',
    appleWalletStripImage: '',
    appleWalletLogoImage: '',
    appleWalletIcon: '',
    cardTextColor: { hex: '#000000', rgb: 'rgb(0,0,0)', },
    cardLabelColor: { hex: '#FFFFFF', rgb: 'rgb(255,255,255)', },
    cardBackgroundColor: { hex: '#AC1925', rgb: 'rgb(172,25,37)', },
    // googleTextColor: { hex: '#000000', rgb: 'rgb(0,0,0)', },
    // googleLabelColor: { hex: '#000000', rgb: 'rgb(0,0,0)', },
    // googleBackgroundColor: { hex: '#FFFFFF', rgb: 'rgb(255,255,255)', },

    appleIcons: undefined,
    // googlePayLogos: undefined,

    componentIsLoading: false,
    isFetchingIcons: false,
    // isFetchingLogos: false,
    editCardName: false,

    confirmAlert: false,
    previewOpen: false,
    // googleCardColorsOpen: false,
    cardIconImage: undefined,

    /* Cropper Start */
    mainCardImagePreview: undefined,
    // googlePayLogoPreview: undefined,
    // googlePayHeroImagePreview: undefined,
    appleWalletStripImagePreview: undefined,
    appleWalletLogoImagePreview: undefined,

    isEditingMainCardImage: false,
    isEditingAppleWalletStripImage: false,
    isEditingAppleWalletLogoImage: false,
    // isEditingGooglePayLogo: false,
    // isEditingGooglePayHeroImage: false,
    /* Cropper End */

    /* ICON PAGINATION */
    page: 1,
    pageSize: 12,
    lastPage: 1,
    showFirstAndLastNav: false,

    transitionVisable: false,
    // transitionVisableGoogleLogo: false,
  };

  state = this.defaultState;

  componentDidMount() {
    this.setState({ componentIsLoading: true, });
    this.getAppleIcons();
  };

  // getGooglePayLogos = () => {
  //   this.setState({ componentIsLoading: true, });
  //   fetch( APIService.serverUrl + "/google-pay-logo/list", {
  //     credentials: 'include',
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json; charset=utf-8',
  //     },
  //     body: JSON.stringify({
  //       'isActive': true,
  //       'pageSize': 25,
  //       'page': 1,
  //     }),
  //   }).then(result => {
  //       return result.json();
  //   }).then(json => {
  //       this.setState({ googlePayLogos: json.results, isFetchingLogos: false, });
  //       console.log("**** getGooglePayLogos() json.results: ", json.results);
  //       this.getAppleIcons();
  //   })
  // };

  getAppleIcons = (options) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({ isFetchingIcons: true, });
    fetch( APIService.serverUrl + "/apple-wallet-icon/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'isActive': true,
        'pageSize': options.pageSize,
        'page': options.page,
      }),
    }).then(result => {
        return result.json();
    }).then(json => {
        this.setState({
          appleIcons: json.results,
          isFetchingIcons: false,
          page: json.page ? json.page : 1,
          pageSize: json.pageSize ? json.pageSize: 12,
          lastPage: json.lastPage ? json.lastPage : 1,
        });
        console.log("**** getAppleIcons() json: ", json);
        this.getCard();
    })
  };

  getCard = () => {
    if(this.isAddEdit === 'Edit') {
      // this.setState({componentIsLoading: true});
      fetch( APIService.serverUrl + "/gift-card-design/" + this.patchId(), {
        credentials: 'include',
        headers: {
          clientId: APIService.clientId
        },
        method: "GET",
      }).then(result => {
          return result.json();
      }).then(json => {
        this.setCardStateWithJSON(json);
        // console.log("**** getCard() results: ", json);
      });
    } else {
      this.setState({
        componentIsLoading: false,
        confirmAlert: false,
      });
    };
  };

  setCardStateWithJSON = (json) => {
    let tempCard = {
      _id: json._id,
      isActive: json.isActive,
      name: json.name,
      createdAt: json.createdAt,
      updatedAt: json.updatedAt,
      mainCardImage: json.mainCardImage ? json.mainCardImage : '',
      appleWalletIcon: this.setAppleWalletIcon(json.appleWalletIcon),
      // googlePayLogo: this.setGooglePayLogo(json.googlePayLogo),
      // googlePayHeroImage: json.googlePayHeroImage ? json.googlePayHeroImage : '',
      appleWalletStripImage: json.appleWalletStripImage ? json.appleWalletStripImage : '',
      appleWalletLogoImage: json.appleWalletLogoImage ? json.appleWalletLogoImage : '',
      cardTextColor: {
        hex: this.convertRGBToHEX(json.foregroundColor ? json.foregroundColor : 'rgb(0,0,0)'),
        rgb: json.foregroundColor ? json.foregroundColor : 'rgb(0,0,0)',
      },
      cardLabelColor: {
        hex: this.convertRGBToHEX(json.labelColor ? json.labelColor : 'rgb(255,255,255)'),
        rgb: json.labelColor ? json.labelColor : 'rgb(255,255,255)',
      },
      cardBackgroundColor: {
        hex: this.convertRGBToHEX(json.backgroundColor ? json.backgroundColor : 'rgb(172,25,37)'),
        rgb: json.backgroundColor ? json.backgroundColor : 'rgb(172,25,37)',
      },
      // googleTextColor: {
      //   hex: this.convertRGBToHEX('rgb(0,0,0)'),
      //   rgb: 'rgb(0,0,0)',
      // },
      // googleLabelColor: {
      //   hex: this.convertRGBToHEX('rgb(255,255,255)'),
      //   rgb: 'rgb(255,255,255)',
      // },
      // googleBackgroundColor: {
      //   hex: this.convertRGBToHEX('rgb(255,255,255)'),
      //   rgb: 'rgb(255,255,255)',
      // },
    }
    this.setState({
      unmodifiedCard: {...tempCard},
      ...tempCard,
      componentIsLoading: false,
      confirmAlert: false,
    });
  };

  saveCard = () => {
    this.setState({componentIsLoading: true});
    let body = {
      _id: this.state._id,
      isActive: this.state.isActive,
      name: this.state.name,
      // createdAt: this.state.createdAt,
      // updatedAt: this.state.updatedAt,
      mainCardImage: this.state.mainCardImage,
      // googlePayLogo: this.state.googlePayLogo ? this.state.googlePayLogo._id : undefined,
      // googlePayHeroImage: this.state.googlePayHeroImage,
      appleWalletStripImage: this.state.appleWalletStripImage,
      appleWalletLogoImage: this.state.appleWalletLogoImage,
      appleWalletIcon: this.state.appleWalletIcon ? this.state.appleWalletIcon._id : undefined,
      labelColor: this.state.cardLabelColor.rgb,
      foregroundColor: this.state.cardTextColor.rgb,
      backgroundColor: this.state.cardBackgroundColor.rgb,
      // googleLabelColor: this.state.googleLabelColor.rgb,
      // googleForegroundColor: this.state.googleTextColor.rgb,
      // googleBackgroundColor: this.state.googleBackgroundColor.rgb,
    };
    // console.log('***** body: ',body);
    let fetchPath = (this.isAddEdit === 'Add') ?
      APIService.serverUrl + "/gift-card-design" :
      APIService.serverUrl + "/gift-card-design/" + this.patchId();
    fetch( fetchPath, {
      credentials: 'include',
      method: this.isAddEdit === 'Add' ? 'POST' : 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify( body )
    })
      .then((response) => {
        if(response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        if(json) {
          this.setState({
            componentIsLoading: false,
            editCardName: false,
            isEditingMainCardImage: false,
            // isEditingGooglePayLogo: false,
            // isEditingGooglePayHeroImage: false,
            isEditingAppleWalletStripImage: false,
            isEditingAppleWalletLogoImage: false,
          });
          this.setCardStateWithJSON(json);
          this.isAddEdit = 'Edit';
          this.props.history.replace('/giftcard/design/'+json._id);
          // console.log('***** json after saveCard(): ',json);
        } else {
          // console.log('saveCard PATCH FAILED');
        }
      })
  };

  setAppleWalletIcon = (iconId) => {
    if (iconId === undefined) {
      return '';
    } else {
      for (var key in this.state.appleIcons) {
        if (this.state.appleIcons[key]._id === iconId) {
          return this.state.appleIcons[key];
        }
      }
    }
  }

  updateCardImages = (image) => {
    this.setState({
      ...image,
      isEditingMainCardImage: false,
      // isEditingGooglePayHeroImage: false,
      isEditingAppleWalletStripImage: false,
      isEditingAppleWalletLogoImage: false,
    });
  };

  unsavedAlert = () => {
    if (this.isFormModified()) {
      this.setState({ confirmAlert: true, });
    } else {
      this.props.history.push('/giftcard')
    }
  };

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      editCardName: e.target.value ? true : false,
    });
  };

  onToggleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  isPreviewEmpty = (dataRef) => {
    switch (dataRef) {
      case 'mainCardImagePreview':
        if(this.state.mainCardImagePreview !== undefined) {
          return (
            <Image
              rounded
              centered
              size='medium'
              alt="Main Card Image"
              className='imageShadow'
              src={this.state.mainCardImagePreview} /> );
        } else {
          return <Image rounded centered alt="Preview Image" className='mainCardImageFrame' src={'/image-frame.png'} />;
        };
      case 'appleWalletLogoImagePreview':
        if (this.state.appleWalletLogoImagePreview !== undefined) {
          return(
            <Image
              alt="Apple Logo Image"
              id='previewLogoImage'
              className='previewLogoImage'
              src={this.state.appleWalletLogoImagePreview} />);
        } else {
          return <Image alt="Preview Image" id='logoCardImageFrame' className='logoCardImageFrame' src={'/image-frame.png'} />;
        };
      case 'appleWalletStripImagePreview':
        if (this.state.appleWalletStripImagePreview !== undefined) {
          return (
            <Image
              centered
              size='large'
              alt="Apple Strip Image"
              className='stripCardImageFrame'
              src={this.state.appleWalletStripImagePreview} />);
        } else {
          return <Image alt="Preview Image" className='stripCardImageFrame' src={'/image-frame.png'} />;
        };
      // case 'googlePayHeroImagePreview':
      //   if (this.state.googlePayHeroImagePreview !== undefined) {
      //     return (
      //       <Image
      //         centered
      //         size='medium'
      //         className={'googlePayHeroImage'}
      //         id={'googlePayHeroImage'}
      //         alt="Google Pay Hero Image"
      //         style={{width:'344px',height:'115px',}}
      //         src={this.state.googlePayHeroImagePreview} /> );
      //   } else {
      //     return (
      //       <Image
      //         centered
      //         size='medium'
      //         className={'googlePayHeroImage'}
      //         id={'googlePayHeroImage'}
      //         alt="Preview Image"
      //         style={{width:'344px',height:'115px',}}
      //         src={'/image-frame.png'} /> );
      //   };
      default:
        return <Icon name='image outline' size='massive' />;
    };
  }

  setCardIcon = (icon) => {
    this.setState({
      appleWalletIcon: icon,
      transitionVisable: false,
    });
  }

  changeCardLbColor = (color) => {
    this.setState({
      cardLabelColor: {
        rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
        hex: color.hex,
      },
    });
  };

  changeCardTxColor = (color) => {
    this.setState({
      cardTextColor: {
        rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
        hex: color.hex,
      }
    });
  };

  changeCardBgColor = (color) => {
    this.setState({
      cardBackgroundColor: {
        rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
        hex: color.hex,
      }
    });
  };

  // setGooglePayLogo = (logoId) => {
  //   if (logoId === undefined) {
  //     return '';
  //   } else {
  //     for (var key in this.state.googlePayLogos) {
  //       if (this.state.googlePayLogos[key]._id === logoId) {
  //         return this.state.googlePayLogos[key];
  //       }
  //     }
  //   }
  // }

  // setCardGoogleLogo = (logo) => {
  //   this.setState({
  //     googlePayLogo: logo,
  //     isEditingGooglePayLogo: false,
  //   });
  // };

  // changeGoogleLbColor = (color) => {
  //   this.setState({
  //     googleLabelColor: {
  //       rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
  //       hex: color.hex,
  //     },
  //   });
  // };

  // changeGoogleTxColor = (color) => {
  //   this.setState({
  //     googleTextColor: {
  //       rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
  //       hex: color.hex,
  //     }
  //   });
  // };

  // changeGoogleBgColor = (color) => {
  //   this.setState({
  //     googleBackgroundColor: {
  //       rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
  //       hex: color.hex,
  //     }
  //   });
  // };

  convertRGBToHEX = (rgb) => {
    let sep = rgb.indexOf(",") > -1 ? "," : " ";
    rgb = rgb.substr(4).split(")")[0].split(sep);
    let r = (+rgb[0]).toString(16),
        g = (+rgb[1]).toString(16),
        b = (+rgb[2]).toString(16);
    if (r.length === 1)
      r = "0" + r;
    if (g.length === 1)
      g = "0" + g;
    if (b.length === 1)
      b = "0" + b;
    return "#" + r + g + b;
  };

  isFormModified = () => {
    let isValueModified = false;
    for (var key in this.state.unmodifiedCard) {

      let value = this.state.unmodifiedCard[key];
      let stateValue = this.state[key];

      if (typeof value !== typeof stateValue) {
        isValueModified = true;
      } else {
        switch (typeof value) {
          case 'object':
            Object.keys(value).forEach((nestedKey, i) => {
              if (value[nestedKey] !== stateValue[nestedKey]) {
                isValueModified = true;
              };
            });
            break;
          case 'array': // POSSIBLE ARRAY OF OBJECT ISSUE
            if (value.length !== stateValue.length) {
              isValueModified = true;
            } else {
              value.forEach((arrayItem, index) => {
                if (arrayItem !== stateValue[index]) {
                  isValueModified = true;
                }
              })
            }
            break;
          default:
            if (this.state.unmodifiedCard[key] !== this.state[key]) {
              isValueModified = true;
            }
            break;
        }
      }
    }
    return isValueModified;
  };

  isDisabled = () => {
    if (this.state.name === '') { return true; } else { return false; }
  };

  render = () => {

    const { appleIcons, } = this.state; /* googlePayLogos, */

    return (

      <Container fluid>

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing >
                  <Button
                    icon='chevron left'
                    content='Back'
                    disabled={this.state.componentIsLoading}
                    onClick={() => this.unsavedAlert()} />
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* ACTIVE & SAVE BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='bottom' >
                  <span style={{ fontSize: "14px", }}>Change the Gift Card title or any of the images.</span>
                </Table.Cell>
                <Table.Cell collapsing >
                  <Checkbox
                    slider
                    id='isActive'
                    name='isActive'
                    label='Active'
                    checked={this.state.isActive}
                    onChange={this.onToggleChange} />&nbsp;
                </Table.Cell>
                <Table.Cell collapsing >
                  <Button
                    icon='save'
                    color={this.isFormModified() ? 'teal' : 'grey'}
                    content={(this.isAddEdit === 'Add') ? 'Add' : 'Save'}
                    disabled={!this.isFormModified() || this.isDisabled()}
                    onClick={ () => this.saveCard() } />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
        </Container>
        {/* CARD NAME INPUT */}
        <Container>
          <Segment basic size='tiny' loading={this.state.componentIsLoading} style={{ marginBottom:'20px', }} >
            <Popup
              position='top right'
              size='mini'
              open={this.state.name ? false : true}
              trigger={
                <Input
                  fluid
                  icon={{ name:this.state.name ? '' : 'asterisk', color:this.state.name ? 'black' : 'red', size:'small' }}
                  label={{ content: 'Gift Card Title', labelposition: 'left', }}
                  size='huge'
                  id='name'
                  name='name'
                  value={this.state.name}
                  onChange={this.onInputChange} />
              }
            ><Icon name='asterisk' color='red' /> Gift Card Title is Required.</Popup>
          </Segment>
        </Container>
        {/* MAIN CARD IMAGE SEGMENT */}
        <Container fluid style={{ minHeight: '372px' }} >
          <Container style={{padding: '10px 0px'}}>
            <Divider horizontal ><Header>Main Card Image</Header></Divider>
            <Grid stackable >
              <Grid.Row>
                <Grid.Column width='8' >
                  {!this.state.mainCardImage && !this.state.isEditingMainCardImage ?
                    <Segment textAlign='center' loading={this.state.componentIsLoading} basic >
                      <p style={{fontSize:'12px',}}>
                        This image is seen by the purchaser when choosing a card design on the website.
                      </p>
                      <Image
                        className='imageShadow'
                        rounded
                        centered
                        size='medium'
                        src='/image-frame.png'
                        style={{width:'300px',height:'190px',}} /><br />
                      <Button
                        color={!this.isDisabled() ? 'teal' : 'grey'}
                        disabled={(!this.state.editCardName && !this.state.name)}
                        onClick={ () => {this.setState({ isEditingMainCardImage: true })} }
                      >
                        <Icon name='upload' />
                        {this.isDisabled() ? 'Upload' : 'Upload'}
                      </Button>
                    </Segment>
                    :
                    <Segment textAlign='center' loading={this.state.componentIsLoading} basic >
                      <p style={{fontSize:'12px',}}>
                        This image is seen by the purchaser when choosing a card design on the website.
                      </p>
                      {this.state.isEditingMainCardImage ?
                        this.isPreviewEmpty('mainCardImagePreview')
                        :
                        <Image
                          className='imageShadow'
                          rounded
                          centered
                          size='medium'
                          alt="Main Card Image"
                          src={'data:image;base64, '+this.state.mainCardImage} />
                      }<br />
                      <Button
                        color={this.state.isEditingMainCardImage ? 'grey' : 'teal'}
                        disabled={this.state.isEditingMainCardImage}
                        onClick={ () => {this.setState({ isEditingMainCardImage: true })} }
                      >
                        <Icon name='edit'/>
                        Edit Main Image
                      </Button>
                    </Segment>
                  }
                </Grid.Column>
                <Grid.Column width='8' >
                  {!this.state.isEditingMainCardImage ? null :
                    <div>
                      <div style={{fontSize:'12px', textAlign:'left', paddingBottom:'6px', }}>
                        Size: 520 x 330 &#8226; Rounded Corners 6px &#8226; RGB &#8226; 72dpi<br />
                      </div>
                      <Base64Cropper
                        aspectRatio={ 3.375/2.125 }
                        widths={ [800] }
                        onChange={ (mainCardImagePreview) => {
                          this.setState({ mainCardImagePreview })
                        }}
                        onSave={ (saveData) => { this.updateCardImages({ mainCardImage: saveData.size800 }); }}
                        onCancel={ () => {
                          this.setState({
                            isEditingMainCardImage: false,
                            mainCardImagePreview: undefined, })
                        }}
                      />
                    </div>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Container>
        {/* APPLE WALLET STRIP & LOGO IMAGE SEGMENT */}
        <Container >
          <Container style={{padding: '10px 0px'}}>
            <Divider horizontal ><Header>Apple Wallet Logo & Strip Image</Header></Divider>
            <Grid stackable >
              <Grid.Row>
                <Grid.Column width='8' align='center' >
                  {/* APPLE WALLET CARD PREVIEW */}
                  <Segment basic loading={this.state.componentIsLoading} >
                    <p style={{fontSize:'12px', marginTop: '10px'}}>
                      The Apple Wallet will be seen by Apple recipients who opt to "Add to Apple Wallet" from their email. It consists of a logo image, strip image, and a few color options.
                    </p>
                    {/* CARD PREVIEW DIV */}
                    <div
                      className='previewCardDiv'
                      style={{
                        backgroundColor:this.state.cardBackgroundColor.hex,
                      }} >
                      <div style={{ height:'60px', }} >
                        <div
                          style={{
                            textAlign:'left',
                            width:'70%',
                            float:'left',
                            align:'left',
                            verticalAlign:'middle',
                          }} >
                            {this.state.isEditingAppleWalletLogoImage ?
                              this.isPreviewEmpty('appleWalletLogoImagePreview')
                              :
                              <Image
                                className={this.state.appleWalletLogoImage ? 'previewLogoImage' : 'logoCardImageFrame'}
                                id={this.state.appleWalletLogoImage ? 'previewLogoImage' : 'logoCardImageFrame'}
                                alt="Apple Wallet Logo Image"
                                src={
                                  this.state.appleWalletLogoImage ?
                                  'data:image;base64, '+this.state.appleWalletLogoImage :
                                  '/image-frame.png'
                                } />
                            }
                        </div>
                        <div
                          style={{
                            textAlign:'right',
                            verticalAlign:'middle',
                            width:'30%',
                            float:'left',
                            fontSize:'10px',
                            paddingRight:'10px',
                            paddingTop:'10px',
                            color:this.state.cardLabelColor.hex,
                          }} >
                          INITIAL BALANCE<br />
                          <span
                            style={{
                              fontWeight:'500',
                              fontSize:'20px',
                              color:this.state.cardTextColor.hex,
                            }}
                          >$25.00</span>
                        </div>
                      </div>
                      <div
                        style={{
                          height:'150px',
                          padding:'0px',
                        }} >
                        {this.state.isEditingAppleWalletStripImage ?
                          this.isPreviewEmpty('appleWalletStripImagePreview')
                          :
                          <Image
                            centered
                            size='large'
                            className={this.state.appleWalletStripImage ? null : 'stripCardImageFrame'}
                            id={this.state.appleWalletStripImage ? null : 'stripCardImageFrame'}
                            alt="Apple Wallet Strip Image"
                            src={
                              this.state.appleWalletStripImage ?
                              'data:image;base64, '+this.state.appleWalletStripImage :
                              '/image-frame.png'
                            } />
                        }
                      </div>
                      <div
                        style={{
                          textAlign:'left',
                          fontSize:'10px',
                          paddingLeft:'10px',
                          height:'145px',
                          color:this.state.cardLabelColor.hex,
                        }} >
                        CARD NUMBER<br />
                        <span
                          style={{
                            fontWeight:'500',
                            fontSize:'20px',
                            color:this.state.cardTextColor.hex,
                          }} >1234-5678-9012-3456</span>
                      </div>
                      <div
                        style={{
                          heigh:'150px',
                          align:'bottom',
                          display:'flex',
                          justifyContent:'flexEnd',
                          flexDirection:'column',
                        }} >
                        <Image
                          centered
                          rounded
                          size='small'
                          src='/barcode-new.png' />
                      </div>
                    </div>
                    {/* BUTTONS DIV */}
                    <div
                      style={{
                        width:'325px',
                      }} >
                      <div
                        style={{
                          margin:'10px',
                        }} >
                        <div
                          style={{
                            marginTop:'10px',
                            textAlign:'left',
                            width:'50%',
                            float:'left',
                            verticalAlign:'middle',
                            padding:'0px 5px 0px 0px',
                          }} >
                          {!this.state.appleWalletLogoImage && !this.state.isEditingAppleWalletLogoImage ?
                              <Button
                                size='small'
                                style={{width:'100%',}}
                                color={!this.isDisabled() || this.state.previewOpen ? 'teal' : 'grey'}
                                disabled={(!this.state.editCardName && !this.state.name)|| this.state.previewOpen}
                                onClick={ ()=> {this.setState({ isEditingAppleWalletLogoImage: true })} }
                              >
                                <Icon name='upload' />
                                {this.isDisabled() ? 'Upload Logo' : 'Upload Logo'}
                              </Button>
                            :
                              <Button
                                size='small'
                                style={{width:'100%',}}
                                color={
                                  this.state.isEditingAppleWalletStripImage ||
                                  this.state.isEditingAppleWalletLogoImage ||
                                  this.state.previewOpen ? 'grey' : 'teal'
                                }
                                disabled={
                                  this.state.isEditingAppleWalletStripImage ||
                                  this.state.isEditingAppleWalletLogoImage ||
                                  this.state.previewOpen
                                }
                                onClick={ () => {this.setState({ isEditingAppleWalletLogoImage: true })} }
                              >
                                <Icon name='edit'/>
                                Edit Logo
                              </Button>
                          }
                        </div>
                        <div
                          style={{
                            marginTop:'10px',
                            textAlign:'left',
                            width:'50%',
                            float:'left',
                            verticalAlign:'middle',
                            padding:'0px 0px 0px 5px',
                          }} >
                          {!this.state.appleWalletStripImage && !this.state.isEditingAppleWalletStripImage ?
                              <Button
                                size='small'
                                floated='right'
                                style={{width:'100%',}}
                                color={!this.isDisabled() || this.state.previewOpen ? 'teal' : 'grey'}
                                disabled={(!this.state.editCardName && !this.state.name) || this.state.previewOpen}
                                onClick={ ()=> {this.setState({ isEditingAppleWalletStripImage: true })} }
                              >
                                <Icon name='upload' />
                                {this.isDisabled() ? 'Upload Strip' : 'Upload Strip'}
                              </Button>
                            :
                              <Button
                                size='small'
                                floated='right'
                                style={{width:'100%',}}
                                color={
                                  this.state.isEditingAppleWalletStripImage ||
                                  this.state.isEditingAppleWalletLogoImage ||
                                  this.state.previewOpen ? 'grey' : 'teal'
                                }
                                disabled={
                                  this.state.isEditingAppleWalletStripImage ||
                                  this.state.isEditingAppleWalletLogoImage ||
                                  this.state.previewOpen
                                }
                                onClick={ () => {this.setState({ isEditingAppleWalletStripImage: true })} }
                              >
                                <Icon name='edit'/>
                                Edit Strip
                              </Button>
                          }
                        </div>
                      </div>
                      <div
                        style={{
                          margin:'10px',
                        }} >
                        <div
                          style={{
                            marginTop:'10px',
                            textAlign:'left',
                            width:'50%',
                            float:'left',
                            verticalAlign:'middle',
                            padding:'0px 5px 0px 0px',
                          }} >
                          &nbsp;
                        </div>
                        <div
                          style={{
                            marginTop:'10px',
                            textAlign:'left',
                            width:'50%',
                            float:'left',
                            verticalAlign:'middle',
                            padding:'0px 0px 0px 5px',
                          }} >
                          <Button
                            size='small'
                            icon='paint brush'
                            floated='right'
                            style={{width:'100%',}}
                            color={
                              this.isDisabled() ||
                              this.state.isEditingAppleWalletStripImage ||
                              this.state.isEditingAppleWalletLogoImage ||
                              this.state.previewOpen ? 'grey' : 'teal'
                            }
                            content={'Edit Colors'}
                            disabled={
                              this.state.isEditingAppleWalletStripImage ||
                              this.state.isEditingAppleWalletLogoImage ||
                              this.state.previewOpen ||
                              (!this.state.editCardName && !this.state.name)
                            }
                            onClick={() => this.setState({ previewOpen: !this.state.previewOpen, })} />
                        </div>
                      </div>
                    </div>

                  </Segment>

                </Grid.Column>
                <Grid.Column width='8' align='center' verticalAlign='middle' >

                  {(!this.state.isEditingAppleWalletStripImage && !this.state.isEditingAppleWalletLogoImage) ? null :
                    <div>
                      <div style={{fontSize:'12px', textAlign:'left', paddingBottom:'6px', }}>
                        {this.state.isEditingAppleWalletStripImage ?
                          <span>Size: 600 x 150 &#8226; Square-cut &#8226; RGB &#8226; 72dpi<br /></span> :
                          <span>Size: 480 x 150 &#8226; Square-cut &#8226; RGB &#8226; 72dpi<br /></span>
                        }
                      </div>
                      {this.state.isEditingAppleWalletStripImage ?
                        <Base64Cropper
                          aspectRatio={ 1125/432 }
                          widths={ [1125] }
                          onChange={ (appleWalletStripImagePreview) => {
                            this.setState({ appleWalletStripImagePreview })
                          }}
                          onSave={ (saveData) => { this.updateCardImages({ appleWalletStripImage: saveData.size1125 }); }}
                          onCancel={ () => {
                            this.setState({
                              isEditingAppleWalletStripImage: false,
                              appleWalletStripImagePreview: undefined, })
                          }} />
                        :
                          <Base64Cropper
                            aspectRatio={ 960/300 }
                            widths={ [960] }
                            onChange={ (appleWalletLogoImagePreview) => {
                              this.setState({ appleWalletLogoImagePreview })
                            }}
                            onSave={ (saveData) => { this.updateCardImages({ appleWalletLogoImage: saveData.size960 }); }}
                            onCancel={ () => {
                              this.setState({
                                isEditingAppleWalletLogoImage: false,
                                appleWalletLogoImagePreview: undefined, })
                            }} />
                      }
                    </div>
                  }

                  {!this.state.previewOpen ? null :
                    <Grid centered columns={2} stackable >
                      <Grid.Row>
                        <Grid.Column textAlign='center' >
                          Label Color<br />
                          <ChromePicker
                            width='175px'
                            disableAlpha={true}
                            color={this.state.cardLabelColor.hex}
                            onChange={this.changeCardLbColor} />
                        </Grid.Column>
                        <Grid.Column textAlign='center' >
                          Text Color<br />
                          <ChromePicker
                            width='175px'
                            disableAlpha={true}
                            color={this.state.cardTextColor}
                            onChangeComplete={ this.changeCardTxColor }/>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column textAlign='center' >
                          Background Color<br />
                          <ChromePicker
                            width='175px'
                            disableAlpha={true}
                            color={this.state.cardBackgroundColor.hex}
                            onChange={this.changeCardBgColor} />
                        </Grid.Column>
                        <Grid.Column textAlign='center' verticalAlign='middle' >
                          <Button
                            size='small'
                            icon='check'
                            color={!this.isDisabled() ? 'teal' : 'grey'}
                            content={'Close'}
                            disabled={(!this.state.editCardName && !this.state.name)}
                            onClick={() => this.setState({ previewOpen: !this.state.previewOpen, })} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div style={{ height:'20px', }} ></div>
          </Container>
        </Container>
        {/* APPLE ICON SEGMENT  */}
        <Container fluid >
          <Container style={{padding: '10px 0px'}}>
            <Divider horizontal ><Header>Apple Icon Image</Header></Divider>
            <Grid stackable >
              <Grid.Row>
                <Grid.Column width='8' align='center' >
                  <Segment textAlign='center' loading={this.state.componentIsLoading} basic >
                    <p style={{fontSize:'12px', marginBottom: '0'}}>
                      The "icon" image is displayed when an Apple Wallet card holder recieves a notification.
                    </p>

                    <div className='previewIconDiv' >
                      <div>
                        <div className='previewIconIcon'>
                          <Image
                            rounded
                            centered
                            src={this.state.appleWalletIcon ?
                              this.apiUrl+this.state.appleWalletIcon.image :
                              '/image-frame.png'
                            } />
                        </div>
                        <div className='previewIconTitle'>DONATOS PIZZA</div>
                        <div className='previewIconWhen'>now</div>
                      </div>
                      <div className='previewIconText'>
                        <span style={{ fontWeight:'700', fontSize:'16px', }}>Get 20% Off with any Online Order!</span><br />
                        Save all week long when you order online and enter the promo code: "Pizza Me!"
                      </div>
                    </div>
                    <div>
                      <Button
                        size='small'
                        color={!this.isDisabled() ? 'teal' : 'grey'}
                        disabled={(!this.state.editCardName && !this.state.name)}
                        onClick={ () => {this.setState({ transitionVisable: !this.state.transitionVisable })} }
                      >
                        <Icon name='edit'/>
                        Edit Icon
                      </Button>
                    </div>
                  </Segment>
                </Grid.Column>
                <Grid.Column width='8' align='center' >
                  <Transition
                    duration={200}
                    animation='slide down'
                    visible={this.state.transitionVisable}
                  >
                    <Segment basic compact loading={this.state.isFetchingIcons} >
                      <div
                        style={{
                          backgroundColor:'#EEEEEE',
                          borderRadius:4,
                          padding:6,
                          minHeight: 300,
                        }} >
                        <Image.Group size='tiny'>
                          {!appleIcons ? null :
                            appleIcons.map((icon) => { return (
                              <Image
                                key={Math.random()}
                                rounded
                                centered
                                id='iconImageCard'
                                alt='Apple Icon'
                                disabled={!icon.isActive}
                                style={{cursor: 'pointer'}}
                                src={this.apiUrl+icon.image}
                                className='iconImage imageShadow'
                                onClick={ () => this.setCardIcon(icon) } />
                            )})
                          }
                        </Image.Group>
                      </div>
                      {this.state.lastPage < 2 ? null :
                        <div style={{ textAlign:'center', }} >
                          <Pagination
                            secondary
                            pointing
                            size='tiny'
                            color='teal'
                            defaultActivePage={this.state.page}
                            totalPages={this.state.lastPage}
                            boundaryRange={1}
                            siblingRange={1}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={this.state.showFirstAndLastNav ?
                              { content: <Icon name='angle double left' />, icon: true } : null }
                            lastItem={this.state.showFirstAndLastNav ?
                              { content: <Icon name='angle double right' />, icon: true } : null }
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            onPageChange={(e,{activePage}) => {
                              this.setState({ page: activePage });
                              this.getAppleIcons({ page: activePage });
                            }}
                          />
                        </div>
                      }
                    </Segment>
                  </Transition>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Container>
        {/* GOOGLE PAY LOGO & HERO IMAGE SEGMENT */} {/*
        <Container >
          <Container style={{padding: '10px 0px'}}>
            <Divider horizontal ><Header>Google Pay Logo & Hero Image</Header></Divider>
            <Grid >
              <Grid.Row>
                <Grid.Column width='8' align='center' >
                  {/* GOOGLE PAY CARD PREVIEW * /}
                  <Segment basic loading={this.state.componentIsLoading} >
                    <p style={{fontSize:'12px', marginTop: '10px'}}>
                      The Google Pay Card will be seen by Android recipients who opt to "Add to Google Pay" from their email. It consists of a logo image, strip image, and a few color options.
                    </p>
                    {/* GOOGLE PAY PREVIEW * /}
                    <div
                      className='previewGoogleCardDiv'
                      style={{
                        backgroundColor:this.state.googleBackgroundColor.hex,
                      }} >
                      <div className='googlePayLogoDiv' >
                        <Image
                          circular
                          size='medium'
                          alt="Google Pay Logo Image"
                          className='googlePayLogo'
                          id='googlePayLogo'
                          src={
                            this.state.googlePayLogo ?
                            this.apiUrl+this.state.googlePayLogo.image :
                            '/image-frame.png'
                          } />
                      </div>
                      <div
                        className='googlePayMerchantName'
                        style={{ color:this.state.googleLabelColor.hex, }} >
                        Donatos Pizza
                      </div>
                      <div
                        className='googlePayBalance'
                        style={{ color:this.state.googleTextColor.hex, }} >
                        GIFT CARD: $25.00
                      </div>
                      <div
                        style={{
                          height:'115px',
                          marginBottom:'35px',
                        }} >
                        {this.state.isEditingGooglePayHeroImage ?
                          this.isPreviewEmpty('googlePayHeroImagePreview')
                          :
                          <Image
                            centered
                            size='large'
                            className={'googlePayHeroImage'}
                            id={'googlePayHeroImage'}
                            alt="Google Pay Hero Image"
                            src={
                              this.state.googlePayHeroImage ?
                              'data:image;base64, '+this.state.googlePayHeroImage :
                              '/image-frame.png'
                            } />
                        }
                      </div>
                      <div>
                        <Image
                          centered
                          rounded
                          size='small'
                          src='/barcode-new.png' />
                      </div>
                      <div style={{ color:this.state.googleLabelColor.hex, }} >barcode label</div>
                    </div>
                    {/* GOOGLE PAY BUTTONS * /}
                    <div
                      style={{
                        width:'325px',
                      }} >
                      <div
                        style={{
                          margin:'10px',
                        }} >
                        <div
                          style={{
                            marginTop:'10px',
                            textAlign:'left',
                            width:'50%',
                            float:'left',
                            verticalAlign:'middle',
                            padding:'0px 5px 0px 0px',
                          }} >
                          {!this.state.googlePayLogo && !this.state.isEditingGooglePayLogo ?
                              <Button
                                size='small'
                                style={{width:'100%',}}
                                color={
                                  this.isDisabled() ||
                                  this.state.isEditingGooglePayHeroImage ||
                                  this.state.isEditingGooglePayLogo ||
                                  this.state.googleCardColorsOpen ? 'grey' : 'teal'
                                }
                                disabled={
                                  (!this.state.editCardName && !this.state.name) ||
                                  this.state.isEditingGooglePayHeroImage ||
                                  this.state.isEditingGooglePayLogo ||
                                  this.state.googleCardColorsOpen
                                }
                                onClick={ ()=> {this.setState({ isEditingGooglePayLogo: true })} }
                              >
                                <Icon name='upload' />
                                {this.isDisabled() ? 'Upload Logo' : 'Upload Logo'}
                              </Button>
                            :
                              <Button
                                size='small'
                                style={{width:'100%',}}
                                color={
                                  this.isDisabled() ||
                                  this.state.isEditingGooglePayHeroImage ||
                                  this.state.isEditingGooglePayLogo ||
                                  this.state.googleCardColorsOpen ? 'grey' : 'teal'
                                }
                                disabled={
                                  this.isDisabled() ||
                                  this.state.isEditingGooglePayHeroImage ||
                                  this.state.isEditingGooglePayLogo ||
                                  this.state.googleCardColorsOpen
                                }
                                onClick={ () => {this.setState({ isEditingGooglePayLogo: true })} }
                              >
                                <Icon name='edit'/>
                                Edit Logo
                              </Button>
                          }
                        </div>
                        <div
                          style={{
                            marginTop:'10px',
                            textAlign:'left',
                            width:'50%',
                            float:'left',
                            verticalAlign:'middle',
                            padding:'0px 0px 0px 5px',
                          }} >
                          {!this.state.googlePayHeroImage && !this.state.isEditingGooglePayHeroImage ?
                              <Button
                                size='small'
                                floated='right'
                                style={{width:'100%',}}
                                color={
                                  this.isDisabled() ||
                                  this.state.isEditingGooglePayHeroImage ||
                                  this.state.isEditingGooglePayLogo ||
                                  this.state.googleCardColorsOpen ? 'grey' : 'teal'
                                }
                                disabled={
                                  this.isDisabled() ||
                                  this.state.isEditingGooglePayHeroImage ||
                                  this.state.isEditingGooglePayLogo ||
                                  this.state.googleCardColorsOpen ||
                                  (!this.state.editCardName && !this.state.name)
                                }
                                onClick={ ()=> {this.setState({ isEditingGooglePayHeroImage: true })} }
                              >
                                <Icon name='upload' />
                                {this.isDisabled() ? 'Upload Hero' : 'Upload Hero'}
                              </Button>
                            :
                              <Button
                                size='small'
                                floated='right'
                                style={{width:'100%',}}
                                color={
                                  this.state.isEditingGooglePayHeroImage ||
                                  this.state.isEditingGooglePayLogo ||
                                  this.state.googleCardColorsOpen ? 'grey' : 'teal'
                                }
                                disabled={
                                  this.state.isEditingGooglePayHeroImage ||
                                  this.state.isEditingGooglePayLogo ||
                                  this.state.googleCardColorsOpen
                                }
                                onClick={ () => {this.setState({ isEditingGooglePayHeroImage: true })} }
                              >
                                <Icon name='edit'/>
                                Edit Hero
                              </Button>
                          }
                        </div>
                      </div>
                      <div
                        style={{
                          margin:'10px',
                        }} >
                        <div
                          style={{
                            marginTop:'10px',
                            textAlign:'left',
                            width:'50%',
                            float:'left',
                            verticalAlign:'middle',
                            padding:'0px 5px 0px 0px',
                          }} >&nbsp; {/* REMOVE &nbsp; IF ADDING CONTENT HERE * /}
                          {/* NO GOOGLE COLORS FOR NOW * /}
                          <Button
                            size='small'
                            icon='paint brush'
                            style={{width:'100%',}}
                            color={
                              this.isDisabled() ||
                              this.state.isEditingGooglePayHeroImage ||
                              this.state.isEditingGooglePayLogo ||
                              this.state.googleCardColorsOpen ? 'grey' : 'teal'
                            }
                            content={'Edit Colors'}
                            disabled={
                              this.isDisabled() ||
                              this.state.isEditingGooglePayHeroImage ||
                              this.state.isEditingGooglePayLogo ||
                              this.state.googleCardColorsOpen ||
                              (!this.state.editCardName && !this.state.name)
                            }
                            onClick={() => this.setState({ googleCardColorsOpen: !this.state.googleCardColorsOpen, })} />
                          {/* * /}
                        </div>
                        <div
                          style={{
                            marginTop:'10px',
                            textAlign:'left',
                            width:'50%',
                            float:'left',
                            verticalAlign:'middle',
                            padding:'0px 0px 0px 5px',
                          }} >&nbsp;</div>
                      </div>
                    </div>

                  </Segment>

                </Grid.Column>
                <Grid.Column width='8' align='center' verticalAlign='middle' >

                  {!this.state.isEditingGooglePayHeroImage ? null :
                    <div>
                      <div style={{fontSize:'12px', textAlign:'left', paddingBottom:'6px', }}>
                        <span>Size: 344 x 115 &#8226; Square-cut &#8226; RGB &#8226; 72dpi<br /></span>
                      </div>

                          <Base64Cropper
                            aspectRatio={ 344/115 }
                            widths={ [344] }
                            onChange={ (googlePayHeroImagePreview) => {
                              this.setState({ googlePayHeroImagePreview })
                            }}
                            onSave={ (saveData) => { this.updateCardImages({ googlePayHeroImage: saveData.size344 }); }}
                            onCancel={ () => {
                              this.setState({
                                isEditingGooglePayHeroImage: false,
                                googlePayHeroImagePreview: undefined, })
                            }}
                          />
                    </div>
                  }

                  <Transition
                    duration={200}
                    animation='slide down'
                    visible={this.state.isEditingGooglePayLogo} >
                    <div
                      style={{
                        backgroundColor:'#EEEEEE',
                        borderRadius:6,
                        padding:6,
                      }}
                    >
                      <Image.Group size='tiny'>
                        {!googlePayLogos ? null :
                          googlePayLogos.map((logo) => { return (
                            <Image
                              key={Math.random()}
                              circular
                              centered
                              id='googleImageCard'
                              alt='Google Logo'
                              disabled={!logo.isActive}
                              style={{cursor: 'pointer'}}
                              src={this.apiUrl+logo.image}
                              className='iconImage imageShadow'
                              onClick={ () => this.setCardGoogleLogo(logo) } />
                          )})
                        }
                      </Image.Group>
                    </div>
                  </Transition>

                  {!this.state.googleCardColorsOpen ? null :
                    <Grid centered columns={2} stackable >
                      <Grid.Row centered columns={2}>
                        <Grid.Column textAlign='center' >
                          Label Color
                          <ChromePicker
                            width='175px'
                            disableAlpha={true}
                            color={this.state.googleLabelColor.hex}
                            onChange={this.changeGoogleLbColor} />
                        </Grid.Column>
                        <Grid.Column textAlign='center' >
                          Text Color
                          <ChromePicker
                            width='175px'
                            disableAlpha={true}
                            color={this.state.googleTextColor}
                            onChangeComplete={ this.changeGoogleTxColor }/>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Column textAlign='center' >
                        Background Color
                        <ChromePicker
                          width='175px'
                          disableAlpha={true}
                          color={this.state.googleBackgroundColor.hex}
                          onChange={this.changeGoogleBgColor} />
                      </Grid.Column>
                      <Grid.Column textAlign='center' verticalAlign='middle' >
                        <Button
                          size='small'
                          icon='check'
                          color={!this.isDisabled() ? 'teal' : 'grey'}
                          content={'Close'}
                          disabled={(!this.state.editCardName && !this.state.name)}
                          onClick={() => this.setState({ googleCardColorsOpen: !this.state.googleCardColorsOpen, })} />
                      </Grid.Column>

                    </Grid>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Divider />
        </Container>  */}

        <Container fluid >
          <Container style={{ /* padding: '10px 0px', */ }} >
            &nbsp;<Divider fitted />&nbsp;
          </Container>
        </Container>

        <Confirm
          open={this.state.confirmAlert}
          content='You have unsaved content. Are you sure you want to go Back?'
          onCancel={() => this.setState({ confirmAlert: false, })}
          onConfirm={() => this.props.history.push('/giftcard')} />

      </Container>
    )
  };
};

export default withRouter(DesignGiftCard);

/*

{/* GOOGLE PAY HERO IMAGE SEGMENT * /}
<Container>
  <Container style={{ padding: '10px 0px 40px 0px', }}>
    <Divider horizontal><Header>Google Pay Hero Image</Header></Divider>
    <Grid >
      <Grid.Row>
        <Grid.Column width='8' align='center' >
          {!this.state.googlePayHeroImage && !this.state.isEditingGooglePayHeroImage ?
            <Segment textAlign='center' loading={this.state.componentIsLoading} basic >
              <p style={{fontSize:'12px',}}>
                The "Google Pay Hero" image is the primary image displayed in Google Pay.
              </p>
              <Image
                className='imageShadow'
                rounded
                centered
                size='medium'
                src='/image-frame.png'
                style={{width:'344px',height:'115px',}} /><br />
              <Button
                color={!this.isDisabled() ? 'teal' : 'grey'}
                disabled={(!this.state.editCardName && !this.state.name)}
                onClick={ () => {this.setState({ isEditingGooglePayHeroImage: true })} }
              >
                <Icon name='upload' />
                {this.isDisabled() ? 'Upload' : 'Upload'}
              </Button>
            </Segment>
            :
            <Segment textAlign='center' loading={this.state.componentIsLoading} basic >
              <p style={{fontSize:'12px',}}>
                The "Google Pay Hero" image is the primary image displayed in Google Pay.
              </p>
              {this.state.isEditingGooglePayHeroImage ?
                this.isPreviewEmpty('googlePayHeroImagePreview')
                :
                <Image
                  className='imageShadow'
                  rounded
                  centered
                  size='medium'
                  alt="Google Hero Image"
                  style={{width:'344px',height:'115px',}}
                  src={'data:image;base64, '+this.state.googlePayHeroImage} />
              }<br />
              <Button
                color={this.state.isEditingGooglePayHeroImage ? 'grey' : 'teal'}
                disabled={this.state.isEditingGooglePayHeroImage}
                onClick={ () => {this.setState({ isEditingGooglePayHeroImage: true })} }
              >
                <Icon name='edit'/>
                Edit Google Image
              </Button><p />
            </Segment>
          }
        </Grid.Column>
        <Grid.Column width='8' >
          {!this.state.isEditingGooglePayHeroImage ? null :
            <div>
              <div style={{fontSize:'12px', textAlign:'left', paddingBottom:'6px', }}>
                Size: 1032 x 336 &#8226; Circle Crop &#8226; RGB &#8226; 72dpi<br />
              </div>

              <Base64Cropper
                aspectRatio={ 1032/336 }
                widths={ [1032] }
                onChange={ (googlePayHeroImagePreview) => {
                  this.setState({ googlePayHeroImagePreview })
                }}
                onSave={ (saveData) => { this.updateCardImages({ googlePayHeroImage: saveData.size1032 }); }}
                onCancel={ () => {
                  this.setState({
                    isEditingGooglePayHeroImage: false,
                    googlePayHeroImagePreview: undefined, })
                }}
              />
            </div>
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
  <Divider />
</Container>


*/
