import React, {Component} from "react";
import {withRouter} from "react-router";
import {Accordion, Button, Container, Divider, Dropdown, Header, Icon, Image, Input, Label, Message, Popup, Segment, Table} from "semantic-ui-react";
import moment from 'moment';
import 'moment-timezone';
import ReactJson from 'react-json-view';
import APIService from '../API.js';


class ViewBalanceInquiry extends Component {

  state = {
    inquiry: {},
    displayDetails: false,
    isFetchingInquiryDetails: false,

    activeIndex: null,
  }

  componentDidMount() {
    this.getInquiryDetails();
  };

  inquiryId = () => this.props.match.params.inquiryId;

  apiURI = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  getInquiryDetails = () => {
    this.setState({ isFetchingCardDetails: true, });
    fetch( APIService.serverUrl + "/gift-card/balance/" + this.inquiryId(), {
      credentials: 'include',
      method: "GET",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            inquiry: json,
            isFetchingInquiryDetails: false,
          });
        }
      })
  };

  displayEventType = (event) => {
    switch(event.eventType) {
      case "InitialBalanceInquiryRequestEvent":
        return "Initial Balance Inquiry Request"
        break
      case "CheckForGiftCardBlockEvent":
        return "Check For Gift Card Block"
        break
      case "SVSBalanceInquiryEvent":
        return "SVS Balance Inquiry"
        break
      case "EvaluateGiftCardBlockEvent":
        return "Evaluate Gift Card Block"
        break
      case "EmailEvent":
        return "Balance Inquiry Block Alert"
        break
      case "CapturedErrorEvent":
        return "Captured Error"
        break
      default:
        return event.eventType
    }
  }

  handleClick = (id) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === id ? -1 : id

    this.setState({ activeIndex: newIndex })
  }

  render = () => {

    let inquiry = this.state.inquiry;
    let events = inquiry.events;
    let initialRequest;
    if (events) {
      initialRequest = events.find(v => v.eventType === "InitialBalanceInquiryRequestEvent");
    }

    return (
      <Container>

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing >
                  <Button
                    icon='chevron left'
                    content='Back'
                    disabled={this.state.isFetchingInquiryDetails}
                    onClick={() => this.props.history.push('/giftcard/inquiries')} />
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>

        {/*VIEW & EDIT CONTENT*/}
        <div style={{marginTop: "15px", marginBottom: "20px"}}>
          <Divider horizontal>
            <Header>
              Balance Inquiry Details
            </Header>
          </Divider>

          {this.state.isFetchingInquiryDetails || !inquiry._id ?
            <Segment loading={this.state.isFetchingCardDetails} basic/>
            :
            <Segment id="purchasedDetails">

              <div className="inquiryViewHeader">

                <div style={{display: 'flex'}}>
                  <span style={{marginRight: '15px'}}>
                    <span style={{fontWeight: 'bold', marginRight: '3px'}}>Date:</span>
                    {new moment(inquiry.createdAt).format('MMM D, YYYY')}
                  </span>

                  <span style={{marginRight: '15px'}}>
                    <span style={{fontWeight: 'bold', marginRight: '3px'}}>Status:</span>
                    {inquiry.status}
                  </span>

                  <span style={{marginRight: '10px'}}>
                    <span style={{fontWeight: 'bold', marginRight: '3px'}}>eGift Card:</span>
                    {inquiry.cardNumber}
                  </span>
                </div>

                <div style={{marginTop: '5px'}}>
                  <span>
                    <span style={{fontWeight: 'bold', marginRight: '3px'}}>User Agent:</span>
                    {inquiry.userAgent}
                  </span>
                </div>
              </div>


              <div className="purchasedEventLog">
                <span style={{fontStyle: "italic", textDecoration: "underline"}}>Inquiry Event Log</span>
                <Table
                  basic="very"
                  compact='very'
                  size='small'
                  verticalAlign='middle'
                >
                  <Table.Body>
                    {events.map((event) => {
                      return (
                        <React.Fragment>
                          <Table.Row
                            className="eventLog"
                            active={this.state.activeIndex === event._id}
                            index={event._id}
                            onClick={() => this.handleClick(event._id)}
                            style={{padding: "0px"}}
                          >
                            <Table.Cell className="eventLogIcon">
                              <Icon
                                name="info circle"
                                color={event.eventType === "CapturedErrorEvent" ? "yellow" : "green"}
                              />
                            </Table.Cell>

                            <Table.Cell className="eventLogDate">
                              {new moment(event.createdAt).format("YYYY/MM/DD - h:mm:ss.SS A")}
                            </Table.Cell>

                            <Table.Cell
                              className={"eventLogStatus " +
                                (event.eventType === "CapturedErrorEvent" ? "errorSpacing" : null)
                              }
                            >
                              {event.eventType === "CapturedErrorEvent" ? "Issue" : "Success"}
                            </Table.Cell>

                            <Table.Cell className="eventLogType">
                              {this.displayEventType(event)}
                            </Table.Cell>
                          </Table.Row>

                          {this.state.activeIndex !== event._id ? null :
                            <Table.Row
                              style={{
                                paddingLeft: "20px",
                                maxHeight: "600px",
                                maxWidth: "100%",
                                overflow: 'scroll'
                              }}
                            >
                              <Table.Cell colspan="4">
                                <ReactJson src={event}/>
                              </Table.Cell>
                            </Table.Row>
                          }
                        </React.Fragment>
                      )
                    })}
                  </Table.Body>
                </Table>
              </div>
            </Segment>
          }

        </div>
      </Container>
    );
  };
};

export default withRouter(ViewBalanceInquiry);
