import React, {Component} from "react";
import {withRouter} from "react-router";
import {Accordion, Button, Container, Divider, Dropdown, Header, Icon, Image, Input, Label, Message, Popup, Segment, Table} from "semantic-ui-react";
import moment from 'moment';
import 'moment-timezone';
import ReactJson from 'react-json-view';
import APIService from '../API.js';


class ViewPurchasedCardDetails extends Component {

  state = {
    card: {},
    displayDetails: false,
    isFetchingCardDetails: false,

    activeIndex: null,

    email: "",

    areActionButtonsShown: true,
    retryAction: "",

    isPostingReIssue: false,
    isPostingSendCard: false,
    isPostingSendReciept: false,
    isPostingSendCoupon: false,
    isResolvingCard: false,
  }

  componentDidMount() {
    this.getCardDetails();
  };

  cardId = () => this.props.match.params.cardId;

  apiURI = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  getCardDetails = () => {
    this.setState({ isFetchingCardDetails: true, });
    fetch( APIService.serverUrl + "/gift-card/" + this.cardId(), {
      credentials: 'include',
      method: "GET",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            card: json,
            isFetchingCardDetails: false,
          });
        }
      })
  };

  reIssueCard = () => {
    this.setState({isPostingReIssue: true})
    fetch(APIService.serverUrl + "/gift-card/" + this.cardId() + "/retry-issue-virtual-card", {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({})
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            email: "",
            areActionButtonsShown: true,
            card: json,
            isPostingReIssue: false
          })
        }
      })
  }

  sendCard = () => {
    this.setState({isPostingSendCard: true})
    fetch(APIService.serverUrl + "/gift-card/" + this.cardId() + "/resend-recipient-email", {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        email: this.state.email,
      })
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            email: "",
            areActionButtonsShown: true,
            card: json,
            isPostingSendCard: false
          })
        }
      })
  }

  sendReceipt = () => {
    this.setState({isPostingSendReciept: true})
    fetch(APIService.serverUrl + "/gift-card/" + this.cardId() + "/resend-receipt-email", {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        email: this.state.email,
      })
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            email: "",
            areActionButtonsShown: true,
            card: json,
            isPostingSendReciept: false
          })
        }
      })
  }

  sendCoupon = () => {
    this.setState({isPostingSendCoupon: true})
    fetch(APIService.serverUrl + "/gift-card/" + this.cardId() + "/resend-promotion-email", {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        email: this.state.email,
      })
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            email: "",
            areActionButtonsShown: true,
            card: json,
            isPostingSendCoupon: false
          })
        }
      })
  }

  resolveCard = () => {
    this.setState({isResolvingCard: true})
    fetch(APIService.serverUrl + "/gift-card/" + this.cardId() + "/resolve", {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({})
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            card: json,
            isResolvingCard: false
          })
        }
      })
  }

  formatPhoneNumber = (str) => {
    //Check if the input is of correct length
    let match = str.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  };

  displayEventType = (event) => {
    switch(event.eventType) {
      case "don_initialRequest":
        return "Initial Request"
        break
      case "don_monetraAvsOnly":
        return "Monetra AVS Only"
        break
      case "don_monetraPreauth":
        return "Monetra Pre-Auth"
        break
      case "svs_issueVirtualCard":
        return "SVS Issue Virtual Card"
        break
      case "don_monetraPreauthComplete":
        return "Monetra Pre-Auth Complete"
        break
      case "don_emailReceipt":
        return "Send Receipt Email"
        break
      case "don_emailRecipient":
        return "Send Recipient Email"
        break
      case "don_emailPromotion":
        return "Send Promotion Email"
        break
      case "captured_error":
        return "Failed Event"
        break
      case "don_resolve":
        return "Resolved"
        break
      default:
        return event.eventType
    }
  }

  handleClick = (id) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === id ? -1 : id

    this.setState({ activeIndex: newIndex })
  }

  isRetrySvsDisabled = () => {
    let foundPreauth = false;
    let foundSvs = false;

    this.state.card.events.forEach(event => {
      if(event.eventType === "don_monetraPreauth") {
        foundPreauth = true;
      }
      if(event.eventType === "svs_issueVirtualCard") {
        foundSvs = true;
      }
    })
    return (!foundPreauth || foundSvs)
  }

  disableRecipientEmailSend = () => {
    let foundSvs = false;
    let foundPreauthComplete = false;
    let isBulkCard = this.state.card.bulkGiftCard;

    this.state.card.events.forEach(event => {
      if(event.eventType === "svs_issueVirtualCard") {
        foundSvs = true;
      }
      if(event.eventType === "don_monetraPreauthComplete") {
        foundPreauthComplete = true
      }
    })
    return !(foundSvs && (isBulkCard || foundPreauthComplete))
  }

  disableReceiptEmailSend = () => {
    let foundSvs = false;
    let foundPreauthComplete = false;

    this.state.card.events.forEach(event => {
      if(event.eventType === "svs_issueVirtualCard") {
        foundSvs = true;
      }
      if(event.eventType === "don_monetraPreauthComplete") {
        foundPreauthComplete = true
      }
    })
    return (!foundSvs || !foundPreauthComplete)
  }

  isSendCouponDisabled = () => {
    let foundPromo = !!this.state.card.promoPeriod;
    let foundSvs = false;
    let foundPreauthComplete = false;
    let foundBulk = !!this.state.card.bulkGiftCard;

    this.state.card.events.forEach(event => {
      if(event.eventType === "svs_issueVirtualCard") {
        foundSvs = true;
      }
      if(event.eventType === "don_monetraPreauthComplete") {
        foundPreauthComplete = true
      }
    })
    return !(foundSvs && foundPromo && (foundPreauthComplete || foundBulk))
  }

  renderRetryAction = () => {
    switch(this.state.retryAction) {
      case "reIssueCard":
        return (
          <div className="retryContainer">
            <span className="retryLabel">Re-Issue Card</span>
            <Button
              className="retryButton"
              content="Send"
              color="teal"
              disabled={this.state.isPostingReIssue}
              loading={this.state.isPostingReIssue}
              onClick={this.reIssueCard}
            />
          </div>
        )
        break
      case "sendCard":
        return (
          <div className="retryContainer">
            <span className="retryLabel">Send Card Email</span>
            <Input
              className="retryInput"
              value={this.state.email}
              onChange={(e) => {
                this.setState({email: e.target.value})
              }}
            />
            <Button
              className="retryButton"
              content="Send"
              color="teal"
              disabled={this.state.isPostingSendCard}
              loading={this.state.isPostingSendCard}
              onClick={this.sendCard}
            />
          </div>
        )
        break
      case "sendReceipt":
        return (
          <div className="retryContainer">
            <span className="retryLabel">Send Receipt Email</span>
            <Input
              className="retryInput"
              value={this.state.email}
              onChange={(e) => {
                this.setState({email: e.target.value})
              }}
            />
            <Button
              className="retryButton"
              content="Send"
              color="teal"
              disabled={this.state.isPostingSendReciept}
              loading={this.state.isPostingSendReciept}
              onClick={this.sendReceipt}
            />
          </div>
        )
        break
      case "sendCoupon":
        return (
          <div className="retryContainer">
            <span className="retryLabel">Send Coupon Email</span>
            <Input
              className="retryInput"
              value={this.state.email}
              onChange={(e) => {
                this.setState({email: e.target.value})
              }}
            />
            <Button
              className="retryButton"
              content="Send"
              color="teal"
              disabled={this.state.isPostingSendCoupon}
              loading={this.state.isPostingSendCoupon}
              onClick={this.sendCoupon}
            />
          </div>
        )
        break
      default:
        return <span>Something went wrong...</span>
    }
  }

  render = () => {

    let card = this.state.card;
    let events = card.events;
    let initialRequest;
    if (events) {
      initialRequest = events.find(v => v.eventType === "don_initialRequest");
    }

    return (
      <Container>

        {/*VIEW & EDIT CONTENT*/}
        <div style={{marginTop: "30px", marginBottom: "20px"}}>
          <Divider horizontal>
            <Header>
              Purchase Details
            </Header>
          </Divider>

          {this.state.isFetchingCardDetails || !card._id ?
            <Segment loading={this.state.isFetchingCardDetails} basic/>
            :
            <Segment id="purchasedDetails">

              <div className="purchasedDetailsHeader">
                <Image
                  id="detailCard"
                  rounded
                  centered
                  size='tiny'
                  alt="Card Image"
                  src={this.apiURI + "/gift-card-design/" + card.giftCardDesign + "/mainCardImage"}
                />

                <div className="detailColumn">
                  <span>
                    <span className="columnHeader">Date:</span>
                    {new moment(card.createdAt).format('MMM D, YYYY')}
                  </span>

                  <span>
                    <span className="columnHeader">Amount:</span>
                    ${card.initialBalance}
                  </span>
                </div>

                <div className="detailColumn">
                  {initialRequest &&
                    <span>
                      <span className="columnHeader">Purchaser:</span>
                      {initialRequest.event.sender.firstName + " " + initialRequest.event.sender.lastName}
                      {" (" + initialRequest.event.billingInfo.email + ")"}
                    </span>
                  }

                  <span>
                    <span className="columnHeader">Recipient:</span>
                    {card.recipientInfo.firstName + " " + card.recipientInfo.lastName}
                    {" (" + card.recipientInfo.email + ")"}
                  </span>
                </div>

                <div className="detailColumn">
                  <span>
                    <span className="columnHeader">Public Reference:</span>
                    {card.publicReference}
                  </span>
                </div>
              </div>

              <Divider className="headerDivider" horizontal hidden>
                <Icon
                  color="grey"
                  onClick={() => {
                    this.setState({displayDetails: !this.state.displayDetails})
                  }}
                  name={this.state.displayDetails ? "arrow circle up" : "arrow circle down"}
                />
              </Divider>

              {!this.state.displayDetails ? null :
                <div className="purchasedDetailsExpanded">
                  <div className="expandedDetailsColumn">
                    <div className="expandedDetailsSection">
                      <span className="expandedDetailsHeader">DATE</span>
                      <span>Purchased: {new moment(card.createdAt).format('MMM D, YYYY - h:mma')}</span>
                      <span>Delivered: {new moment(card.scheduledDelivery).format('MMM D, YYYY - h:mma')}</span>
                    </div>
                    {initialRequest &&
                      <div className="expandedDetailsSection">
                        <span className="expandedDetailsHeader">PURCHASER</span>
                        <span>Name: {initialRequest.event.sender.firstName + " " + initialRequest.event.sender.lastName}</span>
                        <span>Email: {initialRequest.event.billingInfo.email}</span>
                        <span>Card: XXXX-XXXX-XXXX-{initialRequest.event.payment.cardNumberLastFour}</span>
                        <span>Phone: {this.formatPhoneNumber(initialRequest.event.billingInfo.phone)}</span>
                        <span>Address: {initialRequest.event.billingInfo.address}</span>
                        {!initialRequest.event.billingInfo.address2 ? null :
                          <span>Address Line 2: {initialRequest.event.billingInfo.address2}</span>
                        }
                        <span>City: {initialRequest.event.billingInfo.city}</span>
                        <span>State: {initialRequest.event.billingInfo.state}</span>
                        <span>Zip: {initialRequest.event.billingInfo.zip}</span>
                      </div>
                    }
                  </div>

                  <div className="expandedDetailsColumn">
                    <div className="expandedDetailsSection">
                      <span className="expandedDetailsHeader">GIFT CARD</span>
                      <span>Amount: ${card.initialBalance}</span>
                      <span>Card #: {card.cardNumber}</span>
                      <span>PIN: {card.pinNumber}</span>
                      <span>Confirmation: {card.publicReference}</span>
                    </div>
                    <div className="expandedDetailsSection">
                      <span className="expandedDetailsHeader">RECIPIENT</span>
                      <span>Name: {card.recipientInfo.firstName + " " + card.recipientInfo.lastName}</span>
                      <span>Email: {card.recipientInfo.email}</span>
                    </div>
                  </div>

                  <div className="expandedDetailsColumn">
                    <div className="expandedDetailsSection">
                      <span className="expandedDetailsHeader">MESSAGE</span>
                      <span>{card.recipientInfo.message}</span>
                    </div>
                  </div>
                </div>
              }

              <div className="purchasedEventLog">
                <span style={{fontStyle: "italic", textDecoration: "underline"}}>Transaction Event Log</span>
                <Table
                  basic="very"
                  compact='very'
                  size='small'
                  verticalAlign='middle'
                >
                  <Table.Body>
                    {events.map((event) => {
                      return (
                        <React.Fragment>
                          <Table.Row
                            className="eventLog"
                            active={this.state.activeIndex === event._id}
                            index={event._id}
                            onClick={() => this.handleClick(event._id)}
                            style={{padding: "0px"}}
                          >
                            <Table.Cell className="eventLogIcon">
                              <Icon
                                name="info circle"
                                color={event.eventType === "captured_error" ? "yellow" : "green"}
                              />
                            </Table.Cell>

                            <Table.Cell className="eventLogDate">
                              {new moment(event.createdAt).format("YYYY/MM/DD - h:mm:ss.SS A")}
                            </Table.Cell>

                            <Table.Cell
                              className={"eventLogStatus " +
                                (event.eventType === "captured_error" ? "errorSpacing" : null)
                              }
                            >
                              {event.eventType === "captured_error" ? "Issue" : "Success"}
                            </Table.Cell>

                            <Table.Cell className="eventLogType">
                              {this.displayEventType(event)}
                            </Table.Cell>
                          </Table.Row>

                          {this.state.activeIndex !== event._id ? null :
                            <Table.Row
                              style={{
                                paddingLeft: "20px",
                                maxHeight: "600px",
                                maxWidth: "100%",
                                overflow: 'scroll'
                              }}
                            >
                              <Table.Cell colspan="4">
                                <ReactJson src={event}/>
                              </Table.Cell>
                            </Table.Row>
                          }
                        </React.Fragment>
                      )
                    })}
                  </Table.Body>
                </Table>
              </div>

              <div className="purchasedDetailsFooter">
                <h5 style={{margin: "0 15px 0 10px", textTransform: "uppercase"}}>Actions:</h5>

                {this.state.areActionButtonsShown ?
                  <div>
                    <Button
                      color="teal"
                      icon="credit card"
                      content="Retry SVS"
                      disabled={this.isRetrySvsDisabled()}
                      onClick={() => {
                        this.setState({
                          retryAction: "reIssueCard",
                          areActionButtonsShown: false,
                          email: "",
                        })
                      }}
                    />
                    <Button
                      color="teal"
                      icon="mail"
                      content="Send Card"
                      disabled={this.disableRecipientEmailSend()}
                      onClick={() => {
                        this.setState({
                          retryAction: "sendCard",
                          areActionButtonsShown: false,
                          email: "",
                        })
                      }}
                    />
                    <Button
                      color="teal"
                      icon="mail"
                      content="Send Receipt"
                      disabled={this.disableReceiptEmailSend()}
                      onClick={() => {
                        this.setState({
                          retryAction: "sendReceipt",
                          areActionButtonsShown: false,
                          email: "",
                        })
                      }}
                    />
                    <Button
                      color="teal"
                      icon="credit card"
                      content="Send Coupon"
                      disabled={this.isSendCouponDisabled()}
                      onClick={() => {
                        this.setState({
                          retryAction: "sendCoupon",
                          areActionButtonsShown: false,
                          email: "",
                        })
                      }}
                    />
                    <Button
                      color="green"
                      content="Resolve"
                      icon="check"
                      disabled={card.status !== "failure"}
                      onClick={this.resolveCard}
                    />
                  </div>
                  :
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    {this.renderRetryAction()}
                    <Button
                      content="Cancel"
                      color="red"
                      style={{height: "36px"}}
                      onClick={() => {
                        this.setState({
                          email: "",
                          retryAction: "",
                          areActionButtonsShown: true
                        })
                      }}
                    />
                  </div>
                }
              </div>


            </Segment>
          }

        </div>
      </Container>
    );
  };
};

export default withRouter(ViewPurchasedCardDetails);
