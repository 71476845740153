class Enum {
  static get options() { return []};

  static find(value) {
    return this.options.find(v => v === value);
  }

  static filter(array = []) {
    return array.filter(v => !!this.find(v));
  }
}

class Capability extends Enum {
  static ADMINISTRATOR = "administrator";
  static DESIGN_GIFT_CARDS = "design-gift-cards";
  static REVIEW_PURCHASES = "review-purchases";
  static PURCHASE_BULK_GIFT_CARDS = "purchase-bulk-gift-cards";
  static MANAGE_PROMOTIONS = "manage-promotions";
  static REVIEW_INQUIRIES = "review-inquiries";

  static get options() { return [
    this.ADMINISTRATOR,
    this.DESIGN_GIFT_CARDS,
    this.REVIEW_PURCHASES,
    this.PURCHASE_BULK_GIFT_CARDS,
    this.MANAGE_PROMOTIONS,
    this.REVIEW_INQUIRIES
  ]}
}

export default Capability;
