import React, {Component} from "react";
import {withRouter} from "react-router";
import {
  Segment, Table, Button, Icon, Message,
  Image, Container, Header, Divider, Pagination,
} from "semantic-ui-react";
import Moment from 'react-moment';
import APIService from '../API.js';


class ListGiftCards extends Component {

  state = {
    cards: [
      {
        _id: undefined,
        isActive: undefined,
        name: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        mainCardImage: undefined,
        googlePassImage: undefined,
        appleWalletStripImage: undefined,
        appleWalletLogoImage: undefined,
        __v: 0,
      },
    ],

    /* PAGINATION */
    page: 1,
    pageSize: 10,
    lastPage: 1,
    showFirstAndLastNav: false,

    isFetchingGiftCards: false,
  }

  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  componentDidMount() {
    this.getGiftCards();
  };

  getGiftCards = (options) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({ isFetchingGiftCards: true, });
    fetch( APIService.serverUrl + "/gift-card-design/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'pageSize': options.pageSize,
        'page': options.page,
      }),
    }).then(result => {
        return result.json();
    }).then(json => {
        this.setState({
          cards: json.results,
          isFetchingGiftCards: false,
          page: json.page ? json.page : 1,
          pageSize: json.pageSize ? json.pageSize: 10,
          lastPage: json.lastPage ? json.lastPage : 1,
        });
        console.log('****** CARDS: ',json);
    })
  }


  render = () => {

    let giftcards = this.state.cards;

    return (

      <Container fluid >

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row style={{ height:'46px', }}>
                <Table.Cell collapsing >
                  &nbsp;
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* ADD BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='bottom' >
                  <span style={{ fontSize: "14px", }}>Click a row to edit a card.</span>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    id='addButton'
                    color='teal'
                    icon='add'
                    onClick={ () => this.props.history.push('/giftcard/design') }
                    content='New Card' />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
        </Container>
        {/* GIFT CARD LIST */}
        <Container >
          <Segment basic loading={this.state.isFetchingGiftCards} >
            {!giftcards.length > 0 ?
              <Message size='massive' color='teal' compact >
                <Message.Content>
                  <Message.Header>
                    Click the "+ New Card" button to add Cards
                  </Message.Header>
                  <br />
                  <Button
                    id='addButton'
                    color='teal'
                    icon='add'
                    onClick={ () => this.props.history.push('/giftcard/design') }
                    content='New Card' />
                </Message.Content>
              </Message>
              :
              <Table verticalAlign='middle' basic='very' singleLine selectable >
                <Table.Body>
                  {giftcards.map((card) => {
                    return(
                      <Table.Row
                        key={Math.random()}
                        id={card._id}
                        style={{cursor: 'pointer'}}
                        onClick={ () => this.props.history.push('/giftcard/design/'+card._id) }
                      >
                        <Table.Cell textAlign="center" collapsing>
                          <Icon
                            id="isActive"
                            name='check circle'
                            size='large'
                            className={card.isActive ? 'activeItem': 'inActiveItem'} />
                        </Table.Cell>
                        {console.log(card.mainCardImage)}
                        <Table.Cell textAlign="center" collapsing>
                          {!card.mainCardImage ?
                            <Image rounded centered size='tiny' alt="Card Image"
                              src='/250px-Donatos_Pizza_logo.svg.png'
                            />
                            :
                            <Image rounded centered size='tiny' alt="Card Image"
                              style={{minHeight: '50.4px'}}
                              src={this.apiUrl + card.mainCardImage}
                            />
                          }
                        </Table.Cell>
                        <Table.Cell textAlign="left">
                          <Header>
                            <Header.Content>
                              {card.name}
                              <Header.Subheader>
                                <span style={{ 'virticalAlign':'middle', }}>
                                  Created: <Moment format='MMMM Do, YYYY' >{card.createdAt}</Moment>
                                  &nbsp;&#8226;
                                  Updated: <Moment format='MMMM Do, YYYY' >{card.updatedAt}</Moment>
                                </span>
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            }
          </Segment>
          <Divider />
            {this.state.lastPage < 2 ? null :
              <div style={{ textAlign:'center', }} >
                <Pagination
                  secondary
                  pointing
                  size='tiny'
                  color='teal'
                  defaultActivePage={this.state.page}
                  totalPages={this.state.lastPage}
                  boundaryRange={1}
                  siblingRange={1}
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={this.state.showFirstAndLastNav ?
                    { content: <Icon name='angle double left' />, icon: true } : null }
                  lastItem={this.state.showFirstAndLastNav ?
                    { content: <Icon name='angle double right' />, icon: true } : null }
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                  onPageChange={(e,{activePage}) => {
                    this.setState({ page: activePage });
                    this.getGiftCards({ page: activePage });
                  }}
                />
              </div>
            }
        </Container>

      </Container>

    )
  };
};

export default withRouter(ListGiftCards);

/*



*/
