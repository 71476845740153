import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import ListGiftCards from "../Views/ListGiftcards.js";
import DesignGiftCard from "../Editors/DesignGiftcard.js";
import ListPurchasedCards from "../Views/ListPurchasedCards.js";
import ListGiftCardStats from "../Views/ListGiftCardStats.js";
import ViewPurchasedCardDetails from "../Views/ViewPurchasedCardDetails";
import ListBulkPurchases from "../Views/ListBulkPurchases";
import ViewBulkPurchase from "../Views/ViewBulkPurchase";
import AddBulkPurchase from "../Editors/AddBulkPurchase";
import ListBalanceInquiries from "../Views/ListBalanceInquiries";
import ViewBalanceInquiry from "../Views/ViewBalanceInquiry";
import {SolsysAuthReactContext} from '@solsys/solsys-auth-react';
import Capability from "../enum/Capability";
import ViewRoutingError from "../Views/ViewRoutingError";


class GiftCardController extends Component {

  render = () => {

    let relativePath = this.props.match.path;

    const {DESIGN_GIFT_CARDS, REVIEW_PURCHASES, PURCHASE_BULK_GIFT_CARDS, REVIEW_INQUIRIES} = Capability;

    return (
      <SolsysAuthReactContext.Consumer>
        {(context) => {

          const {capabilities} = context.user;

          return(
            <Switch>

              <Route
                path={relativePath + '/design/:giftcardId?'}
                component={capabilities.find(v => v === DESIGN_GIFT_CARDS) ?
                  DesignGiftCard
                    :
                  ViewRoutingError
                }
              />

              <Route
                exact
                path={relativePath}
                component={capabilities.find(v => v === DESIGN_GIFT_CARDS) ?
                  ListGiftCards
                    :
                  ViewRoutingError
                }
              />

              <Route
                path={relativePath + '/purchases'}
                component={capabilities.find(v => v === REVIEW_PURCHASES) ?
                  ListPurchasedCards
                    :
                  ViewRoutingError
                }
              />

              <Route
                path={relativePath + '/view/:cardId'}
                component={capabilities.find(v => v === REVIEW_PURCHASES || v === PURCHASE_BULK_GIFT_CARDS) ?
                  ViewPurchasedCardDetails
                    :
                  ViewRoutingError
                }
              />

              <Route
                exact
                path={relativePath + '/bulk'}
                component={capabilities.find(v => v === PURCHASE_BULK_GIFT_CARDS) ?
                  ListBulkPurchases
                    :
                  ViewRoutingError
                }
              />

              <Route
                path={relativePath + '/bulk/view/:purchaseId'}
                component={capabilities.find(v => v === PURCHASE_BULK_GIFT_CARDS) ?
                  ViewBulkPurchase
                    :
                  ViewRoutingError
                }
              />

              <Route
                path={relativePath + '/bulk/add'}
                component={capabilities.find(v => v === PURCHASE_BULK_GIFT_CARDS) ?
                  AddBulkPurchase
                    :
                  ViewRoutingError
                }
              />

              <Route
                path={relativePath + '/stats'}
                component={capabilities.find(v => v === REVIEW_PURCHASES) ?
                  ListGiftCardStats
                    :
                  ViewRoutingError
                }
              />

              <Route
                exact
                path={relativePath + '/inquiries'}
                component={capabilities.find(v => v === REVIEW_INQUIRIES) ?
                  ListBalanceInquiries
                    :
                  ViewRoutingError
                }
              />

              <Route
                path={relativePath + '/inquiries/view/:inquiryId'}
                component={capabilities.find(v => v === REVIEW_INQUIRIES) ?
                  ViewBalanceInquiry
                    :
                  ViewRoutingError
                }
              />

            </Switch>
          )}}

      </SolsysAuthReactContext.Consumer>


    )
  };
};


export default withRouter(GiftCardController);
