import React, {Component} from "react";
import {withRouter} from "react-router";
import {
  Button, Container, Table, Icon, Pagination,
  Divider, Header, Segment, Message,
} from "semantic-ui-react";
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import APIService from '../API.js';


class ListPromotions extends Component {

  state = {
    promotions: [
      {
        _id: undefined,
        isActive: true,
        name: '',
        startDate: undefined,
        endDate: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        couponCodeTotalCount: 0, //available
        couponCodeUsedCount: 0, //issued
        __v: 0,
      },
    ],

    /* PAGINATION */
    page: 1,
    pageSize: 10,
    lastPage: 1,
    showFirstAndLastNav: false,

    isFetchingPromotions: false,

  };

  componentDidMount() {
    this.getPromotionList();
  };

  getPromotionList = (options) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({isFetchingPromotions: true})
    fetch(APIService.serverUrl + "/promo-period/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'pageSize': options.pageSize,
        'page': options.page,
      }),
    }).then(result => {
      if(result.ok) {
        return result.json()
      } else {
        console.log("*** getPromotionList POST failed: ", result.status, result.statusText)
        // return result;
      }
    }).then(json => {
        this.setState({
          promotions: json.results,
          isFetchingPromotions: false,
          page: json.page ? json.page : 1,
          pageSize: json.pageSize ? json.pageSize: 10,
          lastPage: json.lastPage ? json.lastPage : 1,
        })
        console.log('*** List Promotions json: ',json)
    })
  };


  render = () => {

    let promotions = this.state.promotions;

    return (

      <Container fluid >

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row style={{ height:'46px', }}>
                <Table.Cell collapsing >
                  &nbsp;
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* ADD BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='bottom' >
                  <span style={{ fontSize: "14px", }}>
                    Click a row to edit a promotion. Click the button to add a new promotion.
                  </span>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    id='addButton'
                    color='teal'
                    icon='add'
                    onClick={ () => this.props.history.push('/promotion/design')}
                    content='New Promotion' />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
        </Container>
        {/* PROMOTION LIST */}
        <Container>
          <Segment basic loading={this.state.isFetchingPromotions} >
            {promotions.length < 1 ?
              <Message color='teal'  style={{textAlign:'center'}}>
                <Message.Content>
                  <Message.Header>
                    Click the "<Icon name='plus' />New Promotion" button to add Promotions
                  </Message.Header>
                  <br />
                  <Button
                    id='addButton'
                    color='teal'
                    icon='add'
                    onClick={ () => this.props.history.push('/promotion/design') }
                    content='New Promotion' />
                </Message.Content>
              </Message>
              :
              <div>
                <Table verticalAlign='middle' basic='very' singleLine selectable >
                  <Table.Body>
                    {!promotions.length > 0 ? null : promotions.map((promotion) => { return(
                      <Table.Row
                        key={Math.random()}
                        id={promotion._id}
                        style={{cursor: 'pointer'}}
                        onClick={ () => this.props.history.push({
                          pathname: '/promotion/design/'+promotion._id,
                          state: { promotion: promotion, }
                        })}
                      >
                        <Table.Cell textAlign="center" collapsing>
                          <Icon
                            id='isActive'
                            size='large'
                            name='check circle'
                            className={promotion.isActive ? 'activeItem': 'inActiveItem'} />
                        </Table.Cell>
                        <Table.Cell textAlign="left">
                          <Header>
                            <Header.Content>{promotion.name}</Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign="center" collapsing>
                          <Icon
                            name='calendar alternate'
                            size='big'
                            alt="User Image"
                            className={promotion.isActive ? 'activeItem': 'inActiveItem'} />
                        </Table.Cell>
                        <Table.Cell textAlign="left" >
                          Start: <Moment format='MMM D, YYYY - hh:mm a' >{promotion.startDate}</Moment> {moment.tz.zone('America/Detroit').abbr(promotion.startDate)}
                          <br />
                          End: <Moment format='MMM D, YYYY - hh:mm a' >{promotion.endDate}</Moment> {moment.tz.zone('America/Detroit').abbr(promotion.endDate)}
                        </Table.Cell>
                        <Table.Cell textAlign="center" collapsing>
                          <Icon
                            name='tag'
                            size='big'
                            alt="User Image"
                            className={promotion.isActive ? 'activeItem': 'inActiveItem'} />
                        </Table.Cell>
                        <Table.Cell textAlign="left">
                          Coupons: <span style={{color:'teal',}}>{promotion.couponCodeUsedCount} (issued)</span><br />
                          Coupons: {promotion.couponCodeTotalCount - promotion.couponCodeUsedCount} (available)<br />
                        </Table.Cell>
                      </Table.Row>
                    )})}
                  </Table.Body>
                </Table>
              </div>
            }
          </Segment>
          <Divider />
          {this.state.lastPage < 2 ? null :
            <div style={{ textAlign:'center', }} >
              <Pagination
                secondary
                pointing
                size='tiny'
                color='teal'
                defaultActivePage={this.state.page}
                totalPages={this.state.lastPage}
                boundaryRange={1}
                siblingRange={1}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={this.state.showFirstAndLastNav ?
                  { content: <Icon name='angle double left' />, icon: true } : null }
                lastItem={this.state.showFirstAndLastNav ?
                  { content: <Icon name='angle double right' />, icon: true } : null }
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                onPageChange={(e,{activePage}) => {
                  this.setState({ page: activePage });
                  this.getPromotionList({ page: activePage });
                }}
              />
            </div>
          }
        </Container>

      </Container>

    )
  };
};

export default withRouter(ListPromotions);

/*



*/
