import React, {Component} from "react";
import {withRouter} from 'react-router';
import {
  Button, Container, Table, Divider, Label, Dimmer, Pagination,
  Input, Segment, Checkbox, Grid, Confirm, Loader,
  Header, Icon, Image, Transition, Popup, Message, Modal, TextArea,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import readXlsxFile from 'read-excel-file';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import { ChromePicker } from 'react-color';
import Base64Cropper from './Base64Cropper.js';
import APIService from "../API";
import UploadFile from './UploadSpreadsheetSteps/UploadFileStep';
import MatchSave from './UploadSpreadsheetSteps/MatchSaveStep';
import Summary from './UploadSpreadsheetSteps/SummaryStep';


let activeStepOptions = {
  upload:"upload",
  match:"match",
  summary:"summary"
};

let defaultStartDate = new moment().startOf('day');
let defaultEndDate = new moment().endOf('day');

class DesignPromotion extends Component {

  isAddEdit = this.props.match.params.promotionId === undefined ? 'Add' : 'Edit';
  patchId = () => this.props.match.params.promotionId;
  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  defaultState = {
    unmodifiedPromotion: {
      id: '',
      _id: '',
      name: '',
      description: '',
      disclaimer: '',
      isActive: false,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      couponCodeExpiration: defaultEndDate,
      lowCouponCount: '',
      lowCouponEmailTo: '',
      couponCodeUploads: [],
      createdAt: undefined,
      updatedAt: undefined,

      appleWalletStripImage: '',
      appleWalletLogoImage: '',
      appleWalletIcon: '',
      cardTextColor: { hex: '#000000', rgb: 'rgb(0,0,0)', },
      cardLabelColor: { hex: '#FFFFFF', rgb: 'rgb(255,255,255)', },
      cardBackgroundColor: { hex: '#AC1925', rgb: 'rgb(172,25,37)', },
      stats: undefined,
    },

    id: '',
    _id: '',
    name: '',
    description: '',
    disclaimer: '',
    isActive: false,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    couponCodeExpiration: defaultEndDate,
    lowCouponCount: '',
    lowCouponEmailTo: '',
    couponCodeUploads: [],
    createdAt: undefined,
    updatedAt: undefined,

    appleWalletStripImage: '',
    appleWalletLogoImage: '',
    appleWalletIcon: '',
    cardTextColor: { hex: '#000000', rgb: 'rgb(0,0,0)', },
    cardLabelColor: { hex: '#FFFFFF', rgb: 'rgb(255,255,255)', },
    cardBackgroundColor: { hex: '#AC1925', rgb: 'rgb(172,25,37)', },
    stats: undefined,

    appleWalletStripImagePreview: undefined,
    appleWalletLogoImagePreview: undefined,

    isEditingAppleWalletStripImage: false,
    isEditingAppleWalletLogoImage: false,

    couponCodeExpirationActive: false,
    couponCodeExpirationSaved: false,

    appleIcons: [],

    /* ICON PAGINATION */
    page: 1,
    pageSize: 12,
    lastPage: 1,
    showFirstAndLastNav: false,

    addCouponCodes: false,
    activeStep: '',
    userSelectedFile: "",
    userSelectedFileJson: [],
    userProvidedHeader: "",

    transitionVisable: false,
    previewOpen: false,
    editCardName: false,
    componentIsLoading: false,
    confirmAlert: false,
    errorModalActive: false,
  }

  state = this.defaultState;

  componentDidMount() {
    this.setState({ componentIsLoading: true, });
    this.getAppleIcons();
  };

  getAppleIcons = (options) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({ isFetchingIcons: true, });
    fetch( APIService.serverUrl + "/apple-wallet-icon/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'isActive': true,
        'pageSize': options.pageSize,
        'page': options.page,
      }),
    }).then(result => {
        return result.json();
    }).then(json => {
        this.setState({
          appleIcons: json.results,
          isFetchingIcons: false,
          page: json.page ? json.page : 1,
          pageSize: json.pageSize ? json.pageSize: 12,
          lastPage: json.lastPage ? json.lastPage : 1,
        });
        console.log("**** getAppleIcons() json.results: ", json.results);
        this.readPromotion();
    })
  };

  readPromotion = () => {
    if(this.isAddEdit === 'Edit') {
      fetch( APIService.serverUrl + "/promo-period/" + this.patchId(), {
        method: "GET",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          clientId: APIService.clientId
        },
      }).then(result => {
          return result.json();
      }).then(json => {
        this.setPromotionStateWithJSON(json);
        console.log('**** readPromotion json: ',json);
      })
    } else {
      this.setState({
        ...this.defaultState,
      });
      console.log('**** Setting Default State ****');
    }
  };

  savePromotion = () => {
    this.setState({componentIsLoading: true});
    let fetchPath = (this.isAddEdit === 'Add') ?
      APIService.serverUrl + "/promo-period" :
      APIService.serverUrl + "/promo-period/" + this.patchId();
    fetch( fetchPath, {
      method: this.isAddEdit === 'Add' ? 'POST' : 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'name': this.state.name,
        'description': this.state.description,
        'disclaimer': this.state.disclaimer,
        'isActive': this.state.isActive,
        'startDate': this.state.startDate ? this.state.startDate : new moment().startOf('day'),
        'endDate': this.state.endDate ? this.state.endDate : new moment().endOf('day'),
        'couponCodeExpiration': this.state.couponCodeExpirationActive ? this.state.couponCodeExpiration : undefined,
        'lowCouponCount': this.state.lowCouponCount ? this.state.lowCouponCount : '',
        'lowCouponEmailTo': this.state.lowCouponEmailTo ? this.state.lowCouponEmailTo : '',
        'appleWalletStripImage': this.state.appleWalletStripImage ? this.state.appleWalletStripImage : undefined,
        'appleWalletLogoImage': this.state.appleWalletLogoImage ? this.state.appleWalletLogoImage : undefined,
        'appleWalletIcon': this.state.appleWalletIcon ? this.state.appleWalletIcon._id : undefined,
        'labelColor': this.state.cardLabelColor.rgb,
        'foregroundColor': this.state.cardTextColor.rgb,
        'backgroundColor': this.state.cardBackgroundColor.rgb,
      }),
    }).then(result => {
      if (result.ok) {
        return result.json();
      } else {
        console.log("**** savePromotion Failed ****",result);
        return null
      }
    }).then((json) => {
      if (json) {
        this.setState({
          editCardName: false,
          isEditingAppleWalletStripImage: false,
          isEditingAppleWalletLogoImage: false,
        });
        this.isAddEdit = 'Edit';
        this.props.history.replace('/promotion/design/'+json._id);
        console.log('***** json after savePromotion(): ',json);
        this.readPromotion();
      } else {
        this.setState({
          errorModalActive: true,
          editCardName: false,
          isEditingAppleWalletStripImage: false,
          isEditingAppleWalletLogoImage: false,
          componentIsLoading: false,
        });
      }
    })
  };

  setPromotionStateWithJSON = (json) => {
    let tempCard = {
      id: json._id,
      _id: json._id,
      name: json.name,
      description: json.description,
      disclaimer: json.disclaimer,
      isActive: json.isActive,
      startDate: moment(json.startDate),
      endDate: moment(json.endDate),
      couponCodeExpiration: json.couponCodeExpiration ? moment(json.couponCodeExpiration) : undefined,
      createdAt: moment(json.createdAt),
      updatedAt: moment(json.updatedAt),
      lowCouponCount: json.lowCouponCount ? json.lowCouponCount : '',
      lowCouponEmailTo: json.lowCouponEmailTo ? json.lowCouponEmailTo : '',
      couponCodeUploads: json.couponCodeUploads,
      appleWalletStripImage: json.appleWalletStripImage ? json.appleWalletStripImage : '',
      appleWalletLogoImage: json.appleWalletLogoImage ? json.appleWalletLogoImage : '',
      appleWalletIcon: this.setAppleWalletIcon(json.appleWalletIcon),
      cardTextColor: {
        hex: this.convertRGBToHEX(json.foregroundColor ? json.foregroundColor : 'rgb(0,0,0)'),
        rgb: json.foregroundColor ? json.foregroundColor : 'rgb(0,0,0)',
      },
      cardLabelColor: {
        hex: this.convertRGBToHEX(json.labelColor ? json.labelColor : 'rgb(255,255,255)'),
        rgb: json.labelColor ? json.labelColor : 'rgb(255,255,255)',
      },
      cardBackgroundColor: {
        hex: this.convertRGBToHEX(json.backgroundColor ? json.backgroundColor : 'rgb(172,25,37)'),
        rgb: json.backgroundColor ? json.backgroundColor : 'rgb(172,25,37)',
      },
      stats: json.stats,
    }
    this.setState({
      unmodifiedPromotion: {...tempCard},
      ...tempCard,
      couponCodeExpirationSaved: json.couponCodeExpiration ? true : false,
      componentIsLoading: false,
      confirmAlert: false,
    });
  };

  addArrayOfCodes = () => {
    // this.setState({ componentIsLoading: true, });
    fetch( APIService.serverUrl + "/coupon-code/collection", {
      method: "POST",
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify( {
        'fileName': this.state.userSelectedFile.name,
        "promoPeriod": this.props.match.params.promotionId,
        "codes":  this.state.userSelectedFileJson,
      }),
    }).then( (response) => {
      if(response.ok) {
        return response.json()
      } else {
        console.log("POST failed ", response.status, response.statusText)
        return null
      }
    }).then( (json) => {
      if (json) {
        this.summaryStep();
      } else {
        console.log("*** addArrayOfCodes POST failed ***")
      }
    })
  }

  onToggleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  };

  onStartDateChange = (date) => {
    this.setState({ startDate: moment(date).startOf('day') })
  };

  onEndDateChange = (date) => {
    this.setState({ endDate: moment(date).endOf('day') })
  };

  onCouponCodeExpirationChange = (date) => {
    this.setState({
      couponCodeExpiration: moment(date).endOf('day'),
      couponCodeExpirationActive: true,
    });
    // console.log('onCouponCodeExpirationChange - date: ',date);
  };

  updateCardImages = (image) => {
    this.setState({
      ...image,
      isEditingAppleWalletStripImage: false,
      isEditingAppleWalletLogoImage: false,
    });
  };

  changeCardLbColor = (color) => {
    this.setState({
      cardLabelColor: {
        rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
        hex: color.hex,
      },
    });
  };

  changeCardTxColor = (color) => {
    this.setState({
      cardTextColor: {
        rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
        hex: color.hex,
      }
    });
  };

  changeCardBgColor = (color) => {
    this.setState({
      cardBackgroundColor: {
        rgb: 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')',
        hex: color.hex,
      }
    });
  };

  setCouponIcon = (icon) => {
    this.setState({
      appleWalletIcon: icon,
      transitionVisable: false,
    });
  };

  setAppleWalletIcon = (iconId) => {
    if (iconId === undefined) {
      return '';
    } else {
      for (var key in this.state.appleIcons) {
        if (this.state.appleIcons[key]._id === iconId) {
          return this.state.appleIcons[key];
        }
      }
    }
  };

  convertRGBToHEX = (rgb) => {
    let sep = rgb.indexOf(",") > -1 ? "," : " ";
    rgb = rgb.substr(4).split(")")[0].split(sep);
    let r = (+rgb[0]).toString(16),
        g = (+rgb[1]).toString(16),
        b = (+rgb[2]).toString(16);
    if (r.length === 1)
      r = "0" + r;
    if (g.length === 1)
      g = "0" + g;
    if (b.length === 1)
      b = "0" + b;
    return "#" + r + g + b;
  };

  isPreviewEmpty = (dataRef) => {
    switch (dataRef) {
      case 'appleWalletLogoImagePreview':
        if (this.state.appleWalletLogoImagePreview !== undefined) {
          return(
            <Image
              alt="Coupon Logo Image"
              id='previewLogoImage'
              className='previewLogoImage'
              src={this.state.appleWalletLogoImagePreview} />);
        } else {
          return <Image alt="Preview Image" id='logoCardImageFrame' className='logoCardImageFrame' src={'/image-frame.png'} />;
        };
      case 'appleWalletStripImagePreview':
        if (this.state.appleWalletStripImagePreview !== undefined) {
          return (
            <Image
              centered
              size='large'
              alt="Coupon Strip Image"
              className='stripCardImageFrame'
              src={this.state.appleWalletStripImagePreview} />);
        } else {
          return <Image alt="Preview Image" className='stripCardImageFrame' src={'/image-frame.png'} />;
        };
      // case 'googlePayHeroImagePreview':
      //   if (this.state.googlePayHeroImagePreview !== undefined) {
      //     return (
      //       <Image
      //         centered
      //         size='medium'
      //         className={'googlePayHeroImage'}
      //         id={'googlePayHeroImage'}
      //         alt="Google Pay Hero Image"
      //         style={{width:'344px',height:'115px',}}
      //         src={this.state.googlePayHeroImagePreview} /> );
      //   } else {
      //     return (
      //       <Image
      //         centered
      //         size='medium'
      //         className={'googlePayHeroImage'}
      //         id={'googlePayHeroImage'}
      //         alt="Preview Image"
      //         style={{width:'344px',height:'115px',}}
      //         src={'/image-frame.png'} /> );
      //   };
      default:
        return <Icon name='image outline' size='massive' />;
    };
  };

  unsavedAlert = () => {
    if (this.isFormModified()) {
      this.setState({ confirmAlert: true, });
    } else {
      this.props.history.push('/promotion')
    }
  };

  isFormModified = () => {
    let isValueModified = false;
    for (var key in this.state.unmodifiedPromotion) {

      let value = this.state.unmodifiedPromotion[key];
      let stateValue = this.state[key];

      if (typeof value !== typeof stateValue) {
        isValueModified = true;
        // console.log('**** Modified Key: ',key);
      } else {
        switch (typeof value) {
          case 'object':
            Object.keys(value).forEach((nestedKey, i) => {
              if (value[nestedKey] !== stateValue[nestedKey]) {
                isValueModified = true;
                // console.log('**** Modified nestedKey: ',nestedKey);
              };
            });
            break;
          case 'array': // POSSIBLE ARRAY OF OBJECT ISSUE
            if (value.length !== stateValue.length) {
              isValueModified = true;
              // console.log('**** Modified Array length: ');
            } else {
              value.forEach((arrayItem, index) => {
                if (arrayItem !== stateValue[index]) {
                  isValueModified = true;
                  // console.log('**** Modified arrayItem: ',arrayItem);
                }
              })
            }
            break;
          default:
            if (this.state.unmodifiedPromotion[key] !== this.state[key]) {
              isValueModified = true;
              // console.log('**** Modified this.state[key]: ',this.state[key]);
            }
            break;
        }
      }
    }
    return isValueModified;
  };

  isDisabled = () => {
    if (this.state.name === '' || this.state.name === undefined) { return true; } else { return false; }
  };

  renderSpreadsheetStep = () => {
    switch (this.state.activeStep) {

      case activeStepOptions.upload:
        return (
            <UploadFile
              onNext={this.matchStep}
              onCancel={this.closeSpreadsheet}
              changeUserProvidedFile={this.changeUserProvidedFile}
              userSelectedFile={this.state.userSelectedFile}
            />
        )
      case activeStepOptions.match:
        return (
            <MatchSave
              onNext={this.addArrayOfCodes}
              onBack={this.uploadStep}
              userProvidedHeader={this.state.userProvidedHeader}
              changeUserProvidedHeader={this.changeUserProvidedHeader}
              userSelectedFile={this.state.userSelectedFile}
              userSelectedFileJson={this.state.userSelectedFileJson}
            />
        )
      case activeStepOptions.summary:
        return (
            <Summary
              onBack={this.matchStep}
              onFinish={this.closeSpreadsheet}
              numberOfCodes={this.state.userSelectedFileJson}
            />
        )
      default:
        return (
          <Dimmer.Dimmable as={Container} dimmed={this.state.componentIsLoading} >
            <Dimmer active={this.state.componentIsLoading} inverted >
             <Loader>Loading</Loader>
            </Dimmer>
            {(this.state.couponCodeExpiration && this.state.couponCodeExpirationSaved) ?
              <div className="uploadSegmentButton" onClick={() => { this.uploadStep() }} >
                <div className='uploadSegmentRowDiv uploadSegmentRowButton' >
                  <div className='uploadSegmentColumnDiv' style={{textAlign:'right',width:'10%',}} >
                    <Icon name='upload' size='big' />
                  </div>
                  <div className='uploadSegmentColumnDiv' style={{textAlign:'center',width:'90%',}} >
                    Choose a file to upload <br /> XLSX is supported.<p />
                  </div>
                </div>
              </div>
              :
              <div className="uploadSegmentExpireDate" style={{textAlign:'center',width:'100%',}} >
                <div style={{marginBottom:'8px',}}>
                  The code expiration date needs to be set before you can upload codes.
                </div>
                {/* PROMOTION CODE EXPIRATION DATE */}
                <div>
                  <Label id='endDateLabelClass' size='big' >Expiration Date</Label>
                  <DatePicker
                    id='endDatePickerClass'
                    name='couponCodeExpiration'
                    dateFormat='MM/dd/yyyy'
                    minDate={new Date()}
                    selected={
                      this.state.couponCodeExpiration ?
                      this.state.couponCodeExpiration.toDate() :
                      defaultEndDate.toDate()
                    }
                    onChange={this.onCouponCodeExpirationChange} />
                </div>
              </div>
            }
          </Dimmer.Dimmable>
        )
    }
  };

  renderFullPromotion = () => {
    if(this.state.stats === undefined) {
      return (
        <Container>{/* NO PROMOTIONS MESSAGE */}
          <Segment basic loading={this.state.componentIsLoading} >
            <Container fluid >
              <Container style={{ /* padding: '10px 0px', */ }} >
                &nbsp;<Divider fitted />&nbsp;
              </Container>
            </Container>
            <Message color='teal' style={{textAlign:'center'}} hidden={this.state.componentIsLoading}>
              <Message.Content>
                <Message.Header>
                  Enter the Promotion Title, select the Start and End Dates, then click the "<Icon name='save' />Add" button.
                </Message.Header>
                <br />
                <Button
                  icon='save'
                  color={this.isFormModified() ? 'teal' : 'grey'}
                  content={(this.isAddEdit === 'Add') ? this.isAddEdit : 'Save'}
                  disabled={!this.isFormModified() || this.isDisabled()}
                  onClick={ () => this.savePromotion() } />
              </Message.Content>
            </Message>
          </Segment>
        </Container>
      )
    } else {
      return (
        <div>
          {/* COUPON CODES */}
          <Container fluid >
            <Container style={{padding: '10px 0px'}}>
              <Divider horizontal ><Header>Coupon Codes</Header></Divider>

              {this.state.couponCodeUploads.length === 0 || this.state.addCouponCodes ?
                this.renderSpreadsheetStep()
                :
                <Segment secondary size='mini' loading={this.state.componentIsLoading} >
                  <div className='promotionStatsContainerDiv'>
                    <div className='promotionStatsRowDiv' >
                      <div className='promotionResultsHeaderClass' >
                        Active Coupon Codes - Expire on {moment(this.state.couponCodeExpiration).format('dddd, MMMM D, YYYY')}
                        <Button
                          compact
                          icon='plus'
                          size='mini'
                          color='teal'
                          floated='right'
                          content='Add Coupon Codes'
                          onClick={() => { this.setState({ addCouponCodes:true }) }} />
                      </div>
                    </div>
                    <div className='promotionStatsRowDiv' >
                      <div className='promotionActiveCodeColumn1Div' >
                        <span style={{color:'teal',fontWeight:'800',}}>
                          {this.state.stats.couponCodeIssuedCount}
                        </span>&nbsp;(issued)
                      </div>
                      <div className='promotionActiveCodeColumn2Div' >
                        <span style={{color:'teal',fontWeight:'800',}}>
                          {this.state.stats.couponCodeTotalCount - this.state.stats.couponCodeIssuedCount}
                        </span> (available)
                      </div>
                      <div className='promotionActiveCodeColumn3Div' >
                        Low Warning: &nbsp;
                        <Input
                          size='mini'
                          className='promotionActiveCodeLowInput'
                          name='lowCouponCount'
                          placeholder='300'
                          value={this.state.lowCouponCount}
                          onChange={this.onInputChange} /> &nbsp;
                        Alert email: &nbsp;
                        <Input
                          size='mini'
                          className='promotionActiveCodeEmailInput'
                          name='lowCouponEmailTo'
                          placeholder='user@domain.com'
                          value={this.state.lowCouponEmailTo}
                          onChange={this.onInputChange} />
                      </div>
                    </div>

                  </div>
                </Segment>
              }

              {this.state.couponCodeUploads.length === 0 ? null :
                <Segment basic loading={this.state.componentIsLoading} >
                  <div className='promotionFileListClassDiv'>

                      <div className='promotionResultsHeaderClass' >
                        File Upload History
                      </div>

                    {this.state.couponCodeUploads.map( (upload, i) => { return(
                      <div className='promotionFileListClassRow' key={i}>
                        <div className='promotionFileListClassColumn promotionFileListFile' >
                          File: {'"'+upload.fileName+'"'}
                        </div>
                        <div className='promotionFileListClassColumn promotionFileListDate' >
                          Date: <Moment format='MMM Do, YYYY - hh:mm a' >{upload.uploadedAt}</Moment>
                        </div>
                        <div className='promotionFileListClassColumn promotionFileListUser' >
                          User: {upload.user.firstName+' '+upload.user.lastName}
                        </div>
                        <div className='promotionFileListClassColumn promotionFileListRecords' >
                          Records: {upload.count}
                        </div>
                      </div>
                    )})}
                  </div>
                </Segment>
              }

            </Container>
          </Container>
          {/* PROMOTION RESULTS */}
          <Container >
            <Container style={{padding: '10px 0px'}}>
              <Divider horizontal ><Header>Promotion Results</Header></Divider>
              <Segment secondary loading={this.state.componentIsLoading}>
                <div className='promotionStatsContainerDiv'>
                  <div className='promotionStatsRowDiv' >
                    <div className='promotionStatsColumnDiv' >
                      Promotions Issued:&nbsp;
                      <span style={{color:'teal',fontWeight:'800',}}>
                        {this.state.stats.couponCodeIssuedCount !== 0 ? this.state.stats.couponCodeIssuedCount : '0'}
                      </span>
                    </div>
                    <div className='promotionStatsColumnDiv' >
                      Value:&nbsp;$&nbsp;
                      <span style={{color:'teal',fontWeight:'800',}}>
                        {this.renderMoney(this.state.stats.couponCodeIssuedCount * 10)}
                      </span>
                    </div>
                    <div className='promotionStatsColumnDiv' >&nbsp;</div>
                    <div className='promotionStatsColumnDiv' >&nbsp;</div>
                  </div>
                  <div className='promotionStatsRowDiv' >
                    <div className='promotionStatsColumnDiv' >
                      Gift Cards (total):&nbsp;
                      <span style={{fontWeight:'800',}}>
                        {this.state.stats.eGiftCardTotalCount !== 0 ? this.state.stats.eGiftCardTotalCount : '0'}
                      </span>
                    </div>
                    <div className='promotionStatsColumnDiv' >
                      Sales: $&nbsp;
                      <span style={{fontWeight:'800',}}>
                        {this.renderMoney(this.state.stats.eGiftCardTotalSales)}
                      </span>
                    </div>
                    <div className='promotionStatsColumnDiv' >&nbsp;</div>
                    <div className='promotionStatsColumnDiv' >&nbsp;</div>
                  </div>
                  <div className='promotionStatsRowDiv' >
                    <div className='promotionStatsColumnDiv' >
                      Gift Cards ($50+):&nbsp;
                      <span style={{fontWeight:'800',}}>
                        {this.state.stats.eGiftCardPromoCount !== 0 ? this.state.stats.eGiftCardPromoCount : '0'}
                      </span>
                    </div>
                    <div className='promotionStatsColumnDiv' >
                      Sales: $&nbsp;
                      <span style={{fontWeight:'800',}}>
                        {this.renderMoney(this.state.stats.eGiftCardPromoSales)}
                      </span>
                    </div>
                    <div className='promotionStatsColumnDiv' >
                      <span style={{color:'teal',fontWeight:'800',}}>
                        {this.renderPercentages(this.state.stats.eGiftCardPromoCount,this.state.stats.eGiftCardTotalCount)}
                      </span>% of Cards
                    </div>
                    <div className='promotionStatsColumnDiv' >
                      <span style={{color:'teal',fontWeight:'800',}}>
                        {this.renderPercentages(this.state.stats.eGiftCardPromoSales,this.state.stats.eGiftCardTotalSales)}
                      </span>% of Sales
                    </div>
                  </div>
                </div>
              </Segment>
              <div style={{ height:'10px', }} ></div>
            </Container>
          </Container>
          {/* DIGITAL PASS IMAGE */}
          <Container fluid >
            <Container style={{padding: '10px 0px'}}>
              <Divider horizontal ><Header>Digital Pass Image</Header></Divider>
                <Grid stackable >
                  <Grid.Row>
                    <Grid.Column width='8' align='center' >
                      {/* APPLE WALLET CARD PREVIEW */}
                      <Segment basic loading={this.state.componentIsLoading} >
                        <p style={{fontSize:'12px', marginTop: '10px'}}>
                          The Apple Wallet will be seen by Apple recipients who opt to "Add to Apple Wallet" from their email. It consists of a logo image, strip image, and a few color options.
                        </p>
                        {/* CARD PREVIEW DIV */}
                        <div
                          className='previewCardDiv'
                          style={{
                            backgroundColor:this.state.cardBackgroundColor.hex,
                          }} >
                          <div style={{ height:'60px', }} >
                            <div
                              style={{
                                textAlign:'left',
                                width:'70%',
                                float:'left',
                                align:'left',
                                verticalAlign:'middle',
                              }} >
                                {this.state.isEditingAppleWalletLogoImage ?
                                  this.isPreviewEmpty('appleWalletLogoImagePreview')
                                  :
                                  <Image
                                    className={this.state.appleWalletLogoImage ? 'previewLogoImage' : 'logoCardImageFrame'}
                                    id={this.state.appleWalletLogoImage ? 'previewLogoImage' : 'logoCardImageFrame'}
                                    alt="Apple Wallet Logo Image"
                                    src={
                                      this.state.appleWalletLogoImage ?
                                      'data:image;base64, '+this.state.appleWalletLogoImage :
                                      '/image-frame.png'
                                    } />
                                }
                            </div>
                            <div
                              style={{
                                textAlign:'right',
                                verticalAlign:'middle',
                                width:'30%',
                                float:'left',
                                fontSize:'10px',
                                paddingRight:'10px',
                                paddingTop:'10px',
                                color:this.state.cardLabelColor.hex,
                              }} >
                              COUPON VALUE<br />
                            <span
                              style={{
                                fontWeight:'500',
                                fontSize:'20px',
                                color:this.state.cardTextColor.hex,
                              }}
                            >$10.00</span>
                            </div>
                          </div>
                          <div
                            style={{
                              height:'150px',
                              padding:'0px',
                            }} >
                            {this.state.isEditingAppleWalletStripImage ?
                              this.isPreviewEmpty('appleWalletStripImagePreview')
                              :
                              <Image
                                centered
                                size='large'
                                className={this.state.appleWalletStripImage ? null : 'stripCardImageFrame'}
                                id={this.state.appleWalletStripImage ? null : 'stripCardImageFrame'}
                                alt="Apple Wallet Strip Image"
                                src={
                                  this.state.appleWalletStripImage ?
                                  'data:image;base64, '+this.state.appleWalletStripImage :
                                  '/image-frame.png'
                                } />
                            }
                          </div>
                          <div
                            style={{
                              textAlign:'left',
                              fontSize:'10px',
                              paddingLeft:'10px',
                              height:'145px',
                              color:this.state.cardLabelColor.hex,
                            }} >
                            COUPON CODE<br />
                            <span
                              style={{
                                fontWeight:'500',
                                fontSize:'20px',
                                color:this.state.cardTextColor.hex,
                              }} >K5E6J8D9U1</span>
                          </div>
                          <div
                            style={{
                              heigh:'150px',
                              align:'bottom',
                              display:'flex',
                              justifyContent:'flexEnd',
                              flexDirection:'column',
                            }} >
                            <Image
                              centered
                              rounded
                              size='small'
                              src='/barcode-new.png' />
                          </div>
                        </div>
                        {/* BUTTONS DIV */}
                        <div
                          style={{
                            width:'325px',
                          }} >
                          <div
                            style={{
                              margin:'10px',
                            }} >
                            <div
                              style={{
                                marginTop:'10px',
                                textAlign:'left',
                                width:'50%',
                                float:'left',
                                verticalAlign:'middle',
                                padding:'0px 5px 0px 0px',
                              }} >
                              {!this.state.appleWalletLogoImage && !this.state.isEditingAppleWalletLogoImage ?
                                  <Button
                                    size='small'
                                    style={{width:'100%',}}
                                    color={!this.isDisabled() || this.state.previewOpen ? 'teal' : 'grey'}
                                    disabled={(!this.state.editCardName && !this.state.name)|| this.state.previewOpen}
                                    onClick={ ()=> {this.setState({ isEditingAppleWalletLogoImage: true })} }
                                  >
                                    <Icon name='upload' />
                                    {this.isDisabled() ? 'Upload Logo' : 'Upload Logo'}
                                  </Button>
                                :
                                  <Button
                                    size='small'
                                    style={{width:'100%',}}
                                    color={
                                      this.state.isEditingAppleWalletStripImage ||
                                      this.state.isEditingAppleWalletLogoImage ||
                                      this.state.previewOpen ? 'grey' : 'teal'
                                    }
                                    disabled={
                                      this.state.isEditingAppleWalletStripImage ||
                                      this.state.isEditingAppleWalletLogoImage ||
                                      this.state.previewOpen
                                    }
                                    onClick={ () => {this.setState({ isEditingAppleWalletLogoImage: true })} }
                                  >
                                    <Icon name='edit'/>
                                    Edit Logo
                                  </Button>
                              }
                            </div>
                            <div
                              style={{
                                marginTop:'10px',
                                textAlign:'left',
                                width:'50%',
                                float:'left',
                                verticalAlign:'middle',
                                padding:'0px 0px 0px 5px',
                              }} >
                              {!this.state.appleWalletStripImage && !this.state.isEditingAppleWalletStripImage ?
                                  <Button
                                    size='small'
                                    floated='right'
                                    style={{width:'100%',}}
                                    color={!this.isDisabled() || this.state.previewOpen ? 'teal' : 'grey'}
                                    disabled={(!this.state.editCardName && !this.state.name) || this.state.previewOpen}
                                    onClick={ ()=> {this.setState({ isEditingAppleWalletStripImage: true })} }
                                  >
                                    <Icon name='upload' />
                                    {this.isDisabled() ? 'Upload Strip' : 'Upload Strip'}
                                  </Button>
                                :
                                  <Button
                                    size='small'
                                    floated='right'
                                    style={{width:'100%',}}
                                    color={
                                      this.state.isEditingAppleWalletStripImage ||
                                      this.state.isEditingAppleWalletLogoImage ||
                                      this.state.previewOpen ? 'grey' : 'teal'
                                    }
                                    disabled={
                                      this.state.isEditingAppleWalletStripImage ||
                                      this.state.isEditingAppleWalletLogoImage ||
                                      this.state.previewOpen
                                    }
                                    onClick={ () => {this.setState({ isEditingAppleWalletStripImage: true })} }
                                  >
                                    <Icon name='edit'/>
                                    Edit Strip
                                  </Button>
                              }
                            </div>
                          </div>
                          <div
                            style={{
                              margin:'10px',
                            }} >
                            <div
                              style={{
                                marginTop:'10px',
                                textAlign:'left',
                                width:'50%',
                                float:'left',
                                verticalAlign:'middle',
                                padding:'0px 5px 0px 0px',
                              }} >
                              &nbsp;
                            </div>
                            <div
                              style={{
                                marginTop:'10px',
                                textAlign:'left',
                                width:'50%',
                                float:'left',
                                verticalAlign:'middle',
                                padding:'0px 0px 0px 5px',
                              }} >
                              <Button
                                size='small'
                                icon='paint brush'
                                floated='right'
                                style={{width:'100%',}}
                                color={
                                  this.isDisabled() ||
                                  this.state.isEditingAppleWalletStripImage ||
                                  this.state.isEditingAppleWalletLogoImage ||
                                  this.state.previewOpen ? 'grey' : 'teal'
                                }
                                content={'Edit Colors'}
                                disabled={
                                  this.state.isEditingAppleWalletStripImage ||
                                  this.state.isEditingAppleWalletLogoImage ||
                                  this.state.previewOpen ||
                                  (!this.state.editCardName && !this.state.name)
                                }
                                onClick={() => this.setState({ previewOpen: !this.state.previewOpen, })} />
                            </div>
                          </div>
                        </div>

                      </Segment>

                    </Grid.Column>
                    <Grid.Column width='8' align='center' verticalAlign='middle' >

                      {(!this.state.isEditingAppleWalletStripImage && !this.state.isEditingAppleWalletLogoImage) ? null :
                        <div>
                          <div style={{fontSize:'12px', textAlign:'left', paddingBottom:'6px', }}>
                            {this.state.isEditingAppleWalletStripImage ?
                              <span>Size: 600 x 150 &#8226; Square-cut &#8226; RGB &#8226; 72dpi<br /></span> :
                              <span>Size: 480 x 150 &#8226; Square-cut &#8226; RGB &#8226; 72dpi<br /></span>
                            }
                          </div>
                          {this.state.isEditingAppleWalletStripImage ?
                            <Base64Cropper
                              aspectRatio={ 1125/432 }
                              widths={ [1125] }
                              onChange={ (appleWalletStripImagePreview) => {
                                this.setState({ appleWalletStripImagePreview })
                              }}
                              onSave={ (saveData) => { this.updateCardImages({ appleWalletStripImage: saveData.size1125 }); }}
                              onCancel={ () => {
                                this.setState({
                                  isEditingAppleWalletStripImage: false,
                                  appleWalletStripImagePreview: undefined, })
                              }} />
                            :
                              <Base64Cropper
                                aspectRatio={ 960/300 }
                                widths={ [960] }
                                onChange={ (appleWalletLogoImagePreview) => {
                                  this.setState({ appleWalletLogoImagePreview })
                                }}
                                onSave={ (saveData) => { this.updateCardImages({ appleWalletLogoImage: saveData.size960 }); }}
                                onCancel={ () => {
                                  this.setState({
                                    isEditingAppleWalletLogoImage: false,
                                    appleWalletLogoImagePreview: undefined, })
                                }} />
                          }
                        </div>
                      }

                      {!this.state.previewOpen ? null :
                        <Grid centered columns={2} stackable >
                          <Grid.Row>
                            <Grid.Column textAlign='center' >
                              Label Color<br />
                              <ChromePicker
                                width='175px'
                                disableAlpha={true}
                                color={this.state.cardLabelColor.hex}
                                onChange={this.changeCardLbColor} />
                            </Grid.Column>
                            <Grid.Column textAlign='center' >
                              Text Color<br />
                              <ChromePicker
                                width='175px'
                                disableAlpha={true}
                                color={this.state.cardTextColor}
                                onChangeComplete={ this.changeCardTxColor }/>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column textAlign='center' >
                              Background Color<br />
                              <ChromePicker
                                width='175px'
                                disableAlpha={true}
                                color={this.state.cardBackgroundColor.hex}
                                onChange={this.changeCardBgColor} />
                            </Grid.Column>
                            <Grid.Column textAlign='center' verticalAlign='middle' >
                              <Button
                                size='small'
                                icon='check'
                                color={!this.isDisabled() ? 'teal' : 'grey'}
                                content={'Close'}
                                disabled={(!this.state.editCardName && !this.state.name)}
                                onClick={() => this.setState({ previewOpen: !this.state.previewOpen, })} />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              <div style={{ height:'10px', }} ></div>
            </Container>
          </Container>
          {/* APPLE ICON IMAGE */}
          <Container >
            <Container style={{padding: '10px 0px'}}>
              <Divider horizontal ><Header>Apple Icon Image</Header></Divider>
                <Grid stackable >
                  <Grid.Row>
                    <Grid.Column width='8' align='center' >
                      <Segment textAlign='center' loading={this.state.componentIsLoading} basic >
                        <p style={{fontSize:'12px', marginBottom: '0'}}>
                          The "icon" image is displayed when an Apple Wallet card holder recieves a notification.
                        </p>
                        <div className='previewIconDiv' >
                          <div>
                            <div className='previewIconIcon'>
                              <Image
                                rounded
                                centered
                                src={this.state.appleWalletIcon ?
                                  this.apiUrl+this.state.appleWalletIcon.image :
                                  '/image-frame.png'
                                } />
                            </div>
                            <div className='previewIconTitle'>DONATOS PIZZA</div>
                            <div className='previewIconWhen'>now</div>
                          </div>
                          <div className='previewIconText'>
                            <span style={{ fontWeight:'700', fontSize:'16px', }}>Get 20% Off with any Online Order!</span><br />
                            Save all week long when you order online and enter the promo code: "Pizza Me!"
                          </div>
                        </div>
                        <div>
                          <Button
                            size='small'
                            color={!this.isDisabled() ? 'teal' : 'grey'}
                            disabled={(!this.state.editCardName && !this.state.name)}
                            onClick={ () => {this.setState({ transitionVisable: !this.state.transitionVisable })} }
                          >
                            <Icon name='edit'/>
                            Edit Icon
                          </Button>
                        </div>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width='8' align='center' >
                      <Transition
                        duration={200}
                        animation='slide down'
                        visible={this.state.transitionVisable}
                      >
                        <Segment basic compact loading={this.state.isFetchingIcons} >
                          <div
                            style={{
                              backgroundColor:'#EEEEEE',
                              borderRadius:4,
                              padding:6,
                              minHeight: 300,
                            }} >
                            <Image.Group size='tiny'>
                              {!this.state.appleIcons ? null :
                                this.state.appleIcons.map((icon) => { return (
                                  <Image
                                    key={Math.random()}
                                    rounded
                                    centered
                                    id='iconImageCard'
                                    alt='Apple Icon'
                                    disabled={!icon.isActive}
                                    style={{cursor: 'pointer'}}
                                    src={this.apiUrl+icon.image}
                                    className='iconImage imageShadow'
                                    onClick={ () => this.setCouponIcon(icon) } />
                                )})
                              }
                            </Image.Group>
                          </div>
                          {this.state.lastPage < 2 ? null :
                            <div style={{ textAlign:'center', }} >
                              <Pagination
                                secondary
                                pointing
                                size='tiny'
                                color='teal'
                                defaultActivePage={this.state.page}
                                totalPages={this.state.lastPage}
                                boundaryRange={1}
                                siblingRange={1}
                                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={this.state.showFirstAndLastNav ?
                                  { content: <Icon name='angle double left' />, icon: true } : null }
                                lastItem={this.state.showFirstAndLastNav ?
                                  { content: <Icon name='angle double right' />, icon: true } : null }
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                onPageChange={(e,{activePage}) => {
                                  this.setState({ page: activePage });
                                  this.getAppleIcons({ page: activePage });
                                }}
                              />
                            </div>
                          }
                        </Segment>
                      </Transition>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
            </Container>
          </Container>
          {/* BOTTOM DIVIDER */}
          <Container fluid >
            <Container style={{ /* padding: '10px 0px', */ }} >
              &nbsp;<Divider fitted />&nbsp;
            </Container>
          </Container>
        </div>
      )
    }
  }

  uploadStep = () => {
    this.setState({activeStep: activeStepOptions.upload})
  };

  matchStep = () => {
    this.setState({activeStep: activeStepOptions.match});
    console.log(' **** userSelectedFileJson: ',this.state.userSelectedFileJson);
  }

  summaryStep = () => {
    this.setState({activeStep: activeStepOptions.summary});
  }

  closeSpreadsheet = () => {
      this.setState({ addCouponCodes: false, activeStep: '', componentIsLoading: true, })
      this.readPromotion();
  };

  changeUserProvidedFile = (file) => {
    this.setState( {userSelectedFile: file});
    this.readFile(
      file,
      this.state.userProvidedHeader,
    );
  }

  readFile = (file, header) => {
    const schema = {
      [header === "" ? "Code" : header]: { // ** CHANGE THIS TO MATCH ERIN'S FILE **
        prop: 'code',
        type: String,
      },
    };
    readXlsxFile( file, { schema } ).then( ({rows, errors }) => {
      let arrayofCodes = rows.map(v => v.code);
      console.log(" **** arrayofCodes: ", arrayofCodes);
      this.setState( {userSelectedFileJson: arrayofCodes})
    })
  }

  changeUserProvidedHeader = (e) => {
    this.setState( { [e.target.name]: e.target.value })
    this.readFile(
      this.state.userSelectedFile,
      e.target.value,
    )
  }

  renderPercentages = (prime, divider) => {
    let result = '0 ';
    if ( prime === 0 || divider === 0 ) {
      return result;
    } else {
      result = Math.floor((prime / divider) * 100) + ' ';
      return result;
    }
  };

  renderMoney = (amount) => {
    let result = '0 ';
    if (amount === 0) {
      return result;
    } else {
      result = amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      return result;
    }
  };


  render = () => {

    return (

      <Container fluid >

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing >
                  <Button
                    icon='chevron left'
                    content='Back'
                    disabled={this.state.componentIsLoading}
                    onClick={() => this.unsavedAlert()} />
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* ACTIVE SAVE BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='bottom' >
                  <span style={{ fontSize: "14px", }}>Fill in the promotion information. Then, activate and save.</span>
                </Table.Cell>
                <Table.Cell collapsing >
                  <Checkbox
                    slider
                    id='isActive'
                    name='isActive'
                    label='Active'
                    checked={this.state.isActive}
                    onChange={this.onToggleChange} />&nbsp;
                </Table.Cell>
                <Table.Cell collapsing >
                  <Button
                    icon='save'
                    color={this.isFormModified() ? 'teal' : 'grey'}
                    content={(this.isAddEdit === 'Add') ? this.isAddEdit : 'Save'}
                    disabled={!this.isFormModified() || this.isDisabled()}
                    onClick={ () => this.savePromotion() } />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
        </Container>
        {/* EDIT PROMOTION NAME & START AND END DATE */}
        <Container>
          <Segment basic size='tiny' loading={this.state.componentIsLoading} style={{ marginBottom:'10px', }} >
            <Grid stackable >
              <Grid.Row columns={1} >
                {/* PROMOTION NAME */}
                <Grid.Column>
                  <Popup
                    position='top right'
                    size='mini'
                    open={this.state.name ? false : true}
                    trigger={
                      <Input
                        fluid
                        size='big'
                        icon={{ name:this.state.name ? '' : 'asterisk', color:this.state.name ? 'black' : 'red', size:'small' }}
                        label={{ content: 'Promotion Title', labelposition: 'left', }}
                        id='name'
                        name='name'
                        placeholder={(this.isAddEdit === 'Add') ? 'Enter Promotion Title...' : null}
                        value={this.state.name}
                        onChange={this.onInputChange} />
                    }
                  ><Icon name='asterisk' color='red' /> Promotion Title is Required.</Popup>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} >
                {/* PROMOTION DESCRIPTION */}
                <Grid.Column>
                  <Input
                    fluid
                    size='big'
                    icon={{ name:this.state.description ? '' : '', color:this.state.description ? 'black' : 'red', size:'small' }}
                    label={{ content: "Description &nbsp;&nbsp;&nbsp;&nbsp;", labelposition: 'left', }}
                    id='description'
                    name='description'
                    placeholder='Enter Promotion Description...'
                    value={this.state.description}
                    onChange={this.onInputChange} >
                    <Label style={{width:'135px',}}>Description</Label>
                    <input />
                  </Input>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2} >
                {/* PROMOTION START DATE */}
                <Grid.Column>
                  <Label id='startDateLabelClass' size='big' >Start Date</Label>
                  <DatePicker
                    id='startDatePickerClass'
                    name='startDate'
                    dateFormat='MM/dd/yyyy'
                    minDate={new Date()}
                    selected={this.state.startDate.toDate()}
                    onChange={this.onStartDateChange} />
                </Grid.Column>
                {/* PROMOTION END DATE */}
                <Grid.Column>
                  <Label id='endDateLabelClass' size='big' >End Date</Label>
                  <DatePicker
                    id='endDatePickerClass'
                    name='endDate'
                    dateFormat='MM/dd/yyyy'
                    minDate={new Date()}
                    selected={this.state.endDate.toDate()}
                    onChange={this.onEndDateChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} className="ui form" >
                {/* PROMOTION DISCLAIMER */}
                <Grid.Column style={{padding: "0"}}>
                  <Label attached="top">Disclaimer</Label>
                  <TextArea
                    name="disclaimer"
                    value={this.state.disclaimer}
                    onChange={this.onInputChange}
                    rows={4}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
        {/* RENDER FULL PROMOTION COMPONENT */}
        { this.renderFullPromotion() }

        <Confirm
          open={this.state.confirmAlert}
          content='You have unsaved content. Are you sure you want to go Back?'
          onCancel={() => this.setState({ confirmAlert: false, })}
          onConfirm={() => this.props.history.push('/promotion')} />

        {/* DATE VALIDATION ERROR */}
        <Modal
          size='tiny'
          open={this.state.errorModalActive}
          onClose={() => this.setState({errorModalActive: false,})} >
          <Modal.Header><Icon name='exclamation triangle' color='orange' /> Warning</Modal.Header>
          <Modal.Content>
            <p>There was an issue saving your Promotion. Confirm your details and then re-submit your Promotion.</p>
            <h3>Please note:</h3>
            Promotion period start and end dates cannot overlap with other promotions.
          </Modal.Content>
          <Modal.Actions>
            <Button
              color='teal'
              content='Ok'
              onClick={() => this.setState({errorModalActive: false,})} />
          </Modal.Actions>
        </Modal>

      </Container>

    )
  };
};

export default withRouter(DesignPromotion);

/*

firstItem={{ content: <Icon name='angle double left' />, icon: true }}
lastItem={{ content: <Icon name='angle double right' />, icon: true }}

*/
