import React, { Component } from 'react';
import {Menu, Sidebar, Divider, Image, Icon, Transition} from "semantic-ui-react";
import { withRouter } from 'react-router-dom';
import {SolsysAuthReactContext} from '@solsys/solsys-auth-react';
import Capability from "./enum/Capability";

class SideBarNavigation extends Component {

  state = {
     transitionVisable: false,
  };

  onMenuItemClick = (e, { name }) => {
    switch (name) {
      case "appleIconList":
        this.props.history.push('/appleicon');
        break
      case "giftcardList":
        this.props.history.push('/giftcard');
        break
      case "purchasedCardList":
        this.props.history.push('/giftcard/purchases');
        break
      case "giftCardStats":
        this.props.history.push('/giftcard/stats');
        break
      case "bulkGiftCards":
        this.props.history.push('/giftcard/bulk');
        break
      case "balanceInquiryList":
        this.props.history.push('/giftcard/inquiries');
        break
      case 'promotionList':
        this.props.history.push('/promotion');
        break
      case "userList":
        this.props.history.push('/user');
        break
      // case "googlePayLogoList":
      //   this.props.history.push('/googlepaylogo');
      //   break
      default: this.props.history.push('/');
    };
    this.setState({ transitionVisable: false, });
    this.props.closeNavMenu();
  };

  onHideMenu = () => {
    this.setState({ transitionVisable: false, });
    this.props.closeNavMenu();
  }

  render() {

    const {DESIGN_GIFT_CARDS, REVIEW_PURCHASES, PURCHASE_BULK_GIFT_CARDS, MANAGE_PROMOTIONS, ADMINISTRATOR, REVIEW_INQUIRIES} = Capability;

    return (
      <SolsysAuthReactContext.Consumer>
        {(context) => {

          const {capabilities, firstName, lastName} = context.user;

          return(
            <Sidebar.Pushable >
              <Sidebar
                borderless
                vertical
                as={Menu}
                id="donatosMenu"
                direction="left"
                animation='overlay'
                className="menuSidebar"
                onHide={this.onHideMenu}
                visible={this.props.isNavMenuVisible}
              >
                <Menu.Item
                  name='activeUser'
                  onClick={this.props.closeNavMenu}
                  className='sideBarItem' >
                  <Icon name='times' size='big' color='red' link onClick={this.props.closeNavMenu} />
                  <Image size='tiny' alt="Donatos.com" src='/DonatosLogo.svg' />
                </Menu.Item>

                <Divider />

                <Menu.Item
                  disabled
                  className='sideBarItem'
                  icon='user'
                >
                  <span>{firstName + " " + lastName}</span>
                </Menu.Item>


                {capabilities.find(v =>
                  v === REVIEW_PURCHASES ||
                  v === PURCHASE_BULK_GIFT_CARDS ||
                  v === MANAGE_PROMOTIONS ||
                  v === DESIGN_GIFT_CARDS ||
                  v === REVIEW_INQUIRIES) ?
                  [
                    <Divider/>,
                    <Menu.Item
                      name='giftcardMenu'
                      className='sideBarItem sideBarHeader'
                      content="Gift Cards"
                    />
                  ]
                    :
                  null
                }

                {capabilities.find(v => v === REVIEW_PURCHASES) ?
                  <Menu.Item
                    name='giftCardStats'
                    onClick={this.onMenuItemClick}
                    className='sideBarItem'
                    icon="area graph"
                    content="Gift Card Stats"
                  />
                    :
                  null
                }

                {capabilities.find(v => v === REVIEW_PURCHASES) ?
                  <Menu.Item
                    name='purchasedCardList'
                    onClick={this.onMenuItemClick}
                    className='sideBarItem'
                    icon="credit card"
                    content="Purchased Cards"
                  />
                    :
                  null
                }

                {capabilities.find(v => v === REVIEW_INQUIRIES) ?
                  <Menu.Item
                    name='balanceInquiryList'
                    onClick={this.onMenuItemClick}
                    className='sideBarItem'
                    icon="search"
                    content="Balance Inquiries"
                  />
                    :
                  null
                }

                {capabilities.find(v => v === PURCHASE_BULK_GIFT_CARDS) ?
                  <Menu.Item
                    name='bulkGiftCards'
                    onClick={this.onMenuItemClick}
                    className='sideBarItem'
                    icon="images outline"
                    content="Bulk Purchases"
                  />
                    :
                  null
                }

                {capabilities.find(v => v === MANAGE_PROMOTIONS) ?
                  <Menu.Item
                    name='promotionList'
                    onClick={this.onMenuItemClick}
                    className='sideBarItem'
                    icon="tag"
                    content="Promotions"
                  />
                    :
                  null
                }

                {capabilities.find(v => v === DESIGN_GIFT_CARDS) ?
                  [
                    <Menu.Item
                      name='giftcardList'
                      onClick={this.onMenuItemClick}
                      className='sideBarItem'
                      icon="credit card outline"
                      content="Design Cards"
                    />,
                    <Menu.Item
                      name='appleIconList'
                      onClick={this.onMenuItemClick}
                      className='sideBarItem'
                      icon="apple"
                      content="Apple Icons"
                    />
                  ]
                    :
                  null
                }

                <Divider />

                {capabilities.find(v => v === ADMINISTRATOR) ?
                  [
                    <Menu.Item
                      name='userList'
                      onClick={this.onMenuItemClick}
                      className='sideBarItem'
                      icon="group"
                      content="Users"
                    />,
                    <Divider />
                  ]
                    :
                  null
                }
              </Sidebar>

              <Sidebar.Pusher dimmed={this.props.isNavMenuVisible}>
                  {this.props.children}
              </Sidebar.Pusher>

            </Sidebar.Pushable>
          )
        }}
      </SolsysAuthReactContext.Consumer>

    )
  }
};

export default withRouter(SideBarNavigation);

/*



*/
