import React, { Component } from 'react';
import { Button, Icon, Divider, Header } from 'semantic-ui-react';
import '../../StyleSheets/UploadSpreadsheetSteps/UploadFileStep.css';


class UploadFileStep extends Component {

    selectFileInput = React.createRef();

    render = () => {
        return (

            <div className="uploadSegment">
              <Divider horizontal ><Header as='h4'>Choose File</Header></Divider>
              <div className="uploadSegmentRowDiv" >
                <div className="uploadFileDiv">
                  <Icon name="file excel" size='large' color="teal" />
                  <input
                    type="file"
                    accept=".xlsx"
                    ref={this.selectFileInput}
                    onChange={ (event) => {
                      this.props.changeUserProvidedFile(event.target.files[0])
                    }}
                  />
                </div>
              </div>

              <div className="uploadButtonDiv">
                <Button
                  color='teal'
                  onClick={this.props.onCancel} >
                  <Icon name='cancel'/>
                  Cancel
                </Button>
                &nbsp;
                <Button
                  disabled={!this.props.userSelectedFile}
                  color='teal'
                  onClick={this.props.onNext} >
                  <Icon name='upload'/>
                  Upload
                </Button>
              </div>

            </div>
        )
    }
}

export default UploadFileStep


/*

{this.props.userSelectedFile.length === 0 ? null :
  <div className="uploadSegmentFileConfirm" style={{margin:'auto',}}>
    <Icon name="file excel" size="huge" color="teal" />
    <h5>{this.props.userSelectedFile.name}</h5>
  </div>
}


*/
