import React, {Component} from "react";
import {withRouter} from 'react-router';
import {
  Button, Checkbox, Container, Table,
  Header, Icon, Segment, Image,
  Divider, Confirm, Grid,
} from "semantic-ui-react";
import "react-image-crop/dist/ReactCrop.css";
import Base64Cropper from './Base64Cropper.js';
import APIService from '../API.js';


class DesignAppleIcon extends Component {

  isAddEdit = this.props.match.params.appleIconId === undefined ? 'Add' : 'Edit';
  patchId = () => this.props.match.params.appleIconId;
  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  state = {

    unmodifiedIcon: {
      __v: 0,
      _id: this.props.match.params.appleIconId,
      isActive: false,
      image: '',
      createdAt: '',
      updatedAt: '',
    },

    __v: 0,
    _id: this.props.match.params.appleIconId,
    isActive: false,
    image: '',
    createdAt: '',
    updatedAt: '',

    componentIsLoading: false,
    confirmAlert: false,

    /* Cropper Start */
    imagePreview: undefined,
    isEditingImage: false,
    /* Cropper End */

  };

  componentDidMount() {
    this.getIcon();
  };

  getIcon = () => {
    if(this.isAddEdit === 'Edit') {
      this.setState({componentIsLoading: true});
      fetch( APIService.serverUrl + "/apple-wallet-icon/" + this.patchId(), {
        credentials: 'include',
        headers: {
          clientId: APIService.clientId
        },
        method: "GET",
      }).then(result => {
          return result.json();
      }).then(json => {
        // this.setIconStateWithJSON(json);
        let tempIcon = {
          __v: 0,
          _id: json._id,
          isActive: json.isActive,
          image: json.image,
          createdAt: json.createdAt,
          updatedAt: json.updatedAt,
        }
        this.setState({
          unmodifiedIcon: {...tempIcon},
          ...tempIcon,
          componentIsLoading: false,
          confirmAlert: false,
          isEditingImage: false,
        });
        // console.log("**** appleIcon: ", json)
      })
    }
  };

  saveIcon = () => {
    this.setState({componentIsLoading: true});
    let body = {
      isActive: this.state.isActive,
      image: this.state.image,
    };
    let fetchPath = (this.isAddEdit === 'Add') ?
      APIService.serverUrl + "/apple-wallet-icon" :
      APIService.serverUrl + "/apple-wallet-icon/" + this.patchId();
    fetch( fetchPath, {
      credentials: 'include',
      method: this.isAddEdit === 'Add' ? 'POST' : 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify( body )
    })
      .then((response) => {
        if(response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        if(json) {
          // this.setIconStateWithJSON(json);
          let tempIcon = {
            __v: 0,
            _id: json._id,
            isActive: json.isActive,
            image: json.image,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
          }
          this.setState({
            unmodifiedIcon: {...tempIcon},
            ...tempIcon,
            componentIsLoading: false,
            confirmAlert: false,
            isEditingImage: false,
          });
          this.isAddEdit = 'Edit';
          this.props.history.replace('/appleicon/design/'+json._id);
          // console.log('**** saveIcon JSON: ',json);
        } else {
          // console.log('saveCard PATCH FAILED');
        }
      })
  };

  updateIconImage = (image) => {
    this.setState({
      ...image,
      isEditingImage: false,
    });
  };

  onToggleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  isPreviewEmpty = (dataRef) => {
    switch (dataRef) {
      case 'imagePreview':
        if(this.state.imagePreview !== undefined) {
          return <Image rounded centered alt="Icon Image" className='iconImageFrame' src={this.state.imagePreview} />;
        } else {
          return <Image rounded centered alt="Icon Image" className='iconImageFrame' src={'/image-frame.png'} />;
        };
      default:
        return <Image rounded centered alt="Icon Image" className='iconImageFrame' src={'/image-frame.png'} />;
    };
  }

  isFormModified = () => {
    for (var key in this.state.unmodifiedIcon) {
      if (this.state.unmodifiedIcon[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  };

  unsavedAlert = () => {
    if (this.isFormModified()) {
      this.setState({ confirmAlert: true, });
    } else {
      this.props.history.replace('/appleicon')
    }
  };

  render = () => {

    return (

      <Container fluid >

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing >
                  <Button
                    icon='chevron left'
                    content='Back'
                    disabled={this.state.componentIsLoading}
                    onClick={() => this.unsavedAlert()} /> {/* () => this.props.history.push('/giftcard') */}
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* ACTIVE SAVE BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='bottom' >
                  <span style={{ fontSize: "14px", }}>Change the Apple Icon Image.</span>
                </Table.Cell>
                <Table.Cell collapsing >
                  <Checkbox
                    slider
                    id='isActive'
                    name='isActive'
                    label='Active'
                    checked={this.state.isActive}
                    onChange={this.onToggleChange} />&nbsp;
                </Table.Cell>
                <Table.Cell collapsing >
                  <Button
                    icon='save'
                    color={this.isFormModified() ? 'teal' : 'grey'}
                    content={(this.isAddEdit === 'Add') ? 'Add' : 'Save'}
                    disabled={!this.isFormModified()}
                    onClick={ () => this.saveIcon() } />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
        </Container>
        {/* APPLE ICON IMAGE SEGMENT */}
        <Container fluid >
          <Container style={{ paddingTop:'5px', }} >
            <Divider horizontal><Header><Icon name='image outline' />Apple Icon Image</Header></Divider>
            <Grid style={{ minHeight:'406px', }} stackable >
              <Grid.Row >
                <Grid.Column width='8' >
                  {!this.state.image && !this.state.isEditingImage ?
                    <Segment textAlign='center' loading={this.state.componentIsLoading} basic padded='very' >
                      {this.isPreviewEmpty('imagePreview')}<br />
                      <Button
                        color='teal'
                        onClick={ () => {this.setState({ isEditingImage: true })} }
                      >
                        <Icon name='upload' />
                        'Upload'
                      </Button><br />
                    </Segment>
                    :
                    <Segment textAlign='center' loading={this.state.componentIsLoading} basic  padded='very' >
                      {this.state.isEditingImage ?
                        this.isPreviewEmpty('imagePreview')
                        :
                        <Image
                          rounded
                          centered
                          className='imageShadow'
                          disabled={!this.state.isActive}
                          alt="Apple Icon Image"
                          src={'data:image;base64, '+this.state.image} />
                      }<br />
                      <Button
                        color={this.state.isEditingImage ? 'grey' : 'teal'}
                        disabled={this.state.isEditingImage}
                        onClick={ () => {this.setState({ isEditingImage: true })} }
                      >
                        <Icon name='edit'/>
                        Change
                      </Button>
                    </Segment>
                  }
                </Grid.Column>
                <Grid.Column width='8' >
                  {!this.state.isEditingImage ? null :
                    <Base64Cropper
                      aspectRatio={ 87/87 }
                      widths={ [87] }
                      onChange={ (imagePreview) => {
                        this.setState({ imagePreview })
                      }}
                      onSave={ (saveData) => { this.updateIconImage({ image: saveData.size87 }); }}
                      onCancel={ () => {
                        this.setState({
                          isEditingImage: false,
                          imagePreview: undefined, })
                      }}

                    />
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
          </Container>
        </Container>

        <Confirm
          open={this.state.confirmAlert}
          content='You have unsaved content. Are you sure you want to go Back?'
          onCancel={() => this.setState({ confirmAlert: false, })}
          onConfirm={() => this.props.history.push('/appleicon')} />

      </Container>
    )
  };
};

export default withRouter(DesignAppleIcon);

/*



*/
