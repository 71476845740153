import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import ListAppleIcons from "../Views/ListAppleIcons.js";
import DesignAppleIcon from "../Editors/DesignAppleIcon.js";


class AppleIconController extends Component {

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <Switch>
        <Route exact path={relativePath} render={() => <ListAppleIcons /> } />
        <Route path={relativePath + "/design/:appleIconId?"} render={() => <DesignAppleIcon /> } />
      </Switch>
    )
  };
};

export default withRouter(AppleIconController);
