import React, {Component} from "react";
import {withRouter} from "react-router";
import {
  Segment, Button, Table, Divider, Icon,
  Image, Container, Message, Pagination,
} from "semantic-ui-react";
import APIService from '../API.js';


class ListAppleIcons extends Component {

  state = {
    appleIcons: [
      {
        _id: undefined,
        isActive: undefined,
        image: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        __v: 0,
        id: undefined,
      },
    ],

    /* PAGINATION */
    page: 1,
    pageSize: 24,
    lastPage: 1,
    showFirstAndLastNav: false,

    isFetchingIcons: false,
  }

  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  componentDidMount() {
    this.getAppleIcons();
  };

  getAppleIcons = (options) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({ isFetchingIcons: true, });
    fetch( APIService.serverUrl + "/apple-wallet-icon/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'pageSize': options.pageSize,
        'page': options.page,
      }),
    }).then(result => {
        return result.json();
    }).then(json => {
        this.setState({
          appleIcons: json.results,
          isFetchingIcons: false,
          page: json.page ? json.page : 1,
          pageSize: json.pageSize ? json.pageSize: 24,
          lastPage: json.lastPage ? json.lastPage : 1,
        });
        console.log('*** Apple Icon List: ',json);
    })
  }


  render = () => {

    let appleIcons = this.state.appleIcons;

    return (

      <Container fluid >

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row style={{ height:'46px', }}>
                <Table.Cell collapsing >
                  &nbsp;
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* ADD BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='bottom' >
                  <span style={{ fontSize: "14px", }}>Click an icon to edit.</span>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    id='addButton'
                    color='teal'
                    icon='add'
                    onClick={ () => this.props.history.push('/appleicon/design') }
                    content='New Icon' />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
        </Container>
        {/* APPLE ICONS */}
        <Container fluid style={{ minHeight: '450px', }} >
          <Container style={{padding: '0px 0px'}}>
            {!appleIcons.length > 0 ?
              <Message size='massive' color='teal' compact >
                <Message.Content>
                  <Message.Header>
                    Click the "+ New Icon" button to add Icons
                  </Message.Header>
                  <br />
                  <Button
                    id='addButton'
                    color='teal'
                    icon='add'
                    onClick={ () => this.props.history.push('/appleicon/design') }
                    content='New Icon' />
                </Message.Content>
              </Message>
              :
              <Segment basic textAlign='center' loading={this.state.isFetchingIcons}  >
                  <Image.Group >
                    {appleIcons.map((icon) => { return(
                      <Image
                        key={Math.random()}
                        rounded
                        centered
                        size='tiny'
                        id='iconImage'
                        alt='Apple Icon'
                        className={icon.isActive ? 'iconImage imageShadow' : 'iconImage'}
                        disabled={!icon.isActive}
                        style={{cursor: 'pointer'}}
                        src={this.apiUrl+icon.image}
                        onClick={ () => this.props.history.push('/appleicon/design/'+icon._id) } />
                    )})}
                  </Image.Group>
              </Segment>
            }
            <Divider />
            {this.state.lastPage < 2 ? null :
              <div style={{ textAlign:'center', }} >
                <Pagination
                  secondary
                  pointing
                  size='tiny'
                  color='teal'
                  defaultActivePage={this.state.page}
                  totalPages={this.state.lastPage}
                  boundaryRange={1}
                  siblingRange={1}
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={this.state.showFirstAndLastNav ?
                    { content: <Icon name='angle double left' />, icon: true } : null }
                  lastItem={this.state.showFirstAndLastNav ?
                    { content: <Icon name='angle double right' />, icon: true } : null }
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                  onPageChange={(e,{activePage}) => {
                    this.setState({ page: activePage });
                    this.getAppleIcons({ page: activePage });
                  }}
                />
              </div>
            }
          </Container>
        </Container>
      </Container>

    )
  };
};

export default withRouter(ListAppleIcons);

/*

<Image.Group >
  {!appleIcons.length > 0 ? null :
     appleIcons.map((icon) => { return(

      <Image
        key={Math.random()}
        rounded
        centered
        size='tiny'
        id='iconImage'
        alt='Apple Icon'
        disabled={!icon.isActive}
        style={{cursor: 'pointer'}}
        src={this.apiUrl+icon.image}
        onClick={ () => this.props.history.push('/appleicon/design/'+icon._id) } />

  )})}
</Image.Group>

*/
