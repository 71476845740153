import React, { Component } from 'react';
import readXlsxFile from 'read-excel-file';
import { Button, Icon, Divider, Header, Form, Table, Segment } from 'semantic-ui-react';
import '../StyleSheets/UploadSpreadsheetSteps/UploadFileStep.css';
// import '../StyleSheets/UploadSpreadsheetSteps/MatchSaveStep.css';
import '../StyleSheets/UploadSpreadsheetSteps/SummaryStep.css';

class ExcelToJSON extends Component {
  static steps = [
    'upload',
    'match',
    'review'
  ];

  state = {
    fields: this.props.fields,
    activeStep: 'upload',
    rows: []
  }

  uploadStep = () => {
    this.setState({activeStep: "upload"})
  };

  matchStep = () => {
    this.setState({activeStep: "match"});
  }

  summaryStep = () => {
    this.setState({activeStep: "review"});
  }

  changeUserProvidedFile = (file) => {
    this.setState({userSelectedFile: file});
    this.readFile({file});
  }

  readFile = (options) => {
    let file = options.file || this.state.userSelectedFile;
    let fields = options.fields || this.state.fields;
    let schema = {};
    fields.forEach(field => {
      schema[field.customValue || field.header] = {
        prop: field.field,
        type: field.type || String
      }
    });
    readXlsxFile( file, { schema } ).then( ({rows, errors}) => {
      this.setState({rows});
    })
  }

  render = () => {
    let step;
    switch (this.state.activeStep) {
      case 'upload':
        step = (
          <Upload
            onNext={this.matchStep}
            onCancel={this.props.onClose}
            changeUserProvidedFile={this.changeUserProvidedFile}
            userSelectedFile={this.state.userSelectedFile}
            downloadTemplate={this.props.downloadTemplate}
          />
        )
        break;
      case 'match':
        step = (
          <Match
            onNext={this.summaryStep}
            onBack={this.uploadStep}
            fields={this.state.fields}
            rows={this.state.rows}
            changeFieldValue={updatedField => {
              let fields = this.state.fields.map(field => {
                if (updatedField.field === field.field) {
                  return updatedField
                } else {
                  return field
                }
              });
              this.setState({fields});
              this.readFile({fields});
            }}
            userSelectedFile={this.state.userSelectedFile}
          />
        );
        break;
      case 'review':
        step = (
          <Review
            onBack={this.matchStep}
            onFinish={() => this.props.onComplete(this.state.rows)}
            count={this.state.rows.length}
          />
        );
        break;
      default:
        break;
    }
    return (
      <div className='uploadSegment'>{step}</div>
    );
  }
}

function Upload(props) {
  return (
    <React.Fragment>
      <Divider horizontal ><Header as='h4'>Choose File</Header></Divider>

      {!props.downloadTemplate ? null :
        <Button
          content="Download Template"
          color="grey"
          style={{marginBottom: '10px'}}
          as='a'
          icon="download"
          download={props.downloadTemplate}
          href={props.downloadTemplate}
        />
      }

      <div className="uploadSegmentRowDiv" >
        <div className="uploadFileDiv">
          <Icon name="file excel" size='large' color="teal" />
          <input
            type="file"
            accept=".xlsx"
            onChange={ (event) => {
              props.changeUserProvidedFile(event.target.files[0])
            }}
          />
        </div>
      </div>

      <div className="uploadButtonDiv">
        <Button
          color='teal'
          onClick={props.onCancel} >
          <Icon name='cancel'/>
          Cancel
        </Button>
        &nbsp;
        <Button
          disabled={!props.userSelectedFile}
          color='teal'
          onClick={props.onNext} >
          <Icon name='upload'/>
          Upload
        </Button>
      </div>
    </React.Fragment>
  )
};

function Match(props) {
  return (
    <React.Fragment>
      <Divider horizontal ><Header as='h4'>Preview Content</Header></Divider>

      <p>If blank cells appear in the preview, enter the column (header) name as it apperas in your file.</p>
      <Form>
        <Form.Group inline style={{justifyContent: 'center', flexWrap: 'wrap'}}>
          {props.fields.map(field => {
            return (
              <Form.Input
                key={field.field}
                label={field.text}
                value={field.customValue || ""}
                placeholder={field.header}
                onChange={event => {
                  field.customValue = event.target.value;
                  props.changeFieldValue(field);
                }}
              />
            )
          })}
        </Form.Group>
      </Form>

      {/*placeholder segment with ternary*/}

      {props.rows.length === 0 ?
        <Segment padded='very'>
          <h4>No results. See the above fields to assign the data to a column.</h4>
        </Segment>
        :
        <div className="previewTableContainer">
          <div className='matchPreview'>
            <Table celled compact='very' textAlign="center">
              <Table.Header>
                <Table.Row>
                  {props.fields.map(field => {
                    return (
                      <Table.HeaderCell key={field.field}>{field.text}</Table.HeaderCell>
                    )
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {props.rows.map( (row, index) => {
                  if (index < 3) {
                    return (
                      <Table.Row key={index}>
                        {props.fields.map(field => <Table.Cell key={field.field}>{row[field.field]}</Table.Cell>)}
                      </Table.Row>
                    );
                  }
                })}
              </Table.Body>
            </Table>
          </div>
        </div>
      }
      <div style={{paddingTop:'10px',}} >Total number of rows: {props.rows.length}</div>
      <div style={{paddingTop:'10px',}} >Selected file name: {props.userSelectedFile.name}</div>
      <div className="uploadButtonDiv">
        <Button
          color='teal'
          onClick={props.onBack} >
          <Icon name="left arrow" />
          Cancel
        </Button>
        &nbsp;
        <Button
          color='teal'
          onClick={props.onNext} >
          <Icon name="save" />
          Save
        </Button>
      </div>
    </React.Fragment>
  )
};

function Review(props) {
  return (
    <React.Fragment>
      <Divider horizontal ><Header as='h4'>Upload Complete</Header></Divider>
      <div className="importSummaryDiv">
        <h5>Success!</h5>
        <div size='large' className='uploadFileDiv'>
          <h5><Icon color='green' name='checkmark' /> {props.count} rows have been imported.</h5>
        </div>
      </div>
      <div className="uploadButtonDiv">
        <Button
          color='teal'
          onClick={props.onFinish} >
          <Icon name="checkmark" />
          Done
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ExcelToJSON;
