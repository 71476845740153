import React, {Component} from 'react';
import moment from 'moment-timezone';
import { Button, Dropdown, Form, Header, Input, Label, Segment } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import '../StyleSheets/DeliverySelector.css';

export default class DeliverySelector extends Component {
  static defaultProps = {
    onChange: () => {}
  }

  state = {
    sendNow: true
  }

  componentDidMount() {
    this.onChange({sendNow: true});
  }

  onChange(options = {}) {
    let sendNow;
    let date;
    let timeZone;
    if (options.sendNow) {
      sendNow = true;
      date = new moment();
      timeZone = moment.tz.guess(true);
    } else {
      sendNow = false;
      date = this.props.value.clone();
      if (this.state.sendNow) {
        date.hour(date.hour() + 1).minute(0).second(0);
      }
      if (options.date) {
        let newDate = new moment(options.date);
        date
          .year(newDate.year())
          .month(newDate.month())
          .date(newDate.date());
        timeZone = this.props.timeZone;
      }
      if (options.time) {
        date
          .hour(options.time)
          .minute(0)
          .second(0);
        timeZone = this.props.timeZone;
      }
      if (options.timeZone) {
        timeZone = options.timeZone;
        date.tz(timeZone);
      }
    }
    this.setState({sendNow});
    this.props.onChange(date, timeZone);
  }

  render = () => {
    return (
      <div id="chooseADeliveryDate" className="bottomSpacer">
        <Header as="h3">Choose a Delivery Day</Header>
        <div className="subFormSection" style={{display: 'flex', flexDirection: 'column'}}>

          <Button.Group>
            <Button
              content="Send Now"
              color="red"
              basic={!this.state.sendNow}
              active={this.state.sendNow}
              onClick={() => this.onChange({sendNow: true})}
            />
            <Button
              content="Send Later"
              color="red"
              basic={this.state.sendNow}
              active={!this.state.sendNow}
              onClick={() => this.onChange({sendNow: false})}
            />
          </Button.Group>

          <Segment
            style={{
              display: this.state.sendNow ? 'none' : 'flex'
            }}
          >
            <DatePicker
              id="datePicker"
              name="deliveryDate"
              dateFormat="MM/dd/yyyy"
              showDisabledMonthNavigation
              minDate={new moment().startOf('day').toDate()}
              selected={this.props.value.toDate()}
              onChange={date => {
                this.onChange({date: date});
              }}
            />
            <Form.Group inline style={{marginLeft: '12px'}}>
              <Form.Field>
                <label>Time of Day</label>
                <Dropdown
                  fluid
                  id="timeOfDay"
                  name="timeOfDay"
                  style={{minWidth: '195px'}}
                  selection
                  value={this.props.value.hour()}
                  options={[...Array(24).keys()].map(value => {
                    let text;
                    if (value === 0) {
                      text = "12:00 am";
                    } else if (value < 12) {
                      text = value + ":00 am";
                    } else if (value === 12) {
                      text = "12:00 pm";
                    } else {
                      text = (value - 12) + ":00 pm";
                    }
                    return {text, value}
                  }).filter(option => {
                    let date = this.props.value.clone().hour(option.value).minute(0).second(0);
                    return date.isAfter();
                  })}
                  onChange={(event, data) => {
                    this.onChange({time: data.value})
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Time Zone</label>
                <Dropdown
                  fluid
                  id="timeZone"
                  name="timeZone"
                  style={{minWidth: '195px'}}
                  selection
                  value={this.props.timeZone}
                  options={[
                    { text: 'Eastern', value: 'America/New_York'},
                    { text: 'Central', value: 'America/Chicago'},
                    { text: 'Mountain', value: 'America/Denver'},
                    { text: 'Pacific', value: 'America/Los_Angeles'},
                  ]}
                  onChange={(event, data) => {
                    this.onChange({timeZone: data.value})
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Segment>
        </div>
      </div>
    )
  }
}
