import React, {Component} from "react";
import {withRouter} from "react-router";
import {Checkbox, Dropdown, Grid, Icon, Pagination, Popup, Table} from "semantic-ui-react";
import moment from 'moment';




class PurchasedCardsTable extends Component {

  static columnOptions = [
    {
      header: "Status",
      id: "status",
      sortable: true,
      isSelected: false,
      headerProps: {
        textAlign: "center"
      },
      render: (card) => {
        let color;
        if(card.status === "success" || card.status === "resolved") {
          color = "green"
        } else if(card.status === "pending") {
          color = "yellow"
        } else {
          color = "red"
        }
        return (
          <Table.Cell key={card._id + "_status"} textAlign="center">
            <Icon
              name="circle"
              color={color}
            />
          </Table.Cell>
        )
      }
    },
    {
      header: "Amount",
      id: "amount",
      sortable: true,
      isSelected: false,
      render: (card) => {
        return (
          <Table.Cell key={card._id + "_amount"}>{card.amount && ("$" + card.amount)}</Table.Cell>
        )
      }
    },
    {
      header: "Purchaser Email",
      id: "purchaserEmail",
      sortable: true,
      isSelected: false,
      render: (card) => {
        return (
          <Table.Cell key={card._id + "_purchaserEmail"}>{card.purchaserEmail}</Table.Cell>
        )
      }
    },
    {
      header: "Purchaser Name",
      id: "purchaserName",
      sortable: true,
      isSelected: false,
      render: (card) => {
        return (
          <Table.Cell key={card._id + "_purchaserName"}>{card.purchaserName}</Table.Cell>
        )
      }
    },
    {
      header: "Recipient Email",
      id: "recipientEmail",
      sortable: true,
      isSelected: false,
      render: (card) => {
        return (
          <Table.Cell key={card._id + "_recipientEmail"}>{card.recipientEmail}</Table.Cell>
        )
      }
    },
    {
      header: "Recipient Name",
      id: "recipientName",
      sortable: true,
      isSelected: false,
      render: (card) => {
        return (
          <Table.Cell key={card._id + "_recipientName"}>{card.recipientName}</Table.Cell>
        )
      }
    },
    {
      header: "Date Purchased",
      id: "datePurchased",
      sortable: true,
      isSelected: false,
      render: (card) => {
        return (
          <Table.Cell key={card._id + "_datePurchased"}>{new moment(card.createdAt).format('MMM D, YYYY')}</Table.Cell>
        )
      }
    },
    {
      header: "Date Delivered",
      id: "scheduledDelivery",
      sortable: true,
      isSelected: false,
      render: (card) => {
        return (
          <Table.Cell key={card._id + "_scheduledDelivery"}>{new moment(card.scheduledDelivery).format('MMM D, YYYY')}</Table.Cell>
        )
      }
    },
    {
      header: "Confirmation Number",
      id: "publicReference",
      sortable: true,
      isSelected: false,
      render: (card) => {
        return (
          <Table.Cell key={card._id + "_publicReference"}>{card.publicReference}</Table.Cell>
        )
      }
    },
  ]

  state = {
    selectedColumns: this.props.defaultColumns
  }

  componentDidMount() {
    // get saved columns from localStorage
    if (this.props.identifier) {
      let key = btoa(this.props.identifier);
      let value = localStorage.getItem(key);
      if (value) {
        value = JSON.parse(atob(value));
        this.setState({selectedColumns: value});
      }
    }
  }

  navToCardDetails = (card) => {
    this.props.history.push('/giftcard/view/' + card._id)
  }

  changeColumns = (columnId) => {
    let selectedOptions = this.state.selectedColumns;
    let isRemove = selectedOptions.find(v => v === columnId)
    if (isRemove) {
      selectedOptions = selectedOptions.filter(v => v !== columnId)
    } else {
      selectedOptions.push(columnId);
    }
    this.setState({selectedColumns: selectedOptions})
    this.props.onColumnChange(selectedOptions)
    if (this.props.identifier) {
      let key = btoa(this.props.identifier);
      let value = btoa(JSON.stringify(selectedOptions));
      localStorage.setItem(key, value);
    }
  }

  render = () => {

    let selectedColumnOptions = this.state.selectedColumns.map(column => {
      return PurchasedCardsTable.columnOptions.find(v => v.id === column)
    });

    return (
      <React.Fragment>
        <div style={{overflowX: 'scroll'}}>
          <Table
            striped
            singleLine
            selectable
            sortable
            basic='very'
            compact='very'
            size='small'
            verticalAlign='middle'
            style={{marginTop: "20px"}}
          >
            <Table.Header>
              <Table.Row>

                {selectedColumnOptions.map((column) => {
                  let sorted;
                  if(column.id === this.props.sortField) {
                    if(this.props.sortOrder === 1) {
                      sorted = "ascending"
                    } else if(this.props.sortOrder === -1) {
                      sorted = "descending"
                    }
                  }

                  return (
                    <Table.HeaderCell
                      className='purchasedCardTableHeaderRow'
                      {...(column.sortable && {
                        onClick: () => {
                          let sortOrder;

                          if(this.props.sortField !== column.id || this.props.sortOrder === undefined) {
                            sortOrder = 1;
                          } else if(this.props.sortOrder === 1) {
                            sortOrder = -1;
                          } else {
                            sortOrder = undefined;
                          }

                          this.props.getCardList({
                            sortOrder: sortOrder,
                            sortField: column.id
                          })
                        },
                        sorted: sorted
                      })}
                      {...(column.headerProps)}
                      key={column.id}
                    >
                      {column.header}
                    </Table.HeaderCell>
                  )
                })}

                {!this.props.canModifyColumns ? null :
                  <Table.HeaderCell className='purchasedCardTableHeaderRow' textAlign='center'>
                    <Popup
                      basic
                      wide
                      on="click"
                      id="purchasedPopup"
                      offset='-100px, 10px'
                      position='bottom right'
                      trigger={<Icon name="setting" color="grey"/>}
                    >
                      <div className="popupHeaderContainer">
                        <span>Display Columns</span>
                      </div>

                      <Grid id="popupOptions" columns={2}>
                        <Grid.Row columns={2}>

                          {PurchasedCardsTable.columnOptions.map((option) => {
                            let selected = this.state.selectedColumns.find(v => option.id === v) || false;
                            return (
                              <Grid.Column>
                                <Checkbox
                                  radio
                                  checked={selected}
                                  label={option.header}
                                  onClick={() => {
                                    this.changeColumns(option.id);
                                  }}
                                />
                              </Grid.Column>
                            )
                          })}
                        </Grid.Row>
                      </Grid>
                    </Popup>

                  </Table.HeaderCell>
                }

              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.cards.map((card) => {
                return (

                  <Table.Row
                    key={card._id.toString()}
                    id={card._id.toString()}
                    style={{cursor:'pointer'}}
                    onClick={(e) => {this.navToCardDetails(card)}}
                  >
                    {selectedColumnOptions.map((column) => {
                      return column.render(card)
                    })}

                    {/*empty column for settings cog*/}
                    <Table.Cell/>
                  </Table.Row>
              )})}
            </Table.Body>
          </Table>
        </div>

        {/*PAGINATION*/}
        {this.props.lastPage <= 1 ? null :
          <div style={{ textAlign:'center', }} >
            <Pagination
              secondary
              pointing
              size='tiny'
              color='teal'
              defaultActivePage={this.props.page}
              totalPages={this.props.lastPage}
              boundaryRange={1}
              siblingRange={1}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={this.props.showFirstAndLastNav ?
                { content: <Icon name='angle double left' />, icon: true } : null }
              lastItem={this.props.showFirstAndLastNav ?
                { content: <Icon name='angle double right' />, icon: true } : null }
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              onPageChange={(e,{activePage}) => {
                this.props.getCardList({page: activePage})
              }}
            />
          </div>
        }
      </React.Fragment>
    )
  }
}

export default withRouter(PurchasedCardsTable);
