import {
  Container,
  Segment,
  Icon,
  Form,
  Button,
  Table,
} from 'semantic-ui-react'
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";


export default class Base64Cropper extends PureComponent {

  state = {
    src: null,
    croppedImageUrl: null,
    crop: {
      aspect: this.props.aspectRatio,
      height: 1440,
      width: null,
      x: 0,
      y: 0,
    },
    extraPadding: false
  };

  isNewImageInitialCrop = false;
  isNewImageInitialCropComplete = false;

  imageInput = React.createRef();
  imageRef;
  cropperRef = React.createRef();

  onImageLoaded = (image = this.imageRef) => {
    this.imageRef = image;
    let cropperWidth = this.cropperRef.current.componentRef.clientWidth;
    let cropperHeight = this.cropperRef.current.componentRef.clientHeight;
    let selectedAspect = this.props.aspectRatio;
    let imageAspect = image.width/image.height;
    let initialWidth = selectedAspect < imageAspect ? image.height * selectedAspect : image.width;
    let initialHeight = selectedAspect > imageAspect ? image.width / selectedAspect : image.height;
    let crop = {
      aspect: this.props.aspectRatio,
      width: initialWidth,
      height: initialHeight,
      x: (cropperWidth - initialWidth)/2,
      y: (cropperHeight - initialHeight)/2
    }
    this.setState({
      crop: crop
    })
    this.onCropComplete(crop, true);
  };

  onCropChange = crop => {
    if (this.isNewImageInitialCrop) {
      this.isNewImageInitialCrop = false;
    } else {
      this.setState({crop: crop});
    }
  };

  onCropComplete = (crop, notInitialComplete) => {
    if (this.isNewImageInitialCropComplete && notInitialComplete !== true) {
      this.isNewImageInitialCropComplete = false;
      return;
    }
    if (this.imageRef && crop.width && crop.height) {
      return this.getCroppedImg(this.imageRef, crop, "image.png")
        .then(croppedImageUrl => {
          this.props.onChange(croppedImageUrl);
          // let aspect = crop.width / crop.height;
          this.setState({ croppedImageUrl });
          this.props.widths.forEach( (width) => {
            this.imageResize(croppedImageUrl, width, this.props.aspectRatio);
          });
        });
    }
  };

  getCroppedImg(image, crop, fileName) {
    const cropperWidth = this.cropperRef.current.componentRef.clientWidth;
    const cropperHeight = this.cropperRef.current.componentRef.clientHeight;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const canvasWidth = crop.width * scaleX;
    const canvasHeight = crop.height * scaleY;
    canvas.width = Math.ceil(canvasWidth);
    canvas.height = Math.ceil(canvasHeight);
    const ctx = canvas.getContext("2d");

    let centerOffsetX = (cropperWidth - image.width)/2;
    let centerOffsetY = (cropperHeight - image.height)/2;

    ctx.drawImage(
      image,
      (centerOffsetX * scaleX) - (crop.x * scaleX),
      (centerOffsetY * scaleY) - (crop.y * scaleY),
      image.naturalWidth + 2, // +1 eliminates a border, probably from Math.ceil^^
      image.naturalHeight + 1
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          reject();
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/png");
    });
  };

  onSaveImage = () => {
    let sizesObject = {};
    this.props.widths.forEach( (width) => {
      sizesObject['size'+width] = this.state['size'+width];
    });
    this.props.onSave(sizesObject);
  };

  imageResize = (blobUrl, resizeWidth, resizeAspect ) => {
    let resizeHeight = Math.round(resizeWidth / resizeAspect)
    var sizeState = "size" + resizeWidth;
    var croppedImage = document.createElement("img");
    croppedImage.onload = () => {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext("2d");
      ctx.drawImage(croppedImage, 0, 0);

      let finalWidth = (resizeWidth > croppedImage.width) ? croppedImage.width : resizeWidth;
      let finalHeight = (resizeHeight > croppedImage.height) ? croppedImage.height : resizeHeight;

      canvas.width = finalWidth;
      canvas.height = finalHeight;

      ctx.drawImage(croppedImage, 0, 0, finalWidth, finalHeight);
      var dataURL = canvas.toDataURL();
      var base64 = dataURL.substring(dataURL.indexOf(',')+1);
      this.setState({ [sizeState]: base64 });
    }
    croppedImage.src = blobUrl;
  }

  onNewImageChange = (e) => {
    if (this.imageInput.current.files && this.imageInput.current.files.length > 0) {
      let file = this.imageInput.current.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({
          src: reader.result,
          originalSrc: reader.result,
        })
        this.isNewImageInitialCrop = true;
        this.isNewImageInitialCropComplete = true;
      });
      reader.readAsDataURL(file);
    }
  }

  cancelCroppedImage = () => {
    this.props.onCancel();
  };

  onPaddingChange = () => {
    let extraPadding = !this.state.extraPadding
    this.setState({
      extraPadding: extraPadding
    }, () => {
      this.onImageLoaded();
    })
  }

  render() {
    const { crop, src } = this.state;
    let naturalCropWidth = 0;
    let naturalCropHeight = 0;
    if (this.imageRef) {
      let image = this.imageRef;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      naturalCropWidth = Math.ceil(crop.width * scaleX);
      naturalCropHeight = Math.ceil(crop.height * scaleY);
    }
    return (
      <Container fluid >

        <Form className='image-form'>
          <Form.Field>
            <input name="original" type="file" onChange={this.onNewImageChange} ref={this.imageInput} />
          </Form.Field>

          <Segment placeholder textAlign='center' className="cropper-segment" >
            {!this.state.extraPadding ?
              null :
              <style
                dangerouslySetInnerHTML={{
                  __html: 'div.ReactCrop > div:first-child {padding: 20px;}'
                }}
              />
            }
            { src ?
              <ReactCrop
                ref={this.cropperRef}
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onChange={this.onCropChange}
                onComplete={this.onCropComplete}
              />
              : 'Select an Image...'
            }
            { src ?
              <Button
                active={this.state.extraPadding}
                style={{marginTop: '8px', marginLeft: '0px', padding: '8px 10px'}}
                toggle
                size='small'
                icon='arrows alternate'
                content='Extra Padding'
                onClick={this.onPaddingChange}
              />
              : undefined
            }
          </Segment>
          <Table basic='very' size='small' >
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  { src ? <h4>Width: {Math.round(naturalCropWidth)} / Height: {Math.round(naturalCropHeight)}</h4> : '' }
                </Table.Cell>
                <Table.Cell collapsing >
                  <Form.Field align='right' >
                    <Button color='grey' onClick={this.cancelCroppedImage} >
                      <Icon name='cancel' /> Cancel
                    </Button>
                    <Button color='teal' onClick={this.onSaveImage} >
                      <Icon name='save outline' /> Save
                    </Button>
                  </Form.Field>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

        </Form>
      </Container>
    );
  }
}
