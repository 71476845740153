import React, {Component} from "react";
import {withRouter} from "react-router";
import {
  Button, Container, Table, Icon, Pagination,
  Divider, Image, Header, Segment,
} from "semantic-ui-react";
import Moment from 'react-moment';
import APIService from '../API.js';


class ListUsers extends Component {

  state = {
    users: [
      {
        _id: undefined,
        isActive: undefined,
        firstName: undefined,
        lastName: undefined,
        username: undefined,
        email: undefined,
        organization: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        password: '',
        userImage: undefined,
        __v: 0,
        capabilities: [],
      },
    ],

    /* PAGINATION */
    page: 1,
    pageSize: 10,
    lastPage: 1,
    showFirstAndLastNav: false,

    isFetchingUsers: false,

  };

  componentDidMount() {
    this.getUserList();
  };

  getUserList = (options) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({isFetchingUsers: true})
    fetch(APIService.serverUrl + "/user/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'pageSize': options.pageSize,
        'page': options.page,
      }),
    }).then(result => {
        return result.json();
    }).then(json => {
        this.setState({
          users: json.results,
          isFetchingUsers: false,
          page: json.page ? json.page : 1,
          pageSize: json.pageSize ? json.pageSize: 10,
          lastPage: json.lastPage ? json.lastPage : 1,
        });
        console.log('*** List Users json: ',json);
    });
  };


  render = () => {

    let users = this.state.users;

    return (

      <Container fluid >

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row style={{ height:'46px', }}>
                <Table.Cell collapsing >
                  &nbsp;
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* ADD BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='bottom' >
                  <span style={{ fontSize: "14px", }}>Click a row to edit a user. Click the button to add a new user.</span>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    id='addButton'
                    color='teal'
                    icon='add'
                    onClick={ () => this.props.history.push('/user/edit')}
                    content='New User' />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
        </Container>
        {/* USERS LIST */}
        <Container>
          <Segment basic loading={this.state.isFetchingUsers} >
            <Table verticalAlign='middle' basic='very' singleLine selectable >
              <Table.Body>
                {!users.length > 0 ? null : users.map((user) => { return(
                  <Table.Row
                    key={Math.random()}
                    id={user._id}
                    style={{cursor: 'pointer'}}
                    onClick={ () => this.props.history.push('/user/edit/'+user._id)}
                  >
                    <Table.Cell textAlign="center" collapsing>
                      <Icon
                        id='isActive'
                        size='large'
                        name='check circle'
                        className={user.isActive ? 'activeItem': 'inActiveItem'} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" collapsing>
                      {!user.userImage ?
                        <Icon
                          name='user'
                          size='big'
                          alt="User Image"
                          className={user.isActive ? 'activeItem': 'inActiveItem'} />
                        :
                        <Image
                          rounded
                          centered
                          size='tiny'
                          alt="Card Image"
                          src={'data:image/xxx;base64, '+user.userImage} />
                      }
                    </Table.Cell>
                    <Table.Cell textAlign="left">
                      <Header>
                        <Header.Content>{user.firstName+' '+user.lastName}</Header.Content>
                      </Header>
                      Username: {user.username}
                    </Table.Cell>
                    <Table.Cell textAlign="left">
                      Email: {user.email}<br />
                      Capabilities:
                      {!user.capabilities ? null :
                        user.capabilities.map( (capability, i) => {
                          if (user.capabilities.length === 1) {
                            return( ' '+capability )
                          } else if (user.capabilities.length === (i+1)) {
                            return( ' '+capability+' ' )
                          } else {
                            return( ' '+capability+', ' )
                          }
                      })}
                    </Table.Cell>
                    <Table.Cell textAlign="left" >
                      Created: <Moment format='MMMM Do, YYYY' >{user.createdAt}</Moment><br />
                      Updated: <Moment format='MMMM Do, YYYY' >{user.updatedAt}</Moment>
                    </Table.Cell>
                  </Table.Row>
                )})}
              </Table.Body>
            </Table>
          </Segment>
          <Divider />
            {this.state.lastPage < 2 ? null :
              <div style={{ textAlign:'center', }} >
                <Pagination
                  secondary
                  pointing
                  size='tiny'
                  color='teal'
                  defaultActivePage={this.state.page}
                  totalPages={this.state.lastPage}
                  boundaryRange={1}
                  siblingRange={1}
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={this.state.showFirstAndLastNav ?
                    { content: <Icon name='angle double left' />, icon: true } : null }
                  lastItem={this.state.showFirstAndLastNav ?
                    { content: <Icon name='angle double right' />, icon: true } : null }
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                  onPageChange={(e,{activePage}) => {
                    this.setState({ page: activePage });
                    this.getUserList({ page: activePage });
                  }}
                />
              </div>
            }
        </Container>
      </Container>
    )
  };
};

export default withRouter(ListUsers);

/*



*/
