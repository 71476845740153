
export default class APIService {

  // static local = "http://localhost:8083";
  // static testing = "http://api.donatos.testing.solsys.local:8095";
  // static staging = '';
  // static production = '';
  static serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";
  static clientId = process.env.REACT_APP_SOLSYSAUTH_CLIENT_ID || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_CLIENT_ID";

  // static token = ""
  // static authHeaders = () => ({
  //   "content-type": "application/json",
  //   "Authorization": "Bearer " + APIService.token
  // })

}
