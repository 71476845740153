import React, {Component} from 'react';
import {Button, Container, Divider, Header, Icon, Input, Message, Pagination, Segment, Table} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import APIService from '../API.js';
import PurchasedCardsTable from "./PurchasedCardsTable";

class ListBalanceInquiries extends Component {

  static filterOptions = {
    page: "page",
    pageSize: "pageSize",
    sortField: "sortField",
    sortOrder: "sortOrder",
    text: "text",
    startDate: "startDate",
    endDate: "endDate",
    status: "status"
  }

  static filterKeys = Object.keys(ListBalanceInquiries.filterOptions)

  state = {
    inquiries: [],

    isFetchingInquiries: false,

    /* FILTERING */
    startDate: new moment().subtract(30, 'days'),
    endDate: new moment().endOf('day'),
    text: "",
    status: "",

    /* PAGINATION */
    page: 1,
    pageSize: 10,
    lastPage: 1,
    showFirstAndLastNav: false,
  }

  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.location.search);

    let options = {};

    ListBalanceInquiries.filterKeys.forEach(key => {
      if(searchParams.has(key)) {
        let value = decodeURIComponent(searchParams.get(key))
        switch (key) {
          case "startDate":
            value = new moment(value)
            break
          case "endDate":
            value = new moment(value)
            break
          default:
            break
        }
        options[key] = value
      }
    })

    this.getBalanceInquiries(options);
  };

  getBalanceInquiries = (options) => {
    let filters = {};
    ListBalanceInquiries.filterKeys.forEach(key => {
      filters[key] = this.state[key]
    })
    Object.keys(options).forEach(key => {
      filters[key] = options[key]
    })

    Object.keys(filters).forEach(key => {
      switch(key) {
        case ListBalanceInquiries.filterOptions.startDate:
          filters[key] = new moment(filters[key]).startOf('day')
          break;
        case ListBalanceInquiries.filterOptions.endDate:
          filters[key] = new moment(filters[key]).endOf('day')
          break;
        case ListBalanceInquiries.filterOptions.page:
        case ListBalanceInquiries.filterOptions.pageSize:
          let value = filters[key];
          if(typeof value === "string") {
            let cleaned = value.replace(/\D/g, "");
            filters[key] = cleaned === "" ? undefined : Number(cleaned);
          } else if (typeof value === "number") {
            filters[key] = value
          }
          break;
      }
    })

    this.setState({
      ...filters,
      isFetchingInquiries: true,
    });

    let search = "?";
    ListBalanceInquiries.filterKeys.forEach((key, index) => {
      let value = filters[key];
      if(value) {
        if (search !== "?") search += "&";
        search += key + "=" + encodeURIComponent(filters[key]);
      }
    })
    this.props.history.replace(this.props.location.pathname + search)

    fetch( APIService.serverUrl + "/gift-card/balance/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        ...filters,
        bulkGiftCard: null
      }),
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            inquiries: json.results,
            isFetchingInquires: false,
            page: json.page ? json.page : 1,
            pageSize: json.pageSize ? json.pageSize: 10,
            lastPage: json.lastPage ? json.lastPage : 1,
          });
        }
      })
  }

  navToAttemptDetails = (id) => {
    this.props.history.push('/giftcard/inquiries/view/' + id)
  }

  renderStatusColor = (attempt) => {
    let color;
    if(attempt.status === "success") {
      color = "green"
    } else if(attempt.status === "pending") {
      color = "yellow"
    } else if(attempt.status === "failure") {
      color = "red"
    } else if(attempt.status === "blocked") {
      color = "black"
    }
    return (
      <Table.Cell key={attempt._id + "_status"} textAlign="center">
        <Icon
          name="circle"
          color={color}
        />
      </Table.Cell>
    )
  }

  render = () => {

    let inquiries = this.state.inquiries;

    return (
      <Container>
        <Divider horizontal style={{marginTop: "30px", marginBottom: "20px"}}>
          <Header>
            Balance Inquiries
          </Header>
        </Divider>

        {/* FILTERING  */}
        <div className="inquiryFilterContainer">
          <div className="filterDateSearchContainer">
            <div className="filterDateContainer">
              <form autoComplete='off'>
                <DatePicker
                  className="purchasedDatepicker"
                  name='startDate'
                  dateFormat='MM/dd/yyyy'
                  placeholderText="Start date"
                  selected={this.state.startDate.toDate()}
                  onChange={(date) => {
                    this.getBalanceInquiries({startDate: date});
                  }}
                />
              </form>
              <form autoComplete='off' >
                <DatePicker
                  className="purchasedDatepicker"
                  name='endDate'
                  dateFormat='MM/dd/yyyy'
                  placeholderText="End date"
                  selected={this.state.endDate.toDate()}
                  onChange={(date) => {
                    this.getBalanceInquiries({endDate: date});
                  }}
                />
              </form>
            </div>
            <Input
              icon="search"
              iconPosition="left"
              value={this.state.text}
              style={{height: "36px", flexGrow: "2"}}
              placeholder="Find by Card Number or IP Address..."
              onChange={(event) => {
                this.getBalanceInquiries({text: event.target.value})
              }}
            />
          </div>

          <Button.Group>
            <Button
              className={this.state.status === ""  ? "activeFilter" : "inactiveFilter"}
              style={{borderRight: "0px",borderTopLeftRadius: "6px",borderBottomLeftRadius: "6px"}}
              onClick={() => {
                this.getBalanceInquiries({status: ""})
              }}
            >
              <Icon name="list" color="grey"/>
              <p>View All</p>
            </Button>

            <Button
              className={this.state.status === "success" ? "activeFilter" : "inactiveFilter"}
              style={{borderRight: "0px"}}
              onClick={() => {
                this.getBalanceInquiries({status: "success"})
              }}
            >
              <Icon name="check" color="green"/>
              <p>Successful</p>
            </Button>

            <Button
              className={this.state.status === "pending"  ? "activeFilter" : "inactiveFilter"}
              onClick={() => {
                this.getBalanceInquiries({status: "pending"})
              }}
            >
              <Icon name="warning sign" color="yellow"/>
              <p>Pending</p>
            </Button>

            <Button
              className={this.state.status === "failure"  ? "activeFilter" : "inactiveFilter"}
              onClick={() => {
                this.getBalanceInquiries({status: "failure"})
              }}
            >
              <Icon name="warning sign" color="red"/>
              <p>Failure</p>
            </Button>

            <Button
              className={this.state.status === "blocked"  ? "activeFilter" : "inactiveFilter"}
              style={{borderTopRightRadius: "6px",borderBottomRightRadius: "6px"}}
              onClick={() => {
                this.getBalanceInquiries({status: "blocked"})
              }}
            >
              <Icon name="warning sign" color="black"/>
              <p>Blocked</p>
            </Button>
          </Button.Group>

        </div>

        {/*TABLE LIST & PAGINATION*/}
        {inquiries && inquiries.length < 1 ?
          <Message color='teal'  style={{textAlign:'center'}}>
            <Message.Content>
              <Message.Header>
                There are no Inquiries at this time.
              </Message.Header>
              <br />
            </Message.Content>
          </Message>
          :
          <div>

            {/*TABLE LIST*/}
            <React.Fragment>
              <div style={{overflowX: 'scroll'}}>
                <Table
                  striped
                  singleLine
                  selectable
                  sortable
                  basic='very'
                  compact='very'
                  size='small'
                  verticalAlign='middle'
                  style={{marginTop: "20px"}}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='purchasedCardTableHeaderRow' textAlign='center'>Status</Table.HeaderCell>
                      <Table.HeaderCell className='purchasedCardTableHeaderRow' textAlign='center'>Gift Card</Table.HeaderCell>
                      <Table.HeaderCell className='purchasedCardTableHeaderRow' textAlign='center'>Date</Table.HeaderCell>
                      <Table.HeaderCell className='purchasedCardTableHeaderRow' textAlign='center'>IP Address</Table.HeaderCell>
                      <Table.HeaderCell className='purchasedCardTableHeaderRow' textAlign='left'>User Agent</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {inquiries.map((attempt) => {
                      return (
                        <Table.Row
                          key={attempt._id.toString()}
                          id={attempt._id.toString()}
                          style={{cursor:'pointer'}}
                          onClick={(e) => {this.navToAttemptDetails(attempt._id)}}
                        >
                          {this.renderStatusColor(attempt)}
                          <Table.Cell textAlign='center'>{"xxxx-" + attempt.cardNumber.slice((attempt.cardNumber.length)-4)}</Table.Cell>
                          <Table.Cell textAlign='left'>{new moment(attempt.createdAt).format('MMM D, YYYY h:mm a')}</Table.Cell>
                          <Table.Cell textAlign='center'>{attempt.ipAddress}</Table.Cell>
                          <Table.Cell textAlign='left'>{attempt.userAgent}</Table.Cell>
                        </Table.Row>
                    )})}
                  </Table.Body>
                </Table>
              </div>

              {/*PAGINATION*/}
              {this.state.lastPage <= 1 ? null :
                <div style={{ textAlign:'center', }} >
                  <Pagination
                    secondary
                    pointing
                    size='tiny'
                    color='teal'
                    defaultActivePage={this.state.page}
                    totalPages={this.state.lastPage}
                    boundaryRange={1}
                    siblingRange={1}
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={this.state.showFirstAndLastNav ?
                      { content: <Icon name='angle double left' />, icon: true } : null }
                    lastItem={this.state.showFirstAndLastNav ?
                      { content: <Icon name='angle double right' />, icon: true } : null }
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    onPageChange={(e,{activePage}) => {
                      this.getBalanceInquiries({page: activePage})
                    }}
                  />
                </div>
              }
            </React.Fragment>
          </div>
        }
      </Container>
    )
  }

}

export default withRouter(ListBalanceInquiries);
