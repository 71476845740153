import React, {Component} from 'react';
import {Button, Card, Container, Divider, Dropdown, Form, Header, Icon, Input, Label, Message, Pagination, Segment, Table} from 'semantic-ui-react';
import {withRouter} from 'react-router';
import DeliverySelector from "../Views/DeliverySelector";
import ExcelToJSON from '../Views/ExcelToJSON';
import moment from 'moment-timezone';
import APIService from '../API.js';


const availableStates = [
  { text: 'Alaska', value: 'Alaska'},
  { text: 'Alabama', value: 'Alabama'},
  { text: 'Arizona', value: 'Arizona'},
  { text: 'Arkansas', value: 'Arkansas'},
  { text: 'California', value: 'California'},
  { text: 'Colorado', value: 'Colorado'},
  { text: 'Connecticut', value: 'Connecticut'},
  { text: 'Delaware', value: 'Delaware'},
  { text: 'Florida', value: 'Florida'},
  { text: 'Georgia', value: 'Georgia'},
  { text: 'Hawaii', value: 'Hawaii'},
  { text: 'Idaho', value: 'Idaho'},
  { text: 'Illinois', value: 'Illinois'},
  { text: 'Indiana', value: 'Indiana'},
  { text: 'Iowa', value: 'Iowa'},
  { text: 'Kansas', value: 'Kansas'},
  { text: 'Kentucky', value: 'Kentucky'},
  { text: 'Luisiana', value: 'Luisiana'},
  { text: 'Maine', value: 'Maine'},
  { text: 'Maryland', value: 'Maryland'},
  { text: 'Massachusetts', value: 'Massachusetts'},
  { text: 'Michigan', value: 'Michigan'},
  { text: 'Minnesota', value: 'Minnesota'},
  { text: 'Mississippi', value: 'Mississippi'},
  { text: 'Missouri', value: 'Missouri'},
  { text: 'Montana', value: 'Montana'},
  { text: 'Nebraska', value: 'Nebraska'},
  { text: 'Nevada', value: 'Nevada'},
  { text: 'New Hampshire', value: 'New Hampshire'},
  { text: 'New Jersey', value: 'New Jersey'},
  { text: 'New Mexico', value: 'New Mexico'},
  { text: 'New York', value: 'New York'},
  { text: 'North Carolina', value: 'North Carolina'},
  { text: 'North Dakota', value: 'North Dakota'},
  { text: 'Ohio', value: 'Ohio'},
  { text: 'Oklahoma', value: 'Oklahoma'},
  { text: 'Oregon', value: 'Oregon'},
  { text: 'Pennsylvania', value: 'Pennsylvania'},
  { text: 'Rhode Island', value: 'Rhode Island'},
  { text: 'South Carolina', value: 'South Carolina'},
  { text: 'South Dakota', value: 'South Dakota'},
  { text: 'Tennessee', value: 'Tennessee'},
  { text: 'Texas', value: 'Texas'},
  { text: 'Utah', value: 'Utah'},
  { text: 'Vermont', value: 'Vermont'},
  { text: 'Virginia', value: 'Virginia'},
  { text: 'Washington', value: 'Washington'},
  { text: 'West Virginia', value: 'West Virginia'},
  { text: 'Wisconsin', value: 'Wisconsin'},
  { text: 'Wyoming', value: 'Wyoming'},
];
const availableMonths = [
  { text: '01 - January', value: "01"},
  { text: '02 - February', value: "02"},
  { text: '03 - March', value: "03"},
  { text: '04 - April', value: "04"},
  { text: '05 - May', value: "05"},
  { text: '06 - June', value: "06"},
  { text: '07 - July', value: "07"},
  { text: '08 - August', value: "08"},
  { text: '09 - September', value: "09"},
  { text: '10 - October', value: "10"},
  { text: '11 - November', value: "11"},
  { text: '12 - December', value: "12"},
];
const availableYears = [
  { text: '2020', value: "20"},
  { text: '2021', value: "21"},
  { text: '2022', value: "22"},
  { text: '2023', value: "23"},
  { text: '2024', value: "24"},
  { text: '2025', value: "25"},
  { text: '2026', value: "26"},
  { text: '2027', value: "27"},
  { text: '2028', value: "28"},
  { text: '2029', value: "29"},
  { text: '2030', value: "30"},
  { text: '2031', value: "31"},
  { text: '2032', value: "32"},
  { text: '2033', value: "33"},
  { text: '2034', value: "34"},
  { text: '2035', value: "35"},
  { text: '2036', value: "36"},
  { text: '2037', value: "37"},
  { text: '2038', value: "38"},
  { text: '2039', value: "39"},
  { text: '2040', value: "40"},
];

class AddBulkPurchase extends Component {

  state = {
    // INITIAL CREATE
    title: "",
    scheduledDelivery: new moment().endOf('day'),
    scheduledDeliveryTimeZone: moment.tz.guess(),
    subject: "",
    message: "",

    // EMAIL LIST
    isFileUploadShown: false,
    recipients: [],

    // LIST CARD DESIGNS
    page: 1,
    pageSize: 25,
    lastPage: 1,
    showFirstAndLastNav: false,
    cardDesigns: [],
    giftCardDesign: undefined,

    // BILLING INFO
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cvv: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',

    purchaseError: "",

    isFetchingCardDesigns: false,
    isPostingBulkPurchase: false
  }

  componentDidMount() {
    this.getCardDesigns();
  }

  apiURI = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  getCardDesigns = (options) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({ isFetchingCardDesigns: true, });
    fetch( APIService.serverUrl + "/gift-card-design/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'pageSize': options.pageSize,
        'page': options.page,
      }),
    }).then(result => {
        return result.json();
    }).then(json => {
        if (json) {
          this.setState({
            cards: json.results,
            isFetchingCardDesigns: false,
            page: json.page ? json.page : 1,
            pageSize: json.pageSize ? json.pageSize: 10,
            lastPage: json.lastPage ? json.lastPage : 1,
          });
        }
    })
  }

  postBulkPurchase = () => {
    this.setState({ isPostingBulkPurchase: true, });
    fetch( APIService.serverUrl + "/bulk-gift-card", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        title: this.state.title,
        subject: this.state.subject,
        message: this.state.message,
        giftCardDesign: this.state.giftCardDesign,
        recipients: this.state.recipients,
        scheduledDelivery: this.state.scheduledDelivery,
        scheduledDeliveryTimeZone: this.state.scheduledDeliveryTimeZone,
        payment: {
          cardNumber: this.state.cardNumber,
          cvv: this.state.cvv,
          expirationMonth: this.state.expMonth,
          expirationYear: this.state.expYear
        },
        billingInfo: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone,
          address: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip
        }
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response);
          alert('Bulk Purchase failed, check browser log for response')
        }
      })
      .then(json => {
        if (json) {
          this.props.history.replace('/giftcard/bulk/view/' + json._id)
        }
        this.setState({isPostingBulkPurchase: false})
      })
  }

  validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  };

  luhn_checksum = (code) => {
    var len = code.length
    var parity = len % 2
    var sum = 0
    for (var i = len-1; i >= 0; i--) {
        var d = parseInt(code.charAt(i))
        if (i % 2 == parity) { d *= 2 }
        if (d > 9) { d -= 9 }
        sum += d
    }
    return sum % 10 == 0
  }

  validateBulkItem = (key) => {
    let isItemValid = true;
    const value = this.state[key];
    if(value === undefined || value === null || value === "") isItemValid = false;
    if(key === "email" && !this.validateEmail(value)) isItemValid = false;
    if(key === "cardNumber" && !this.luhn_checksum(value)) isItemValid = false;
    if(key === "recipients" && !value.length) isItemValid = false;
    return isItemValid;
  }

  validateBulkForm = () => {
    let isFormValid = true;
    [
      "title",
      "subject",
      "message",
      "scheduledDelivery",
      "recipients",
      "giftCardDesign",
      "firstName",
      "lastName",
      "email",
      "phone",
      "cardNumber",
      "expMonth",
      "expYear",
      "cvv",
      "address1",
      "city",
      "state",
      "zip",
    ].forEach(key => {
      if(!this.validateBulkItem(key)) {
        isFormValid = false;
      }
    })
    return isFormValid;
  }

  render = () => {
    return (
      <Container id="addBulkPurchase">

        {/*Initial Create*/}
        <div>
          <Divider horizontal style={{marginTop: "30px", marginBottom: "20px"}}>
            <Header>
              Create Bulk Delivery
            </Header>
          </Divider>

          <Form>
            <Form.Group>
              <Form.Field width={6}>
                <Input
                  label="Name"
                  value={this.state.title}
                  onChange={e => {
                    this.setState({title: e.target.value})
                  }}
                />
              </Form.Field>
              <Form.Field width={10}>
                <Input
                  label="Subject"
                  fluid
                  value={this.state.subject}
                  onChange={e => {
                    this.setState({subject: e.target.value})
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.TextArea
              placeholder="Enter an email message"
              value={this.state.message}
              onChange={(event) => {
                this.setState({message: event.target.value})
              }}
            />
            <DeliverySelector
              value={this.state.scheduledDelivery}
              timeZone={this.state.scheduledDeliveryTimeZone}
              onChange={(date, timeZone) => {
                this.setState({scheduledDelivery: date, scheduledDeliveryTimeZone: timeZone})
              }}
            />
          </Form>
        </div>


        {/*EMAIL LIST*/}
        <div>
          <Divider horizontal style={{marginTop: "50px", marginBottom: "25px"}}>
            <Header>
              Email List
            </Header>
          </Divider>

          {this.state.isFileUploadShown ?
            <ExcelToJSON
              fields={[
                {
                  text: "First Name",
                  header: "First Name",
                  field: "firstName"
                },
                {
                  text: "Last Name",
                  header: "Last Name",
                  field: "lastName"
                },
                {
                  text: "Email",
                  header: "Email Address",
                  field: "email"
                },
                {
                  text: "Amount",
                  header: "Amount",
                  field: "amount",
                  type: Number
                }
            ]}
              downloadTemplate={"/BulkTemplate.xlsx"}
              onClose={() => {
                this.setState({isFileUploadShown: false})
              }}
              onComplete={results => {
                this.setState({
                  recipients: results,
                  isFileUploadShown: false,
                })
              }}
            />
            :
            !this.state.isFileUploadShown && this.state.recipients.length === 0 ?
              <Segment basic>
                <Message color='teal' style={{textAlign:'center'}}>
                  <Message.Content>
                    <Message.Header>
                      Click the "<Icon name='save' />Add" button to upload a file.
                    </Message.Header>
                    <br />
                    <Button
                      icon='save'
                      color='teal'
                      content='Add'
                      onClick={() => this.setState({isFileUploadShown: true, activeStep: "upload"})}
                    />
                  </Message.Content>
                </Message>
              </Segment>
              :
              <Table compact="very" color="teal">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">
                      First Name
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Last Name
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Email
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Amount
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.recipients.map((recipient, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell textAlign="center">{recipient.firstName}</Table.Cell>
                        <Table.Cell textAlign="center">{recipient.lastName}</Table.Cell>
                        <Table.Cell textAlign="center">{recipient.email}</Table.Cell>
                        <Table.Cell textAlign="center">{recipient.amount}</Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
          }

        </div>


        {/*CARD DESIGNS*/}
        <div>
          <Divider horizontal style={{marginTop: "50px", marginBottom: "25px"}}>
            <Header>
              Card Design
            </Header>
          </Divider>

          {this.state.isFetchingCardDesigns ?
            <Segment loading padded='very' basic>

            </Segment>
              :
            <div id="cardMapping">
              {!this.state.cards ?
                null
                :
                this.state.cards.map(card => {
                  return (
                  <Card
                    className={'cardDesign' + (card._id === this.state.giftCardDesign ? ' selectedCard' : '')}
                    key={card._id}
                  >
                    <Card.Content
                      onClick={() => {
                        this.setState({giftCardDesign: card._id})
                      }}>
                      <div
                        style={{
                          backgroundImage: `url("${this.apiURI}${card.mainCardImage}")`,
                          position: 'absolute',
                          top: '0',
                          bottom: '0',
                          left: '0',
                          right: '0',
                          backgroundPositionX: '50%',
                          backgroundPositionY: '50%',
                          backgroundSize: 'cover',
                          borderRadius: '4px',
                          ...card._id === this.state.giftCardDesign && {
                            boxShadow: "0 0 8px 5px rgb(107, 118, 118)"
                          }
                        }}
                      ></div>
                    </Card.Content>
                  </Card>
                )
              })}

              {/*PAGINATION*/}
              {this.state.lastPage <= 1 ? null :
                <div style={{ textAlign:'center', }} >
                  <Pagination
                    secondary
                    pointing
                    size='tiny'
                    color='teal'
                    defaultActivePage={this.state.page}
                    totalPages={this.state.lastPage}
                    boundaryRange={1}
                    siblingRange={1}
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={this.state.showFirstAndLastNav ?
                      { content: <Icon name='angle double left' />, icon: true } : null }
                    lastItem={this.state.showFirstAndLastNav ?
                      { content: <Icon name='angle double right' />, icon: true } : null }
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    onPageChange={(e,{activePage}) => {
                      this.setState({ page: activePage });
                      this.getCardDesigns({ page: activePage });
                    }}
                  />
                </div>
              }
            </div>
          }
        </div>


        {/*BILLING INFO*/}
        <div>
          <Divider horizontal style={{marginTop: "50px", marginBottom: "25px"}}>
            <Header>
              Billing Info
            </Header>
          </Divider>

          <Form style={{display: 'flex', marginBottom: "10px"}}>

            {/*Left Side of Form*/}
            <div style={{width: '50%', marginRight: '8px'}}>

              {/*First Name*/}
              <Form.Field>
                <Input
                  label="First Name"
                  value={this.state.firstName}
                  onChange={e => {
                    this.setState({firstName: e.target.value})
                  }}
                />
              </Form.Field>

              {/*Last Name*/}
              <Form.Field>
                <Input
                  label="Last Name"
                  value={this.state.lastName}
                  onChange={e => {
                    this.setState({lastName: e.target.value})
                  }}
                />
              </Form.Field>

              {/*Email*/}
              <Form.Field>
                <Input
                  label="Email"
                  value={this.state.email}
                  onChange={e => {
                    this.setState({email: e.target.value})
                  }}
                />
              </Form.Field>

              {/*Phone*/}
              <Form.Field>
                <Input
                  label="Phone"
                  value={this.state.phone}
                  onChange={e => {
                    this.setState({phone: this.formatPhoneNumber(e.target.value)})
                  }}
                />
              </Form.Field>
            </div>

            {/*Right Side of Form*/}
            <div style={{width: '50%', marginLeft: '8px'}}>

              {/*Credit Card Number*/}
              <Form.Field>
                <Input
                  label="Card Number"
                  value={this.state.cardNumber}
                  onChange={e => {
                    let cleaned = ('' + e.target.value).replace(/\D/g, '');
                    this.setState({cardNumber: cleaned})
                  }}
                />
              </Form.Field>

              {/*Month/Year/CVV*/}
              <Form.Group >

                {/*Month*/}
                <Form.Field width="8">
                  <span className="billingSelectLabel">Month</span>
                  <Dropdown
                    fluid
                    search
                    selection
                    className="billingDropdown"
                    value={this.state.expMonth}
                    options={availableMonths}
                    onChange={(event, data) => {
                      this.setState({expMonth: data.value})
                    }}
                  />
                </Form.Field>

                {/*Year*/}
                <Form.Field width="6">
                  <span className="billingSelectLabel">Year</span>
                  <Dropdown
                    fluid
                    search
                    selection
                    className="billingDropdown"
                    value={this.state.expYear}
                    options={availableYears}
                    onChange={(event, data) => {
                      this.setState({expYear: data.value})
                    }}
                  />
                </Form.Field>

                {/*CVV*/}
                <Form.Field width="5">
                  <Input
                    label="CVV"
                    value={this.state.cvv}
                    onChange={e => {
                      let cleaned = ('' + e.target.value).replace(/\D/g, '');
                      this.setState({cvv: cleaned})
                    }}
                  />
                </Form.Field>
              </Form.Group>

              {/*Address*/}
              <Form.Field>
                <Input
                  label="Address"
                  value={this.state.address1}
                  onChange={e => {
                    this.setState({address1: e.target.value})
                  }}
                />
              </Form.Field>

              {/*Address 2*/}
              <Form.Field>
                <Input
                  label="Address 2"
                  value={this.state.address2}
                  onChange={e => {
                    this.setState({address2: e.target.value})
                  }}
                />
              </Form.Field>

              {/*City*/}
              <Form.Field>
                <Input
                  label="City"
                  value={this.state.city}
                  onChange={e => {
                    this.setState({city: e.target.value})
                  }}
                />
              </Form.Field>

              {/*State/Zip Code*/}
              <Form.Group>

                {/*State*/}
                <Form.Field width="8">
                  <span className="billingSelectLabel">State</span>
                  <Dropdown
                    fluid
                    search
                    selection
                    className="billingDropdown"
                    value={this.state.state}
                    options={availableStates}
                    onChange={(event, data) => {
                      this.setState({state: data.value})
                    }}
                  />
                </Form.Field>

                {/*Zip Code*/}
                <Form.Field width="8">
                  <Input
                    label="Zip Code"
                    value={this.state.zip}
                    onChange={e => {
                      this.setState({zip: e.target.value})
                    }}
                  />
                </Form.Field>
              </Form.Group>

              {/*Button Container*/}
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  style={{backgroundColor: '#AC1A2B', color: '#fff', marginRight: '0px'}}
                  content="Purchase"
                  icon="credit card outline"
                  disabled={!this.validateBulkForm()}
                  loading={this.state.isPostingBulkPurchase}
                  onClick={this.postBulkPurchase}
                />
              </div>

              {/*Purchase Error Segment*/}
              {!this.state.purchaseError ? null :
                <Segment>
                  <h4 style={{textAlign: 'center', marginBottom: '8px'}}>Error</h4>
                  <p style={{textAlign: 'center'}}>{this.state.purchaseError}</p>
                  <h4 style={{textAlign: 'center', marginTop: '8px'}}>Please try again.</h4>
                </Segment>
              }
            </div>
          </Form>

          {/*Missing required field popup*/}
          {this.validateBulkForm() ? null :
            <Segment className="missingContent">
              <Header as="h5" content="Invalid/Missing:"/>
              <div horizontal className="missingContentDiv">
                {this.validateBulkItem("title") ? null :
                  <h6>Name</h6>
                }
                {this.validateBulkItem("subject") ? null :
                  <h6>Subject</h6>
                }
                {this.validateBulkItem("message") ? null :
                  <h6>Message</h6>
                }
                {this.validateBulkItem("scheduledDelivery") ? null :
                  <h6>Delivery Date</h6>
                }
                {this.validateBulkItem("recipients") ? null :
                  <h6>Emails</h6>
                }
                {this.validateBulkItem("giftCardDesign") ? null :
                  <h6>Gift Card</h6>
                }
                {this.validateBulkItem("firstName") ? null :
                  <h6>First Name</h6>
                }
                {this.validateBulkItem("lastName") ? null :
                  <h6>Last Name</h6>
                }
                {this.validateBulkItem("email") ? null :
                  <h6>Billing Email</h6>
                }
                {this.validateBulkItem("phone") ? null :
                  <h6>Phone</h6>
                }
                {this.validateBulkItem("cardNumber") ? null :
                  <h6>Credit Card</h6>
                }
                {this.validateBulkItem("expMonth") ? null :
                  <h6>Expiration Month</h6>
                }
                {this.validateBulkItem("expYear") ? null :
                  <h6>Expiration Year</h6>
                }
                {this.validateBulkItem("cvv") ? null :
                  <h6>CVV</h6>
                }
                {this.validateBulkItem("address1") ? null :
                  <h6>Address Line 1</h6>
                }
                {this.validateBulkItem("city") ? null :
                  <h6>City</h6>
                }
                {this.validateBulkItem("state") ? null :
                  <h6>State</h6>
                }
                {this.validateBulkItem("zip") ? null :
                  <h6>Zip Code</h6>
                }
              </div>
            </Segment>
          }
        </div>

      </Container>
    )
  }
}

export default withRouter(AddBulkPurchase)
