import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Container, Message, Segment} from 'semantic-ui-react';

class ViewRoutingError extends Component {

  render = () => {
    return(
      <Container>
        <Segment basic style={{marginTop: "60px"}}>
          <Message style={{textAlign:'center'}}>
            <Message.Content>
              <Message.Header>
                You don't have permissions to view this page.
              </Message.Header>
              <br />
              <Message.Header>
                Contact an Administrator if this is wrong.
              </Message.Header>
            </Message.Content>
          </Message>
        </Segment>
      </Container>
    )
  }
}

export default withRouter(ViewRoutingError);
