import React, {Component} from "react";
import {withRouter} from 'react-router';
import {
  Button, Container, Table, Divider, Dropdown,
  Input, Segment, Checkbox, Grid, Confirm,
} from "semantic-ui-react";
import APIService from "../API";
import Capability from "../enum/Capability";

const renderLabel = (label) => ({
  color: 'teal',
  content: `${label.text}`,
  icon: 'check',
})

class EditUser extends Component {

  isAddEdit = this.props.match.params.userId === undefined ? 'Add' : 'Edit';
  patchId = this.props.match.params.userId;

  defaultState = {
    unmodifiedUser: {
      id: undefined,
      _id: undefined,
      capabilities: undefined,
      isActive: false,
      email: undefined,
      username: undefined,
      firstName: undefined,
      lastName: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      newPassword: undefined,
      confirmPassword: undefined,
    },

    id: undefined,
    _id: undefined,
    capabilities: undefined,
    isActive: false,
    email: undefined,
    username: undefined,
    firstName: undefined,
    lastName: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    newPassword: undefined,
    confirmPassword: undefined,

    passwordError: false,
    componentIsLoading: false,
    confirmAlert: false,
  }

  state = this.defaultState;

  componentDidMount() {
    this.readUser();
    // console.log('**** state: ',this.state);
  };

  readUser = () => {
    if(this.isAddEdit === 'Edit') {
      this.setState({componentIsLoading: true});
      fetch( APIService.serverUrl + "/user/" + this.patchId, {
        method: "GET",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          clientId: APIService.clientId
        },
      }).then(result => {
          return result.json();
      }).then(json => {
          let tempUser = {
            id: json._id,
            _id: json._id,
            capabilities: json.capabilities,
            isActive: json.isActive,
            email: json.email,
            username: json.username,
            firstName: json.firstName,
            lastName: json.lastName,
            createdAt: json.createdAt,
            updatedAt: json.updatedAt,
            newPassword: undefined,
            confirmPassword: undefined,
          }
          this.setState({
            unmodifiedUser: {...tempUser},
            ...tempUser,

            passwordError: false,
            componentIsLoading: false,
            confirmAlert: false,

          });
        // console.log("**** User: ", json);
      })
    };
  };

  saveUser = () => {
    this.setState({componentIsLoading: true});
    let passwordVar = (this.state.newPassword === undefined) ? null :
      { password: this.state.newPassword };
    let fetchPath = (this.isAddEdit === 'Add') ?
      APIService.serverUrl + "/user" :
      APIService.serverUrl + "/user/" + this.patchId;
    // console.log('*** fetchPath: ', fetchPath);
    fetch( fetchPath, {
      method: this.isAddEdit === 'Add' ? 'POST' : 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'isActive': this.state.isActive,
        'email': this.state.email,
        'username': this.state.username,
        'firstName': this.state.firstName,
        'lastName': this.state.lastName,
        'capabilities': this.state.capabilities,
        ...passwordVar,
      }),
      }).then(result => {
        return result.json();
      }).then(json => {
        // console.log('** saveUser json: ',json);
        this.isAddEdit = 'Edit';
        this.setState({
          componentIsLoading: false,
        });
        this.readUser();
      })
  };

  onToggleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  };

  onSelectChange = (e, { value }) => {
    this.setState({ capabilities: value })
  };

  newPasswordChange = (e) => {
    if (e.target.value !== this.state.confirmPassword) {
      this.setState({ passwordError: true, newPassword: e.target.value, });
    } else {
      this.setState({ passwordError: false, newPassword: e.target.value, });
    }
  };

  confirmPasswordChange = (e) => {
    if (e.target.value !== this.state.newPassword) {
      this.setState({ passwordError: true, confirmPassword: e.target.value, });
    } else {
      this.setState({ passwordError: false, confirmPassword: e.target.value, });
    }
  };

  unsavedAlert = () => {
    if (this.isFormModified()) {
      this.setState({ confirmAlert: true, });
    } else {
      this.props.history.push('/user')
    }
  };

  isFormModified = () => {
    for (var key in this.state.unmodifiedUser) {
      if (this.state.unmodifiedUser[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  };

  render = () => {

    return (

      <Container fluid >

        {/* BACK BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing >
                  <Button
                    icon='chevron left'
                    content='Back'
                    disabled={this.state.componentIsLoading}
                    onClick={() => this.unsavedAlert()} />
                </Table.Cell>
                <Table.Cell >
                  &nbsp;
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* ACTIVE SAVE BUTTON */}
        <Container>
          <Table compact='very' basic='very' size='small' verticalAlign='middle' >
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='bottom' >
                  <span style={{ fontSize: "14px", }}>Fill in the user information. Then, activate and save.</span>
                </Table.Cell>
                <Table.Cell collapsing >
                  <Checkbox
                    slider
                    id='isActive'
                    name='isActive'
                    label='Active'
                    checked={this.state.isActive}
                    onChange={this.onToggleChange} />&nbsp;
                </Table.Cell>
                <Table.Cell collapsing >
                  <Button
                    icon='save'
                    color={this.isFormModified() ? 'teal' : 'grey'}
                    content={(this.isAddEdit === 'Add') ? this.isAddEdit : 'Save'}
                    disabled={!this.isFormModified() || this.state.passwordError}
                    onClick={ () => this.saveUser() } />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
        </Container>
        {/* USER INPUT */}
        <Container>
          <Segment size='tiny' loading={this.state.componentIsLoading} basic >
            <Grid stackable >
              <Grid.Row columns={2} >
                <Grid.Column>
                  <Input
                    fluid
                    size='big'
                    label={{ content: 'First Name', labelposition: 'left', }}
                    id='firstName'
                    name='firstName'
                    placeholder={(this.isAddEdit === 'Add') ? 'Enter First Name...' : null}
                    value={this.state.firstName}
                    onChange={this.onInputChange} />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    fluid
                    size='big'
                    label={{ content: 'Last Name', labelposition: 'left', }}
                    id='lastName'
                    name='lastName'
                    placeholder={(this.isAddEdit === 'Add') ? 'Enter Last Name...' : null}
                    value={this.state.lastName}
                    onChange={this.onInputChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2} >
                <Grid.Column>
                  <Input
                    fluid
                    size='big'
                    label={{ content: 'User Name', labelposition: 'left', }}
                    id='username'
                    name='username'
                    autoComplete='false'
                    placeholder={(this.isAddEdit === 'Add') ? 'Enter Username...' : null}
                    value={this.state.username}
                    onChange={this.onInputChange} />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    fluid
                    size='big'
                    label={{ content: 'Email', labelposition: 'left', }}
                    id='email'
                    name='email'
                    autoComplete='false'
                    placeholder={(this.isAddEdit === 'Add') ? 'Enter Email...' : null}
                    value={this.state.email}
                    onChange={this.onInputChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2} >
                <Grid.Column>
                  <Input
                    fluid
                    size='big'
                    id='newPassword'
                    name='newPassword'
                    type='password'
                    autoComplete='false'
                    placeholder='Enter the New Password...'
                    label={{ content: 'New Password', labelposition: 'left', }}
                    error={this.state.passwordError}
                    value={this.state.newPassword}
                    onChange={this.newPasswordChange} />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    fluid
                    size='big'
                    id='confirmPassword'
                    name='confirmPassword'
                    type='password'
                    autoComplete='false'
                    placeholder='Confirm the New Password...'
                    label={{ content: 'Confirm Password', labelposition: 'left', }}
                    error={this.state.passwordError}
                    value={this.state.confirmPassword}
                    onChange={this.confirmPasswordChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} >
                <Grid.Column>
                    <Dropdown
                      fluid
                      selection
                      multiple
                      id='capabilities'
                      name='capabilities'
                      placeholder='Select Capabilities...'
                      options={Capability.options.map(
                        capability => ({ text: capability, value: capability })
                      )}
                      value={this.state.capabilities}
                      renderLabel={renderLabel}
                      onChange={this.onSelectChange} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Divider fitted></Divider>
        </Container>

        <Confirm
          open={this.state.confirmAlert}
          content='You have unsaved content. Are you sure you want to go Back?'
          onCancel={() => this.setState({ confirmAlert: false, })}
          onConfirm={() => this.props.history.push('/user')} />

      </Container>

    )
  };
};

export default withRouter(EditUser);
