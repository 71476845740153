import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import EditUser from '../Editors/EditUser';
import ListUsers from "../Views/ListUsers";


class UserController extends Component {


  render = () => {

    let relativePath = this.props.match.path;

    return (
      <Switch>
        <Route exact path={relativePath} render={() => <ListUsers /> } />
        <Route path={relativePath + "/edit/:userId?"} render={() => <EditUser /> } />
      </Switch>
    )
  };
};

export default withRouter(UserController);
