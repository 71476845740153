import React, { Component } from 'react';
import {Route, Switch} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import {withRouter} from "react-router";
import SideBarNavigation from './SideBarNavigation.js';
import HeaderMenu from "./HeaderMenu.js";
// import GooglePayLogoController from './Controllers/GooglePayLogoController.js';
import AppleIconController from './Controllers/AppleIconController.js';
import GiftCardController from './Controllers/GiftcardController.js';
import PromotionController from './Controllers/PromotionController.js';
import UserController from './Controllers/UserController.js';
import {SolsysAuthReact, SolsysAuthReactContext} from '@solsys/solsys-auth-react';
import Capability from "./enum/Capability";
import ViewRoutingError from "./Views/ViewRoutingError";


class App extends Component {

  static defaultState = {
    isNavMenuVisible: false,
  }

  state = App.defaultState;

  openNavMenu = () => {
    this.setState({isNavMenuVisible: true})
  }

  closeNavMenu = () => {
    this.setState({isNavMenuVisible: false})
  }

  render = () => {

    const {DESIGN_GIFT_CARDS, REVIEW_PURCHASES, PURCHASE_BULK_GIFT_CARDS, MANAGE_PROMOTIONS, ADMINISTRATOR, REVIEW_INQUIRIES} = Capability;

    return(

      <SolsysAuthReact>

        <SolsysAuthReactContext.Consumer>
          {(context) => {

            const {capabilities} = context.user;

            return(
              <SideBarNavigation
                isNavMenuVisible={this.state.isNavMenuVisible}
                openNavMenu={this.openNavMenu}
                closeNavMenu={this.closeNavMenu}
              >
                <HeaderMenu
                  openNavMenu={this.openNavMenu}
                />
                <div className="appBody">
                  <Switch>

                    <Route
                      exact
                      path='/'
                      render={(props) => {
                        let route;
                        if(capabilities.find(v => v === REVIEW_PURCHASES)) {
                          route = '/giftcard/stats'
                        } else if(capabilities.find(v => v === PURCHASE_BULK_GIFT_CARDS)) {
                          route = '/giftcard/bulk'
                        } else if(capabilities.find(v => v === DESIGN_GIFT_CARDS)) {
                          route = '/giftcard';
                        } else if(capabilities.find(v => v === MANAGE_PROMOTIONS)) {
                          route = '/promotion'
                        } else if(capabilities.find(v => v === REVIEW_INQUIRIES)) {
                          route = '/giftcard/inquiries'
                        } else {
                          route = '/user';
                        }

                        return props.history.replace(route)
                      }}
                    />

                    {/*<Route path='/googlepaylogo' component={GooglePayLogoController} />*/}


                    <Route
                      path='/appleicon'
                      component={capabilities.find(v => v === DESIGN_GIFT_CARDS) ?
                        AppleIconController
                          :
                        ViewRoutingError
                      }
                    />

                    <Route
                      path='/giftcard'
                      component={capabilities.find(v => v === REVIEW_PURCHASES || v === PURCHASE_BULK_GIFT_CARDS || v === DESIGN_GIFT_CARDS || v === REVIEW_INQUIRIES) ?
                        GiftCardController
                          :
                        ViewRoutingError
                      }
                    />

                    <Route
                      path='/promotion'
                      component={capabilities.find(v => v === MANAGE_PROMOTIONS) ?
                        PromotionController
                          :
                        ViewRoutingError
                      }
                    />

                    <Route
                      path='/user'
                      component={capabilities.find(v => v === ADMINISTRATOR) ?
                        UserController
                          :
                        ViewRoutingError
                      }
                    />

                  </Switch>
                </div>
              </SideBarNavigation>
            )}}

        </SolsysAuthReactContext.Consumer>

      </SolsysAuthReact>
    )
  }
};

export default withRouter(App);
