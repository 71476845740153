import React, { Component } from 'react';
import {Button, Icon, Divider, Header, } from "semantic-ui-react";
import '../../StyleSheets/UploadSpreadsheetSteps/SummaryStep.css';


class SummaryStep extends Component {

  render = () => {
    return (
      <div className="uploadSegment">
        <Divider horizontal ><Header as='h4'>Upload Complete</Header></Divider>

        <div className="importSummaryDiv">
          <h5>Success!</h5>
          <div size='large' className='uploadFileDiv'>
            <h5><Icon color='green' name='checkmark' /> {this.props.numberOfCodes.length} codes have been imported.</h5>
          </div>
        </div>

        <div className="uploadButtonDiv">
          <Button
            color='teal'
            onClick={this.props.onFinish} >
            <Icon name="checkmark" />
            Done
          </Button>
        </div>

      </div>
    )
  }
}

export default SummaryStep;
