import React, {Component,} from "react";
import {withRouter} from "react-router";
import {Container, Dropdown, Image, Dimmer, Loader, Icon} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import APIService from '../API.js';
import Chart from 'chart.js';
import SolsysDropdown from './SolsysDropdown.js';


class ListGiftCardStats extends Component {

  state = {

    cardStats: [],

    promotionList: [],
    selectedPromotion: '',

    dateOptions: [
      'Daily',
      'Weekly',
      'Monthly',
    ],
    salesGrouping: 'Daily',
    areaChartDateFormat: 'MM/DD/YYYY',    // BY DAY 'MM/DD/YYYY'  BY WEEK 'WW YYYY' BY MONTH MM/YYYY'
    areaChartDateDisplayFormat: 'MMM DD', // BY DAY 'MMM DD'      BY WEEK '[W]W'    BY MONTH 'MMM'
    areaChartWidth: 800,

    startDate: new moment().subtract(1, 'month').startOf('day'),
    endDate: new moment().endOf('day'),

    /* PAGINATION */
    activePage: 1,
    pageSize: 10,
    lastPage: 1,

    componentIsLoading: false,
    isFetchingPromotions: false,
  }

  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  componentDidMount() {
    this.changeDateRange();
    this.getPromotionList();
    window.onresize = () => {
      let width = window.innerWidth < 600 ? 400 :
                  (window.innerWidth < 1200 && window.innerWidth > 600) ? window.innerWidth - 200 :
                  window.innerWidth - 400;
      this.setState({areaChartWidth: width});
    };
  };

  changeDateRange = (options) => {
    options = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      ...options,
    };
    this.setState({
      ...options,
      componentIsLoading: true,
    });
    fetch( APIService.serverUrl + "/gift-card/stats", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        startDate: options.startDate,
        endDate: options.endDate,
      }),
    }).then(result => {
      if(result.ok) {
        return result.json()
      } else {
        console.log("*** changeDateRange POST failed: ", result.status, result.statusText)
      }
    }).then(json => {
        if (json) {
          this.setState({
            cardStats: json.results,
            componentIsLoading: false,
          });
        } else { this.setState({ componentIsLoading: false, }); }
    })
  };

  getPromotionList = (options) => {
    options = {
      page: this.state.activePage,
      pageSize: this.state.pageSize,
      ...options,
    };
    this.setState({isFetchingPromotions: true})
    fetch(APIService.serverUrl + "/promo-period/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        'pageSize': options.pageSize,
        'page': options.page,
      }),
    }).then(result => {
      if(result.ok) {
        return result.json()
      } else {
        throw result
      }
    }).then(json => {
        this.setState({
          promotionList: json.results,
          isFetchingPromotions: false,
          activePage: json.page ? json.page : 1,
          pageSize: json.pageSize ? json.pageSize: 10,
          lastPage: json.lastPage ? json.lastPage : 1,
        })
    }).catch(error => {
      console.error(error);
      alert("Fetching Promotions failed, see broswer logs for details.")
    })
  };

  changePromotion = (promo) => {
    if (promo) {
      this.setState({selectedPromotion: promo._id});
      this.changeDateRange({
        startDate: new moment(promo.startDate).startOf('day'),
        endDate: new moment(promo.endDate).endOf('day')
      });
    } else {
      this.setState({selectedPromotion: ""});
    }
  };

  changeSalesGrouping = (scope) => {
    this.setState({salesGrouping: scope})

    switch(scope) {
      case "Weekly":
        this.changeDateRange({
          startDate: this.state.startDate.startOf("isoWeek"),
          endDate: this.state.endDate.endOf("isoWeek")
        })
        break;

      case "Monthly":
        this.changeDateRange({
          startDate: this.state.startDate.startOf("month"),
          endDate: this.state.endDate.endOf("month")
        })
        break;

      default:
        break
    }
  };

  onDateButtonPress = (value) => {
    this.changeDateRange({
      startDate: new moment().startOf('day').subtract(value, 'days'),
      endDate: new moment(),
    });
    this.changePromotion();
    this.changeSalesGrouping("Daily");
  }

  onStartDateChange = (date) => {
    this.changeDateRange({startDate: new moment(date).startOf('day')});
    this.changePromotion();
  };

  onEndDateChange = (date) => {
    this.changeDateRange({endDate: new moment(date).endOf('day')});
    this.changePromotion();
  };

  renderAreaChart = (data, areaChartDateFormat, areaChartDateDisplayFormat) => {
    var ctx = document.getElementById('myAreaChart');
    let labels = data.map( (set) =>
      new moment(set.name, areaChartDateFormat).format(areaChartDateDisplayFormat)
    );
    let datasets = [{
      label: 'Sales',
      data: data.map( (set) => { return( set.amount )}), // CONVERT THIS TO $#k $#.#k SOMEHOW
      lineTension: 0,
      backgroundColor: 'rgba(229,13,23, 0.6)',
      borderColor: 'rgba(0,0,0, 1)',
      borderWidth: 1.5,
      pointBorderColor: 'rgba(229,13,23, 1)',
      pointBackgroundColor: 'rgba(255,255,255, 1)',
      pointBorderWidth: 1,
      pointRadius: 4,
      pointHitRadius: 20,
    }]

    if (this.myAreaChart) {
      this.myAreaChart.data.labels = labels;
      this.myAreaChart.data.datasets = datasets;
      this.myAreaChart.update();
    } else if (ctx) {
      this.myAreaChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          animation: {
              duration: 0 // general animation time
          },
          hover: {
              animationDuration: 0 // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0,
          elements: {
              line: {
                  tension: 0 // disables bezier curves
              }
          },
          layout: {
            padding: {
              left: 0,
              right: 20,
              top: 0,
              bottom: 0
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: (value, index, values) => {
                  return this.renderAmount(value, true);
                },
              }
            }],
          },
          legend: {
            display: false,
            labels: {
              fontColor: 'rgb(255, 99, 132)'
            }
          }
        }
      });
    }
  };

  renderPieChart = (data) => {
    var ctx = document.getElementById('myPieChart');
    let labels = data.map( (set) => { return( set.name )});
    let datasets = [{
      data: data.map( (set) => { return( set.value )}),
      backgroundColor: 'rgba(229,13,23, 0.6)',
      borderColor: 'rgba(255,255,255, 1)',
      borderWidth: 2,
    }];
    if(this.myPieChart) {
      this.myPieChart.data.labels = labels;
      this.myPieChart.data.datasets = datasets;
      this.myPieChart.update();
    } else if (ctx) {
      this.myPieChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          animation: {
              duration: 0 // general animation time
          },
          layout: {
            padding: {
              left: 0,
              right: 20,
              top: 0,
              bottom: 0
            }
          },
          legend: {
            display: false,
            labels: {
              fontColor: 'rgb(255, 99, 132)'
            }
          }
        }
      });
    }
  };

  renderBarChart = (data) => {
    var ctx = document.getElementById('myBarChart');
    let labels = data.map( (set) => { return( set.name )}); // THIS IS THE DATE IN "Mmm ##" FORMAT
    let datasets = [{
      data: data.map( (set) => { return( set.amount )}), // CONVERT THIS TO $#k $#.#k SOMEHOW
      backgroundColor: 'rgba(229,13,23, 0.6)',
    }];
    if(this.myBarChart) {
      this.myBarChart.data.labels = labels;
      this.myBarChart.data.datasets = datasets;
      this.myBarChart.update();
    } else if (ctx) {
      this.myBarChart = new Chart(ctx, {
        type: 'horizontalBar',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          animation: {
              duration: 0 // general animation time
          },
          layout: {
            padding: {
              left: 0,
              right: 10,
              top: 0,
              bottom: 0
            }
          },
          legend: {
            display: false,
            labels: {
              fontColor: 'rgb(255, 99, 132)'
            }
          }
        }
      });
    }

  }

  calcPercentages = (prime, divider) => {
    if ( prime === 0 || divider === 0 ) {
      return '0';
    } else {
      return (Math.floor((prime / divider) * 100)) < 0.01 ? '1' : (Math.floor((prime / divider) * 100)).toString();
    }
  };

  renderAmount = (amount, dollar) => {
    switch (true) {
      case (amount < 1000) : // HUNDREDS
        return dollar ? '$' + amount.toString() : amount.toString();
        break;
      case (amount > 999 && amount < 10000) : // THOUSANDS
        let tempResult1000 = (Math.round(amount/100)*100).toString();
        let thousand = tempResult1000.substring(0, tempResult1000.length - 2);
        return dollar ? '$' + thousand.substring(0, 1) + '.' + thousand.substring(1) + 'k' :
          thousand.substring(0, 1) + '.' + thousand.substring(1) + 'k';
        break;
      case (amount > 9999 && amount < 1000000) : // HUNDRED THOUSANDS
        let tempResult100000 = (Math.round(amount/1000)*1000).toString();
        return dollar ? '$' + tempResult100000.substring(0, tempResult100000.length - 3) + 'k' :
          tempResult100000.substring(0, tempResult100000.length - 3) + 'k';
        break;
      case (amount > 999999 && amount < 9999999) : // MILLIONS
        let tempResult1000000 = (Math.round(amount/100000)*100000).toString();
        let million = tempResult1000000.substring(0, tempResult1000000.length - 5);
        return dollar ? '$' + million.substring(0, 1) + '.' + million.substring(1) + 'm' :
          million.substring(0, 1) + '.' + million.substring(1) + 'm';
        break;
      case (amount > 10000000) : // GREATER THAN MILLIONS
        let tempResult10000000 = (Math.round(amount/1000000)*1000000).toString();
        return dollar ? '$' + tempResult10000000.substring(0, tempResult10000000.length - 6) + 'm' :
          tempResult10000000.substring(0, tempResult10000000.length - 6) + 'm';
        break;
      default:
        return dollar ? '$0' : '0';
        break;
    };
  };

  render = () => {

// building render data
    let successfulPurchaseCount = 0;
    let totalRevenue = 0;
    let totalCoupons = 0;
    let topDesigns = {};
    let stateCount = {};
    let amountCount = {};
    let areaChartDateFormat;
    let areaChartDateDisplayFormat;
    let duration = moment.duration(this.state.endDate.diff(this.state.startDate));
    let momentShorthand = "";

    let disabledStartDays;
    let disabledEndDays;


// LIMIT DATEPICKERS TO SPECIFIC DAYS WHEN WEEKLY/MONTHLY ARE SELECTED.
    switch(this.state.salesGrouping) {
      case "Weekly":
        areaChartDateFormat = 'WW YYYY'; // BY MONTH MM/YYYY' BY DAY 'MM/DD/YYYY' BY WEEK 'WW YYYY'
        areaChartDateDisplayFormat = '[W]W'; // BY DAY 'MMM DD' BY MONTH 'MMM' BY WEEK '[W]W'
        momentShorthand = "weeks";
        disabledStartDays = date => {
          const day = new moment(date).format("dddd");
          return day === "Monday";
        };
        disabledEndDays = date => {
          const day = new moment(date).format("dddd");
          return day === "Sunday";
        };

        break;

      case "Monthly":
        areaChartDateFormat = 'MM/YYYY'; // BY MONTH MM/YYYY' BY DAY 'MM/DD/YYYY' BY WEEK 'WW YYYY'
        areaChartDateDisplayFormat = 'MMM'; // BY DAY 'MMM DD' BY MONTH 'MMM' BY WEEK '[W]W'
        momentShorthand = "months";
        disabledStartDays = date => {
          const startOfMonth = new moment(date).startOf("month").format("D");
          const today = new moment(date).format("D")
          return today === startOfMonth;
        };
        disabledEndDays = date => {
          const endOfMonth = new moment(date).endOf("month").format("D");
          const today = new moment(date).format("D")
          return today === endOfMonth;
        };

        break;

      default:
        momentShorthand = "days";
        areaChartDateFormat = 'MM/DD/YYYY'; // BY MONTH MM/YYYY' BY DAY 'MM/DD/YYYY' BY WEEK 'WW YYYY'
        areaChartDateDisplayFormat = 'MMM DD'; // BY DAY 'MMM DD' BY MONTH 'MMM' BY WEEK '[W]W'

      break;
    }
    let entriesCount = Math.round(duration.as(momentShorthand));

    let areaChart = {};   // Helper object that assits building chart and maintain unique-ness
    for (var i = 0; i < entriesCount; i++) {
      // This is the loop that adds default $0 values
      let key = new moment(this.state.startDate).add(i, momentShorthand).format(areaChartDateFormat);
      areaChart[key] = 0;
    }

    this.state.cardStats.forEach( (card) => {
      if (card.status === 'success') { // card.status === 'success' // <-- THIS FOR PRODUCTION
        successfulPurchaseCount += 1;
        totalRevenue += card.customerCharged || 0;
        totalCoupons += card.customerCharged > 49 ? 1 : 0;
        topDesigns[card.giftCardDesign] = topDesigns[card.giftCardDesign] ? topDesigns[card.giftCardDesign] +1 : 1;
        stateCount[card.billingState] = stateCount[card.billingState] ? stateCount[card.billingState] + 1 : 1;
        amountCount[card.customerCharged] = amountCount[card.customerCharged] ? amountCount[card.customerCharged] +1 : 1;
        let customerCharged = card.customerCharged || 0;
        let key = new moment(card.createdAt).format(areaChartDateFormat);
        areaChart[key] = (areaChart[key] ? areaChart[key] : 0) + customerCharged;
      }
    });


    let topTenDesigns = Object.keys(topDesigns).sort( (a, b) => topDesigns[a] < topDesigns[b] ? 1 : -1 );
    let topTenStates = Object.keys(stateCount).sort( (a, b) => stateCount[a] < stateCount[b] ? 1 : -1 );
    let topTenAmounts = Object.keys(amountCount);
    let areaChartSorted = Object.keys(areaChart).sort( (a, b) =>
      new moment(a, areaChartDateFormat).isAfter(new moment(b, areaChartDateFormat)) ? 1 : -1 );

    let areaDataArray = [];
    areaChartSorted.forEach((dayKey, index) => {
      let amount = areaChart[dayKey];
      areaDataArray.push({ name: dayKey, amount: amount });
    })

    let pieDataArray = [];
    topTenStates.forEach((stateId,index) => {
      let count = stateCount[stateId];
      if (index < 5) {
        pieDataArray.push({ name: stateId, value: count, });
      } else {
        let existingTotal = (pieDataArray[5] && pieDataArray[5].value) || 0;
        let otherTotal = existingTotal + count;
        pieDataArray[5] = { name: 'Other', value: otherTotal };
      }
    });

    let barDataArray = [
      {name: '$15', count: 0},
      {name: '$25', count: 0},
      {name: '$50', count: 0},
      {name: '$75', count: 0},
      {name: '$100', count: 0},
      {name: 'Other', count: 0},
    ];
    topTenAmounts.forEach((amountId,index) => {
      let count = amountCount[amountId];
      switch (amountId) {
        case ('15') :
          barDataArray[0] = { name: '$15', amount: count };
          break;
        case ('25') :
          barDataArray[1] = { name: '$25', amount: count };
          break;
        case ('50') :
          barDataArray[2] = { name: '$50', amount: count };
          break;
        case ('75') :
          barDataArray[3] = { name: '$75', amount: count };
          break;
        case ('100') :
          barDataArray[4] = { name: '$100', amount: count };
          break;
        default:
          let otherAmount = barDataArray[5].count + count;
          barDataArray[5] = { name: 'Other', amount: otherAmount };
          break;
      }
    });

    return (

      <div className='containerBodyDiv' >

        {/* MENU BAR CONTAINER */}
        <div style={{backgroundColor: "#FFFFFF"}}>
          <div className='menuBarContainer' >
            <div className='menuBarSubContainer' >
              <div className='menuBarTitle' >Gift Card Statistics</div>
            </div>
            <div className='menuBarSubContainer' >
              <form autoComplete='off' >

                <DatePicker
                  id='menuBarStartDatePicker'
                  name='startDate'
                  dateFormat='MM/dd/yyyy'
                  placeholderText="Start date"
                  filterDate={disabledStartDays}
                  selected={this.state.startDate.toDate()}
                  onChange={this.onStartDateChange}
                />

              </form>
              <form autoComplete='off' >

                <DatePicker
                  id='menuBarEndDatePicker'
                  name='endDate'
                  dateFormat='MM/dd/yyyy'
                  placeholderText="End date"
                  filterDate={disabledEndDays}
                  selected={this.state.endDate.toDate()}
                  onChange={this.onEndDateChange}
                />

              </form>
            </div>
            <div className='menuBarSubContainer' >
              <div className='menuBarButton' onClick={() => this.onDateButtonPress(7)}>Last 7 days</div>
              <div className='menuBarButton' onClick={() => this.onDateButtonPress(30)}>Last 30 days</div>
              <div className='menuBarButton' onClick={() => this.onDateButtonPress(90)}>Last 90 days</div>
            </div>
            <div className='menuBarSubContainer' >
              <SolsysDropdown
                componentIsLoading={this.state.componentIsLoading}
                selectedPromotion={this.state.selectedPromotion}
                getPromotionList={this.getPromotionList}
                optionsList={this.state.promotionList}
                onSelectChange={(e, {value}) => {
                  this.changePromotion(this.state.promotionList.find(v => v._id === value))
                  this.changeSalesGrouping("Daily");
                }}
                activePage={this.state.activePage}
                lastPage={this.state.lastPage}
              />
            </div>
          </div>
        </div>


        {/* GIFT CARD STATS */}
        <div className='statsContainer' >

          <div className='graphContainer'>

            <div className='salesVolumeContainer'>

              <Dimmer.Dimmable blurring dimmed={this.state.componentIsLoading} >
                <Dimmer inverted active={this.state.componentIsLoading} >
                  <Loader size='large' />
                </Dimmer>

                <div className='salesVolumeNumbersRow'>

                  <div className='salesVolumeStatsDiv' >
                    <div className='salesVolumeNumber' >{this.renderAmount(successfulPurchaseCount)}</div>
                    <div className='salesVolumeLabel' >CARDS PURCHASED</div>
                  </div>
                  <div className='salesVolumeStatsDiv' >
                    <div className='salesVolumeNumber' >{this.renderAmount(totalRevenue, true)}</div>
                    <div className='salesVolumeLabel' >TOTAL REVENUE</div>
                  </div>
                  <div className='salesVolumeStatsDiv' >
                    <div className='salesVolumeNumber' >{this.renderAmount(totalCoupons)}</div>
                    <div className='salesVolumeLabel' >COUPONS ISSUED</div>
                  </div>
                  <div className='salesVolumeStatsDivRight' >
                    <div className='salesVolumeNumber' >{this.renderAmount( totalCoupons * 10, true )}</div>
                    <div className='salesVolumeLabel' >TOTAL COUPON VALUE</div>
                  </div>
                </div>

              </Dimmer.Dimmable>

              <div className='salesVolumeTitleRow' >
                <div className='salesVolumeTitleText' >Sales Volume</div>
                <div className='salesVolumeTitleButton' >
                  <Dropdown
                    basic
                    style={{width:'50px',}}
                    selection
                    id='menuBarDropdown'
                    name='dateOptions'
                    className='menuBarDropdown'
                    options={this.state.dateOptions.map(
                      date => { return {text: date, value: date }}
                    )}
                    value={this.state.salesGrouping}
                    onChange={(e, {value}) => {
                      this.changeSalesGrouping(value);
                      this.changePromotion();
                    }}
                  />
                </div>
              </div>

              <Dimmer.Dimmable blurring dimmed={this.state.componentIsLoading} >
                <Dimmer inverted active={this.state.componentIsLoading} >
                  <Loader size='large' />
                </Dimmer>
                {this.renderAreaChart(areaDataArray, areaChartDateFormat, areaChartDateDisplayFormat)}
                <div className='salesVolumeGraphRow' >
                  <canvas
                    id='myAreaChart'
                    style={{
                      width:this.state.areaChartWidth,
                      height:'250px',
                      minHeight:'150px',
                    }} />
                </div>

              </Dimmer.Dimmable>

            </div>

            <div className='salesVolumeBottomContainer' >

              <div className='salesRegionsDiv' >
                <div className='salesTitleContainer' >Regions</div>
                <div className='salesRegionGraphContainer' >

                  <Dimmer.Dimmable blurring dimmed={this.state.componentIsLoading} >
                    <Dimmer inverted active={this.state.componentIsLoading} >
                      <Loader size='large' />
                    </Dimmer>
                    {this.renderPieChart(pieDataArray)}
                    <div>
                      <canvas
                        id='myPieChart'
                        style={{
                          width:'350px',
                          height:'250px',
                        }} />
                    </div>

                  </Dimmer.Dimmable>

                </div>
                <div className='salesStateContainer'>
                  <div className='salesStateDiv' >
                  {pieDataArray.map( (state,i) => {
                    if (i < 3) { return(
                      <div key={i}>{state.name} {this.calcPercentages(state.value,successfulPurchaseCount)}% </div>
                    )}})}
                  </div>
                  <div className='salesStateDivRight' >
                  {pieDataArray.map( (state,i) => {
                    if (i > 2) { return(
                      <div key={i}>{state.name} {this.calcPercentages(state.value || 0,successfulPurchaseCount)}% </div>
                    )}})}
                  </div>
                </div>
              </div>

              <div className='salesAmountDiv' >
                <div className='salesTitleContainer' >Amounts</div>
                <div className='salesRegionGraphContainer' >

                  <Dimmer.Dimmable blurring dimmed={this.state.componentIsLoading} >
                    <Dimmer inverted active={this.state.componentIsLoading} >
                      <Loader size='large' />
                    </Dimmer>
                    {this.renderBarChart(barDataArray)}
                      <canvas
                        id='myBarChart'
                        style={{
                          width:'350px',
                          height:'350px',
                        }} />

                  </Dimmer.Dimmable>

                </div>
              </div>

            </div>

          </div>

          <div className='cardContainer'>
            <div className='cardContainerColumn' >
              <div className='salesTitleContainer' >
                Card Designs
              </div>

              <Dimmer.Dimmable blurring dimmed={this.state.componentIsLoading} >
                <Dimmer inverted active={this.state.componentIsLoading} >
                  <Loader size='large' />
                </Dimmer>

                { topTenDesigns.map( (cardId,index) => {
                    let count = topDesigns[cardId];
                    return (
                      <div key={index} className='cardDesignDiv' >
                        <div>
                          <Image rounded centered alt="Card Image"
                            style={{width:'100px',height:'auto',}}
                            src={this.apiUrl+'/gift-card-design/'+cardId+'/mainCardImage/'} />
                        </div>
                        <div>{count}</div>
                      </div>
                    )
                  })
                }

              </Dimmer.Dimmable>

            </div>
          </div>

        </div>

      </div>

    )
  };
};

export default withRouter(ListGiftCardStats);

/*



*/
