import React, {Component,} from "react";
import {withRouter} from "react-router";
import { Dropdown, Icon, Button, } from "semantic-ui-react";


class SolsysDropdown extends Component {

  render = () => {

    return (

      <Dropdown
        basic
        search
        clearable
        selection
        disabled={this.props.componentIsLoading}
        id='menuBarDropdown'
        name='promotion'
        className='menuBarDropdown'
        value={this.props.selectedPromotion}
        placeholder='Select Promotion...'
        options={this.props.optionsList.map( option => { return ({
          key: option._id, text: option.name, value: option._id })}
        )}
        onChange={this.props.onSelectChange}
        header={
          this.props.lastPage === 1 ? null :
          <div className='dropdownPaginator' >
            <div className='dropdownPaginatorIcon' >
              <Icon
                fitted
                color='grey'
                name='chevron left'
                disabled={this.props.activePage === 1}
                style={{ cursor:(this.props.activePage === 1) ? '' : 'pointer', }}
                onClick={() => {
                  this.props.getPromotionList({ page: this.props.activePage - 1 });
                }} />
            </div>
            <div className='dropdownPaginatorText' >
              PAGE {this.props.activePage}
            </div>
            <div className='dropdownPaginatorIcon' >
              <Icon
                fitted
                color='grey'
                name='chevron right'
                disabled={(this.props.activePage === this.props.lastPage)}
                style={{ cursor:(this.props.activePage === this.props.lastPage) ? '' : 'pointer', }}
                onClick={() => {
                  this.props.getPromotionList({ page: this.props.activePage + 1 });
                }} />
            </div>
          </div>
        }
      />

    )

  };

};

export default withRouter(SolsysDropdown);
