import React, { Component } from 'react';
import { Menu, Icon, Button, Image, Grid, } from 'semantic-ui-react';
import { withRouter } from "react-router";
import {SolsysAuthReactContext} from '@solsys/solsys-auth-react';

class HeaderMenu extends Component {

  render() {

    return (

      <SolsysAuthReactContext.Consumer>
        {(context) => {
          return(
            <Menu
              className='appMenu'
              pointing
              fixed='top'
              borderless
              compact
              key="header" >

              <div className='navDiv'>
                <div className='navContainer'>
                  <Button
                    id='navMenuButton'
                    className='menuButton'
                    onClick={this.props.openNavMenu} >
                    <Icon name='bars'/>
                    <Image size='tiny' centered alt="Donatos.com" src='/logo-white.svg' />&nbsp;&nbsp;
                  </Button>
                  <Grid verticalAlign='middle' >
                    <Grid.Row>
                      <Grid.Column>
                        <span style={{ fontWeight:'bold',color:'#FFFFFF', }}>Donatos E-Gift Cards - Administration</span>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                <div className='signOutDiv'>
                  <Button
                    id='logoutButton'
                    className='menuButton'
                    onClick={() => context.logout() } >
                    <Icon name='log out'/>
                    <span style={{fontSize: '14px', color: '#fff'}}>Logout</span>
                  </Button>
                </div>
              </div>
            </Menu>
          )
        }}
      </SolsysAuthReactContext.Consumer>
    )
  }
}

export default withRouter(HeaderMenu);
