import React, {Component} from "react";
import {withRouter} from "react-router";
import {Button, Checkbox, Container, Divider, Dropdown, Grid, Header, Icon, Input, Label, Message, Pagination, Popup, Search, Segment, Table} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import APIService from '../API.js';
import PurchasedCardsTable from "./PurchasedCardsTable";


class ListPurchasedCards extends Component {

  static filterOptions = {
    page: "page",
    pageSize: "pageSize",
    sortField: "sortField",
    sortOrder: "sortOrder",
    text: "text",
    startDate: "startDate",
    endDate: "endDate",
    status: "status",
    startAmount: "startAmount",
    endAmount: "endAmount"
  }

  static filterKeys = Object.keys(ListPurchasedCards.filterOptions)

  state = {
    cards: [],

    /* PAGINATION */
    page: 1,
    pageSize: 25,
    lastPage: 1,
    showFirstAndLastNav: false,
    sortField: "",
    sortOrder: "",

    /*FILTERING*/
    startDate: new moment().subtract(30, 'days'),
    endDate: new moment().endOf('day'),
    startAmount: undefined,
    endAmount: undefined,
    status: "",
    isViewAllSelected: true,
    isViewSuccessfulSelected: false,
    isViewEventIssuesSelected: false,
    isMultiPurchasedSelected: false,
    text: "",

    /*LIST COLUMN DISPLAY*/
    selectedColumns: ["amount", "datePurchased", "purchaserName", "recipientName", "recipientEmail", "status"],

    /*PURCHASE DETAILS*/
    displayDetails: false,
    isEventLogInfoOpen: false,

    isFetchingGiftCards: false,
  }

  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.location.search);

    let options = {};

    ListPurchasedCards.filterKeys.forEach(key => {
      if(searchParams.has(key)) {
        let value = decodeURIComponent(searchParams.get(key))
        switch (key) {
          case "startDate":
            value = new moment(value)
            break
          case "endDate":
            value = new moment(value)
            break
          default:
            break
        }
        options[key] = value
      }
    })

    this.getPurchasedCards(options);
  };

  getPurchasedCards = (options) => {
    let filters = {};
    ListPurchasedCards.filterKeys.forEach(key => {
      filters[key] = this.state[key]
    })
    Object.keys(options).forEach(key => {
      filters[key] = options[key]
    })

    Object.keys(filters).forEach(key => {
      switch(key) {
        case ListPurchasedCards.filterOptions.startDate:
          filters[key] = new moment(filters[key]).startOf('day')
          break;
        case ListPurchasedCards.filterOptions.endDate:
          filters[key] = new moment(filters[key]).endOf('day')
          break;
        case ListPurchasedCards.filterOptions.startAmount:
        case ListPurchasedCards.filterOptions.endAmount:
        case ListPurchasedCards.filterOptions.page:
        case ListPurchasedCards.filterOptions.pageSize:
          let value = filters[key];
          if(typeof value === "string") {
            let cleaned = value.replace(/\D/g, "");
            filters[key] = cleaned === "" ? undefined : Number(cleaned);
          } else if (typeof value === "number") {
            filters[key] = value
          }
          break;
      }
    })

    this.setState({
      ...filters,
      isFetchingGiftCards: true,
    });

    let search = "?";
    ListPurchasedCards.filterKeys.forEach((key, index) => {
      let value = filters[key];
      if(value) {
        if (search !== "?") search += "&";
        search += key + "=" + encodeURIComponent(filters[key]);
      }
    })
    this.props.history.replace(this.props.location.pathname + search)

    fetch( APIService.serverUrl + "/gift-card/list", {
      credentials: 'include',
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        clientId: APIService.clientId
      },
      body: JSON.stringify({
        ...filters,
        bulkGiftCard: null
      }),
    })
      .then(result => {
        if(result.ok) {
          return result.json();
        }
      })
      .then(json => {
        if(json) {
          this.setState({
            cards: json.results,
            isFetchingGiftCards: false,
            page: json.page ? json.page : 1,
            pageSize: json.pageSize ? json.pageSize: 10,
            lastPage: json.lastPage ? json.lastPage : 1,
          });
        }
      })
  }

  setSelectedOptions = (selectedOptions) => {
    this.setState({selectedColumns: selectedOptions});
  }

  render = () => {

    let cards = this.state.cards;

    return (
      <Container>

        <Divider horizontal style={{marginTop: "30px", marginBottom: "20px"}}>
          <Header>
            Purchase List
          </Header>
        </Divider>

        {/*FILTERING & LIST*/}
        <div className='purchasedCardTableContainer' >

          {/* FILTERING  */}
          <div className="purchasedFilterContainer">
            <div className="purchasedDateRangeAndMonetaryAmounts">
              <div className="purchasedDateRanges">
                <form autoComplete='off' >
                  <DatePicker
                    className="purchasedDatepicker"
                    name='startDate'
                    dateFormat='MM/dd/yyyy'
                    placeholderText="Start date"
                    selected={this.state.startDate.toDate()}
                    onChange={(date) => {
                      this.getPurchasedCards({startDate: date});
                    }}
                  />
                </form>
                <form autoComplete='off' >
                  <DatePicker
                    className="purchasedDatepicker"
                    name='endDate'
                    dateFormat='MM/dd/yyyy'
                    placeholderText="End date"
                    selected={this.state.endDate.toDate()}
                    onChange={(date) => {
                      this.getPurchasedCards({endDate: date});
                    }}
                  />
                </form>
              </div>
              <div className="purchasedMonetaryAmounts">
                <Input
                  label='From:'
                  value={this.state.startAmount ? ("$" + this.state.startAmount) : ""}
                  placeholder="$0"
                  onChange={(event) => {
                    this.getPurchasedCards({startAmount: event.target.value})
                  }}
                />
                <Input
                  label='To:'
                  value={this.state.endAmount ? ("$" + this.state.endAmount) : ""}
                  placeholder="$100"
                  onChange={(event) => {
                    this.getPurchasedCards({endAmount: event.target.value})
                  }}
                />
              </div>
            </div>

            <div className="purchasedFilterButtonsAndSearch">
              <div className="purchasedFilterButtons">
                <Button.Group>
                  <Button
                    className={this.state.status === ""  ? "activeFilter" : "inactiveFilter"}
                    style={{borderRight: "0px",borderTopLeftRadius: "6px",borderBottomLeftRadius: "6px"}}
                    onClick={() => {
                      this.getPurchasedCards({status: ""})
                    }}
                  >
                    <Icon name="list" color="grey"/>
                    <p>View All</p>
                  </Button>

                  <Button
                    className={this.state.status === "success" ? "activeFilter" : "inactiveFilter"}
                    style={{borderRight: "0px"}}
                    onClick={() => {
                      this.getPurchasedCards({status: "success"})
                    }}
                  >
                    <Icon name="check" color="green"/>
                    <p>Successful</p>
                  </Button>

                  <Button
                    className={this.state.status === "failure"  ? "activeFilter" : "inactiveFilter"}
                    style={{borderTopRightRadius: "6px",borderBottomRightRadius: "6px"}}
                    onClick={() => {
                      this.getPurchasedCards({status: "failure"})
                    }}
                  >
                    <Icon name="warning sign" color="yellow"/>
                    <p>Event Issues</p>
                  </Button>
                </Button.Group>

                {/*<Button
                  basic
                  style={{padding: "8px 9px", borderRadius: "6px"}}
                  content="Multi-Purchased"
                  icon="user outline"
                />*/}

              </div>
              <Input
                icon="search"
                iconPosition="left"
                value={this.state.text}
                placeholder="Find... (name, email, error code, confirmation)"
                onChange={(event) => {
                  this.getPurchasedCards({text: event.target.value})
                }}
              />
            </div>

            <div className="purchasedDownloadContainer">
              <Button
                id="download"
                icon='download'
                size='tiny'
                content='Download'
                as="a"
                href={APIService.serverUrl + "/gift-card/list/excel?options=" + JSON.stringify({
                  "sortOrder": this.state.sortOrder,
                  "sortField": this.state.sortField,
                  "text": this.state.text,
                  "startDate": this.state.startDate,
                  "endDate": this.state.endDate,
                  "status": this.state.status,
                  "startAmount": this.state.startAmount,
                  "endAmount": this.state.endAmount,
                  "listProperties": this.state.selectedColumns
                })}
                style={{backgroundColor: "#37A9AF", color: "#fff", borderRadius: "6px"}}
              />
            </div>
          </div>

          {/*TABLE LIST & PAGINATION*/}
          {cards && cards.length < 1 ?
            <Message color='teal'  style={{textAlign:'center'}}>
              <Message.Content>
                <Message.Header>
                  There are no Gift Card Purchases at this time.
                </Message.Header>
                <br />
              </Message.Content>
            </Message>
            :
            <div>

              {/*TABLE LIST*/}
              <PurchasedCardsTable
                identifier={'listPurchasedCardsMain'}
                cards={cards}
                canModifyColumns
                defaultColumns={this.state.selectedColumns}
                onColumnChange={this.setSelectedOptions}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                getCardList={this.getPurchasedCards}
                page={this.state.page}
                pageSize={this.state.pageSize}
                lastPage={this.state.lastPage}
                showFirstAndLastNav={this.state.showFirstAndLastNav}
              />
            </div>
          }
        </div>
      </Container>
    )
  };
};

export default withRouter(ListPurchasedCards);

/*

{moment(this.state.couponCodeExpiration).format('dddd, MMMM D, YYYY')}


*/
