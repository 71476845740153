import React, { Component } from 'react';
import {
  Button, Icon, Form, Table, Segment,
  Header, Divider,
} from "semantic-ui-react";
import '../../StyleSheets/UploadSpreadsheetSteps/MatchSaveStep.css';


class MatchSaveStep extends Component {

  render = () => {
    return (
      <div className="uploadSegment">
        <Divider horizontal ><Header as='h4'>Preview Content</Header></Divider>

        <div className="matchFields">
          <p>If blank cells appear in the preview, enter the column (header) name as it appears in your file.</p>
          <div style={{width:'300px',margin:'auto',}}>
            <Form>
              <Form.Group className="formGroup">
                <Form.Input
                  label='Header Row'
                  name='userProvidedHeader'
                  value={this.props.userProvidedHeader}
                  onChange={this.props.changeUserProvidedHeader}
                />
              </Form.Group>
            </Form>
          </div>

          {/*placeholder segment with ternary*/}

          {this.props.userSelectedFileJson.length === 0 ?
            <Segment>
              <Icon name="exclamation" color="red"/>
              <h4>No fields match our data! See the above fields to assign the data to a column.</h4>
              <Icon name="exclamation" color="red"/>
            </Segment>
            :
            <div className="previewTableContainer">
              <div className='matchPreview'>
                <Table celled compact='very' size='small' textAlign="center" style={{width:'300px',margin:'auto',}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Codes</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.userSelectedFileJson.map( (row, index) => {
                      if (index < 3) {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>{row}</Table.Cell>
                          </Table.Row>
                        )
                      }
                    })}
                  </Table.Body>
                </Table>
              </div>
            </div>
          }

        <div style={{paddingTop:'10px',}} >Total number of rows: {this.props.userSelectedFileJson.length}</div>
        <div style={{paddingTop:'10px',}} >Selected file name: {this.props.userSelectedFile.name}</div>


        </div>

        <div className="uploadButtonDiv">
          <Button
            color='teal'
            onClick={this.props.onBack} >
            <Icon name="left arrow" />
            Cancel
          </Button>
          &nbsp;
          <Button
            color='teal'
            onClick={this.props.onNext} >
            <Icon name="save" />
            Save
          </Button>
        </div>

      </div>
    )
  }
}

export default MatchSaveStep;
